function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: number,
  orderBy: Key
): (
  a: { [key in Key]: number | string | React.ReactNode },
  b: { [key in Key]: number | string | React.ReactNode }
) => number {
  switch (order) {
    case 1:
      return (a, b) => -descendingComparator(a, b, orderBy);
    case 2:
      return (a, b) => descendingComparator(a, b, orderBy);
    default:
      return (a, b) => descendingComparator(a, b, orderBy);
  }
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
  orderBy: string,
  order: number
) {
  if (
    (orderBy === "indexName" && order === 0) ||
    order === 0 ||
    orderBy === "action"
  ) {
    return array;
  }

  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const orderSort = comparator(a[0], b[0]);
    if (orderSort !== 0) {
      return orderSort;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
