import styled from "styled-components";

export const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
  }
`;

export const ForgotContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;
`;
