import React from "react";

export interface Data {
  indexName: string;
  decadeAvgDividendYield: number;
  nominalEarningGrowth: number;
  valuationAdjustment: number;
  annualizedNominalReturn: number;
  currencyExchangeImpact: number;
  unexplained: number;
  beginningPriceToEarningsRatio: number;
  endingPriceToEarningsRatio: number;
  action: React.ReactNode;
}

export interface NoData extends Data {
  noData?: boolean;
  type?: "COUNTRY" | "DEVELOPED_MARKET" | "EMERGING_MARKET" | "GLOBAL";
  indexType?: "COUNTRY" | "DEVELOPED_MARKET" | "EMERGING_MARKET" | "GLOBAL";
}

export interface UnitsOfMeasurement {
  annualizedNominalReturn: string;
  expectedNominalAnnualizedReturn: string;
  currencyExchangeImpact: string;
  unexplained: string;
  nominalEarningGrowth: string;
  valuationAdjustment: string;
  beginningPriceToEarningsRatio: string;
  endingPriceToEarningsRatio: string;
  dividendYield: string;
  decadeAvgDividendYield: string;
  action: string;
}

export interface List {
  dataUnitsOfMeasurement: UnitsOfMeasurement;
  historicalStock: Data[];
  showButton: any;
  compareLines: boolean;
  headCells: HeadCell[];
  onSelectedCompare?: any;
  selectedCompare?: Data[] | NoData[];
  buttonSection: number;
  valuationSummary?: boolean;
}

export interface HeadCell {
  id: keyof Data;
  label: string;
  diffBackground: boolean;
  tooltip?: string;
}

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy: string;
  rowCount: number;
}

export interface DesktopProps {
  headCells: HeadCell[];
  compareLines: boolean;
  selectedCompare?: Data[] | NoData[];
  selected: Data[];
  order: number;
  orderBy: keyof Data;
  rows: NoData[];
  dataUnitsOfMeasurement: UnitsOfMeasurement;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  handleClick: (event: React.MouseEvent<unknown>, row: Data) => void;
  buttonSection: number;
}

export enum IndexTypes {
  countries = "COUNTRY",
  developedMarkets = "DEVELOPED_MARKET",
  emergingMarkets = "EMERGING_MARKET",
  global = "GLOBAL",
}
