import React, {useMemo} from "react";

import {SIGN_UP_PROCESS_STEPS, UPDATE_SUBSCRIPTION_PROCESS_STEPS,} from "./constants";
import {SideBarProps} from "./types";
import {applicationConfig} from "application/config/applicationConfig";

import ProcessItem from "./components/ProcessItem";
import ProcessAnimation from "./components/ProcessAnimation";

import {
  ArrowBox,
  ArrowSection,
  Container,
  CopyrightAction,
  CopyrightAnd,
  CopyrightBlock,
  CopyrightCircle,
  CopyrightContainer,
  CopyrightText,
  LogoText,
  ProcessContainer,
  ProcessList,
  ProcessTitle,
  ProcessWrapper,
  Title,
  HeaderTitle,
  LogoSection,
} from "./styles";

import mfruLogoSource from "application/assets/images/logo-white.svg";
import arrowSource from "application/assets/images/side-bar-arrow.svg";
import {PathNames} from "../../application/routes";
import {useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import HeaderAuth from "../../application/components/HeaderAuth";

const SideBar = ({ type, activeStep = 1, recovery }: SideBarProps) => {
  const navigate = useNavigate();

  const PROCESS_STEPS =
    type === "sign-up"
      ? SIGN_UP_PROCESS_STEPS
      : UPDATE_SUBSCRIPTION_PROCESS_STEPS;

  const progress = useMemo(() => {
    const isFirsStep = activeStep === 1;
    const progressCalc = Math.min(
      ((activeStep - 1) * 100) / (PROCESS_STEPS.length - 1),
      100
    );
    return isFirsStep ? 0 : progressCalc;
  }, [activeStep]);

  const handleMainPage = () => {
    window.open(applicationConfig.assetcamp, "_blank");
  };

  const title = useMemo(() => {
    switch (type) {
      case "sign-in":
        return "Simplify your research. Empower your decisions.";
      case "sign-up":
        return "Account Creation";
      case "update-subscription":
        return "Subscription Renewal Process";
      default:
        return "Simplify your research. Empower your decisions.";
    }
  }, [type]);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const { privacy_policy, refunds, cookies } = applicationConfig;

  const navigateTo = () => {
    navigate(type === "sign-in" ? PathNames.registration : PathNames.login)
  }

  return (
    <Container isSignUp={type === "sign-up" || type === "update-subscription"}>
      <LogoSection>
        <LogoText src={mfruLogoSource} alt="logo text" onClick={handleMainPage} />

        {isTablet && !recovery && (
          <HeaderAuth type={type} navigateTo={navigateTo} />
        )}

        {isTablet && recovery && (
          <HeaderTitle>
            Simplify your research. Empower your decisions.
          </HeaderTitle>
        )}
      </LogoSection>

      {type === "sign-in" ? (
        <ArrowSection>
          <Title>{title}</Title>
          <ArrowBox>
            <img src={arrowSource} alt="arrow" />
          </ArrowBox>

        </ArrowSection>
      ) : (
        <ProcessWrapper>
          <ProcessTitle>{title}</ProcessTitle>

          <ProcessContainer>
            <ProcessList>
              {isTablet && (
                <ProcessItem
                  key={PROCESS_STEPS[activeStep - 1].id}
                  process_step={PROCESS_STEPS[activeStep - 1]}
                  isActive={activeStep > PROCESS_STEPS[activeStep - 1].step}
                />
              )}
              {!isTablet && (
                PROCESS_STEPS.map((process_step) => (
                  <ProcessItem
                    key={process_step.id}
                    process_step={process_step}
                    isActive={activeStep > process_step.step}
                  />
                ))
              )}
            </ProcessList>

            <ProcessAnimation
              type={type}
              progress={progress}
              activeStep={activeStep}
            />
          </ProcessContainer>
        </ProcessWrapper>
      )}
      <CopyrightContainer>
        <CopyrightBlock>
          <CopyrightAction target="_blank" href={privacy_policy}>
            Disclosures and Privacy Policy
          </CopyrightAction>
          <CopyrightCircle>•</CopyrightCircle>
          <CopyrightAction target="_blank" href={refunds}>
            Refunds
          </CopyrightAction>
          <CopyrightAnd>and</CopyrightAnd>
          <CopyrightAction target="_blank" href={cookies}>
            Cookie Policy
          </CopyrightAction>
        </CopyrightBlock>

        <CopyrightText>Copyright © {new Date().getFullYear()}</CopyrightText>
      </CopyrightContainer>
    </Container>
  );
};

export default SideBar;
