import Api from "../Api";
import { FilterData } from "common/valuationReports/store/model";

class ApiMetrics extends Api {
  static getMetrics() {
    return ApiMetrics.instance.axiosInst.get<FilterData>("/metrics");
  }

  static getIndicesComparisonMetrics() {
    return ApiMetrics.instance.axiosInst.get<FilterData>(
      "/metrics/comparison-metrics"
    );
  }
}

export default ApiMetrics;
