import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import { colors } from "application/styles/colors";

interface StyledProps {
  visible?: boolean;
  isSelected?: boolean;
}

export const Container = styled.div`
  display: flex;
  padding: 16px 0;
  position: relative;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.oxford_blue};

  @media (max-width: 1023px) {
    padding: 16px 28px;
    height: 72px;
  }
`;

export const HeaderMenuIcon = styled(MenuIcon)`
  display: none !important;
  width: 1.8rem !important;
  height: 1.8rem !important;

  @media (max-width: 1023px) {
    display: block !important;
  }
`;

export const HeaderCloseIcon = styled(CloseIcon)`
  width: 1.8rem !important;
  height: 1.8rem !important;
`;

export const Logo = styled.img`
  width: 136px;
  height: 48px;
  cursor: pointer;

  @media (max-width: 1023px) {
    width: 108px;
    height: 38px;
  }
`;

export const Arrow = styled.img`
  width: 16px;
  height: 16px;

  @media (max-width: 1023px) {
    margin-left: 12px;
  }
`;

export const Content = styled.div`
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
`;

export const UserName = styled.h5`
  margin: 0 12px;
  /* Font style */
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.oxford_blue};

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const BarItemContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  @media only screen and (max-width: 1023px) {
    margin-top: 10px;
  }
`;

export const BarContainer = styled.div<StyledProps>`
  user-select: none;
  z-index: ${({ visible }) => (visible ? 11 : 1)};
`;

export const MobileNavWrapper = styled.div`
  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: calc(100vh - 72px);
    top: 72px;
    left: 0;
    z-index: 12;
    position: absolute;
    box-shadow: 0 2px 18px rgba(0, 0, 0, 0, 25);
    background: rgba(5, 22, 56, 0.6);
    overflow: scroll;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: flex-start;
    background: ${colors.white};
    margin-left: unset;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    z-index: 11;
    padding-bottom: 54px;
  }
`;

export const BarText = styled.div<StyledProps>`
  margin-right: 40px;
  font-size: 16px;
  color: ${({ isSelected }) =>
    isSelected ? colors.ochre : colors.oxford_blue};
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  display: flex;
  align-items: center;
  cursor: pointer;

  @media only screen and (max-width: 1023px) {
    margin-top: 30px;
    font-size: ${({ isSelected }) => (isSelected ? "24px" : "16px")};
    line-height: ${({ isSelected }) => (isSelected ? "28px" : "20px")};
    font-weight: 700;
  }
`;

export const StocksMargin = styled.div`
  margin-right: 8px;
`;

export const ListItem = styled.div<StyledProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 16px 24px;
  margin: 0 0 0 16px;
  justify-content: space-between;
  p {
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-family: "Helvetica";
    color: ${colors.font_grey};
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  }
  &:hover {
    p {
      color: ${colors.blue_default};
    }
    background: rgba(48, 98, 197, 0.06);
  }

  @media only screen and (max-width: 1023px) {
    padding: 16px 0 16px 24px;

    p {
      font-size: 14px;
    }
  }
`;

export const ListItemHeader = styled.div`
  display: flex;
  cursor: default;
  align-items: center;
  padding: 16px 24px;
  justify-content: space-between;
  p {
    font-size: 16px;
    line-height: 20px;
    font-style: normal;
    font-family: "Helvetica";
    color: ${colors.font_grey};
    font-weight: 700;
  }

  @media only screen and (max-width: 1023px) {
    padding: 16px 0 16px 24px;

    p {
      font-size: 14px;
    }
  }
`;

export const OneList = styled.div`
  margin: 14px 0;
`;

export const List = styled.div`
  top: 34px;
  left: -150px;
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  background: ${colors.white};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 1023px) {
    position: unset;
    box-shadow: unset;
  }
    @media only screen and (min-width: 1023px) {
    white-space: nowrap;
  }
`;
