import React from "react";
import moment from "moment";

import { VictoryCustomTooltipProps } from "./types";
import { numberFormatter } from "application/helpers/numberFormatter";
import {
  ToolTipHeight,
  ToolTipWidth,
  ToolTipHeightWithMovingAvg,
  ToolTipBigWidth,
} from "features/chart/constants";

import {
  SD,
  Text,
  Date,
  Percent,
  Container,
  ValueContainer,
  Avg,
} from "./styles";
import { getTooltipPosition } from "features/chart/helpers/getTooltipPosition";
import { handleBigNumbers } from "application/helpers/handleBigNumbers";

const VictoryCustomTooltip = (props: VictoryCustomTooltipProps) => {
  const { datum, isPercent, y = 0, x = 0, withNumberFormater = false } = props;
  const hasMonthMovingAvg = !!datum && !!datum.m;
  const { tooltipX, tooltipY } = getTooltipPosition(x, y, hasMonthMovingAvg, withNumberFormater);

  const getValue = () => {
    if (isPercent) {
      return `${numberFormatter(datum?.c || 0)}%`;
    } else if (withNumberFormater) {
      return handleBigNumbers(datum?.c || 0, props.doNotShowDecimals);
    } else {
      return numberFormatter(datum?.c || 0);
    }
  };

  const getMonthMovingAvgValue = () => {
    return numberFormatter(datum?.m || 0);
  };

  const height = hasMonthMovingAvg ? ToolTipHeightWithMovingAvg : ToolTipHeight;

  return (
    <g style={{ pointerEvents: "none" }}>
      <foreignObject
        y={tooltipY}
        x={tooltipX}
        width={withNumberFormater ? ToolTipBigWidth : ToolTipWidth}
        height={height}
        style={{ overflow: "visible" }}
      >
        <Container>
          <Date>{moment(datum?.a).format("MMM, YYYY")}</Date>
          <ValueContainer>
            <Text>Calculated value</Text>
            <Percent>{getValue()}</Percent>
          </ValueContainer>
          {datum?.m && (
            <ValueContainer>
              <Text>Moving Avg</Text>
              <Avg>{getMonthMovingAvgValue()}</Avg>
            </ValueContainer>
          )}
          {datum?.sd !== undefined && (
            <ValueContainer>
              <Text>
                {props.difference ? "Difference" : "Standard deviations"}
              </Text>
              <SD>{numberFormatter(datum?.sd || 0)}</SD>
            </ValueContainer>
          )}
        </Container>
      </foreignObject>
    </g>
  );
};

export default VictoryCustomTooltip;
