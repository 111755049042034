import styled from "styled-components";

interface Props {
  menuVisible: boolean
}
export const Container = styled.div<Props>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  max-height: ${({menuVisible}) => (menuVisible ? "100vh": "unset")};
  overflow: ${({menuVisible}) => (menuVisible ? "hidden": "unset")};
`;

export const ContentContainer = styled.div`
  width: 1140px;
  margin: 0 auto;

  @media only screen and (max-width: 1160px) {
    width: 990px;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
  
  @media only screen and (max-width: 647px) {
    width: 100%;
  }
`;