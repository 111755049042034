import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import { FilterDataItem, ValuationSummary } from "./model";
import BondValuationSummaryActions from "./actions";

const initialState: ValuationSummary = {
  metrics: {},
  tableData: {},
  filters: {
    selectedMetric: { code: "", name: "" },
    selectedIndices: { code: "", name: "" },
  },
};

export const bondValuationSummarySlice = createSlice({
  name: "bond-valuation-summary",
  initialState,
  reducers: {
    setTableData: (state, action) => {
      state.tableData = action.payload;
    },
    setBondFilterMetric: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedMetric = action.payload;
    },
    setBondFilterIndices: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedIndices = action.payload;
    },
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    // getTableData
    builder.addCase(
      BondValuationSummaryActions.getValuationSummaryTableData.fulfilled,
      (state, action) => {
        state.tableData = action.payload;
      }
    );
    builder.addCase(
      BondValuationSummaryActions.getBondMetrics.fulfilled,
      (state, action) => {
        state.metrics = action.payload;
      }
    );
  },
});

export default bondValuationSummarySlice.reducer;
export const bondValuationSelector = () =>
  store.getState().bondValuationSummary;
export const {
  setBondFilterMetric,
  reset,
  setBondFilterIndices,
  setTableData,
} = bondValuationSummarySlice.actions;
