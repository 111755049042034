import { IndexTypes, NoData } from "./types";

// we need this value of 100 because of sorting!
// if value equal to 0 it produces issue with blinking sorting
export const globalMock: NoData[] = [
  {
    type: IndexTypes.global,
    indexName: "globalMock",
    indexDescription: "",
    mtd: 100,
    threeMtd: 100,
    ytd: 100,
    oneYr: 100,
    threeYr: 100,
    fiveYr: 100,
    tenYr: 100,
    twentyYr: 100,
  },
];

export const developedMarketsMock: NoData[] = [
  {
    type: IndexTypes.developedMarkets,
    indexName: "developedMarketsMock",
    indexDescription: "",
    mtd: 100,
    threeMtd: 100,
    ytd: 100,
    oneYr: 100,
    threeYr: 100,
    fiveYr: 100,
    tenYr: 100,
    twentyYr: 100,
  },
];

export const emergingMarketslMock: NoData[] = [
  {
    type: IndexTypes.emergingMarkets,
    indexName: "emergingMarketslMock",
    indexDescription: "",
    mtd: 100,
    threeMtd: 100,
    ytd: 100,
    oneYr: 100,
    threeYr: 100,
    fiveYr: 100,
    tenYr: 100,
    twentyYr: 100,
  },
];

export const countriesMock: NoData[] = [
  {
    type: IndexTypes.countries,
    indexName: "countriesMock",
    indexDescription: "",
    mtd: 100,
    threeMtd: 100,
    ytd: 100,
    oneYr: 100,
    threeYr: 100,
    fiveYr: 100,
    tenYr: 100,
    twentyYr: 100,
  },
];
