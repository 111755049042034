import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { authSlice, requestSlice } from "common";
import { notifySlice } from "common/notify/slice";
// import { homeSlice } from "common/home/store/slice";
import { historical } from "../common/historicalStock/store/slice";
import { expectStockSlice } from "common/expectedStock/store/slice";
import { valuationReportSlice } from "common/valuationReports/store/slice";
import { indicesComparisonSlice } from "common/indicesComparison/store/slice";
import { valuationSummarySlice } from "common/valuationSummary/store/slice";
import { indicesPerformanceSummarySlice } from "common/indicesPerformanceSummary/store/slice";
import { bondSummarySlice } from "common/bondIndicesPerformanceSummary/store/slice";
import { bondValuationSummarySlice } from "common/bondValuationSummary/store/slice";
import { bondYieldCurveSlice } from "common/bondSpreadSummary/store/slice";
import { stockBondYieldComparisonSlice } from "common/stockBondYieldsComparison/store/slice";
import { expectedReturnsModelingSlice } from "common/bondExpectedReturnsModeling/store/slice";
import { bondValuationReportSlice } from "common/fixedIncomeCharts/store/slice";

const reducer = combineReducers({
  auth: authSlice.reducer,
  valuationReport: valuationReportSlice.reducer,
  notify: notifySlice.reducer,
  request: requestSlice.reducer,
  expectStock: expectStockSlice.reducer,
  historicalStock: historical.reducer,
  indicesComparison: indicesComparisonSlice.reducer,
  valuationSummary: valuationSummarySlice.reducer,
  bondValuationSummary: bondValuationSummarySlice.reducer,
  indicesPerformanceSummary: indicesPerformanceSummarySlice.reducer,
  bondSummary: bondSummarySlice.reducer,
  yieldCurveSelector: bondYieldCurveSlice.reducer,
  stockBondYieldComparison: stockBondYieldComparisonSlice.reducer,
  expectedReturnsModeling: expectedReturnsModelingSlice.reducer,
  bondValuationReport: bondValuationReportSlice.reducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
