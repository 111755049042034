import { LargeTitle } from "application/styles/text";
import styled from "styled-components";
import { colors } from "../../application/styles/colors";
import { ReactComponent as ArrowBackIcon } from "../../application/assets/images/icons/arrow-back.svg";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

interface Props {
  compare?: boolean;
}

interface MainContainerWrapperProps {
  isModalOpened: boolean;
}

export const Container = styled.div`
  position: relative;
  min-height: 101vh;

  .sticky-table-cell {
    span {
      width: 100px;
    }
  }

  @media only screen and (max-width: 1023px) {
    min-height: unset;
    padding: 0 16px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 52px 0 24px 0;

  @media only screen and (max-width: 1023px) {
    margin: 0;
  }
`;

export const Title = styled(LargeTitle)`
  font-family: "Time";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  text-align: left;

  @media (max-width: 1023px) {
    font-family: "Helvetica";
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 0;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  margin: 32px 0 24px 0;

  @media (max-width: 1023px) {
    justify-content: center;
    margin: 24px 0 16px;
  }
`;

export const DropContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    flex-wrap: wrap;

    > div {
      flex-grow: 1;
      margin-left: 0;
    }

    > div:nth-child(2) {
      margin-left: 16px;
    }

    > div:nth-child(3) {
      width: 100%;
      margin-top: 16px;
    }
  }

  @media (max-width: 647px) {
    flex-direction: column;
    > div {
      margin-left: 0 !important;
    }

    > div:nth-child(2) {
      margin-top: 16px;
    }
  }
`;

export const CompareButton = styled.button<Props>`
  width: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: ${colors.ochre};
  color: ${colors.white};
  border: none;
  padding: 14px 16px;
  cursor: pointer;
  text-transform: uppercase;

  &[disabled] {
    background: ${colors.grey_500};
  }

  @media (max-width: 647px) {
    display: ${({ compare }) => (compare ? "none" : "unset")};
  }
`;

export const ArrowBackContainer = styled.div`
  display: none;
  background: ${colors.ochre};

  @media (max-width: 647px) {
    display: block;
  }
`;

export const ArrowBack = styled(ArrowBackIcon)`
  padding: 10px;
  stroke: ${colors.font_grey};
`;

export const TableInfo = styled.p`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
`;

export const TableInfoWrapper = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;

  @media (max-width: 1023px) {
    margin: 16px 0;
    align-items: flex-end;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 16px);
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background: #fff;
    }
  }

  @media (max-width: 647px) {
    flex-direction: ${({ compare }) => (compare ? "row-reverse" : "row")};
  }
`;

export const UnderContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 647px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: 647px) {
    margin-top: 12px;
  }
`;

export const BottomMfruLogo = styled.img`
  width: 95px;
  height: 38px;
  cursor: pointer;
  margin-right: 20px;

  @media (max-width: 1024px) {
    width: 75px;
    height: 30px;
    margin-left: 16px;
  }

  @media (max-width: 647px) {
    margin-left: 8px;
  }
`;

export const BottomLogo = styled.img`
  width: 92px;
  height: 32px;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: 74px;
    height: 26px;
    margin-left: 16px;
  }

  @media (max-width: 647px) {
    margin-left: 8px;
  }
`;

export const SubTitle = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  font-family: "Time";

  @media (max-width: 1023px) {
    font-family: "Helvetica";
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 0;
  }
`;

export const OpenChartIcon = styled(AssessmentOutlinedIcon)`
  width: 18px;
  height: 18px;
  color: ${colors.ochre};
  cursor: pointer;
`;
