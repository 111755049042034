import styled from "styled-components/macro";

import { colors } from "application/styles/colors";
import { ReactComponent as ArrowIcon } from "application/assets/images/icons/arrow-white.svg";

export const Container = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  p {
    margin: 0 8px;
    /* Font style */
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.white};
  }
  img {
    width: 24px;
    height: 24px;
  }

  @media only screen and (max-width: 1023px) {
    p {
      margin: 0 8px 0 0;
    }
  }
`;

export const IconAccept = styled(ArrowIcon)`
  stroke: ${colors.green_a_400};
`;
