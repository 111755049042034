import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "application/store";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import ApiValuationSummary from "integration/api/ApiValuationSummary";

class ValuationSummaryActions {
  static getValuationSummaryTableData = createAsyncThunk(
    `valuation-summary/${ResponseHandlerName.GetExpectedStockTableData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).valuationSummary;

      const metric = filters.selectedMetric.code;
      try {
        const res = await ApiValuationSummary.getValuationSummary({ metric });
        return res.data;
      } catch (error) {
        console.log("getValuationSummaryTableData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static getMetrics = createAsyncThunk(
    `valuation-summary/${ResponseHandlerName.GetMetricsValuation}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiValuationSummary.getMetrics({
          tableView: "VALUATIONS_AND_EARNINGS_SUMMARY_TABLE",
        });
        return res.data;
      } catch (error) {
        console.log("getMetrics Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default ValuationSummaryActions;
