import { IndexTypes, NoData } from "./types";

// we need this value of 100 because of sorting!
// if value equal to 0 it produces issue with blinking sorting
export const globalMock: NoData[] = [
  {
    type: IndexTypes.global,
    indexName: "globalMock",
    beginningYtm: 100,
    absolutePriceChange: 100,
    interestRateChangeAnnualImpact: 100,
    creditSpreadChangeAnnualImpact: 100,
    corporateDefaultAnnualImpact: 100,
    yearExpectedAnnualizedNominalReturn: 100,
  },
];

export const developedMarketsMock: NoData[] = [
  {
    type: IndexTypes.developedMarkets,
    indexName: "developedMarketsMock",
    beginningYtm: 100,
    absolutePriceChange: 100,
    interestRateChangeAnnualImpact: 100,
    creditSpreadChangeAnnualImpact: 100,
    corporateDefaultAnnualImpact: 100,
    yearExpectedAnnualizedNominalReturn: 100,
  },
];

export const emergingMarketslMock: NoData[] = [
  {
    type: IndexTypes.emergingMarkets,
    indexName: "emergingMarketslMock",
    beginningYtm: 100,
    absolutePriceChange: 100,
    interestRateChangeAnnualImpact: 100,
    creditSpreadChangeAnnualImpact: 100,
    corporateDefaultAnnualImpact: 100,
    yearExpectedAnnualizedNominalReturn: 100,
  },
];

export const countriesMock: NoData[] = [
  {
    type: IndexTypes.usa,
    indexName: "usaMock",
    beginningYtm: 100,
    absolutePriceChange: 100,
    interestRateChangeAnnualImpact: 100,
    creditSpreadChangeAnnualImpact: 100,
    corporateDefaultAnnualImpact: 100,
    yearExpectedAnnualizedNominalReturn: 100,
  },
];
