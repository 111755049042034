import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { styled } from "@mui/material/styles";

const CustomizedTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 13,
    padding: "8px",
  },
}));

const OptionalTooltip = ({
  text,
  placement = "bottom",
  children,
}: {
  text?: string;
  placement?:
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  children: React.ReactElement<any, any>;
}) => {
  return text && text.length > 0 ? (
    <CustomizedTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 500 }}
      enterDelay={1000}
      leaveDelay={100}
      placement={placement}
      title={text}
    >
      {children}
    </CustomizedTooltip>
  ) : (
    children
  );
};

export default OptionalTooltip;
