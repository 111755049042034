import styled from "styled-components";

import { NotifyState } from "common/notify/model";
import { colors } from "application/styles/colors";
import { ReactComponent as ArrowIcon } from "application/assets/images/icons/arrow-white.svg";
import { ReactComponent as CloseIconSvg } from "application/assets/images/icons/close-icon-test.svg";

interface StyledProps {
  type: NotifyState["data"]["type"];
}

export const Container = styled.div<StyledProps>`
  top: 24px;
  z-index: 1;
  right: 24px;
  position: fixed;
  width: 389px;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  background-color: ${({ type }) =>
    type === "success" ? colors.green_a_400 : colors.red_100};

  @media only screen and (max-width: 647px) {
    width: 100%;
    top: 0;
    right: 0;
  }
`;

export const Text = styled.p<StyledProps>`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ type }) => (type === "success" ? colors.white : colors.red_900)};
`;

export const InfoIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const InfoIconAccept = styled(ArrowIcon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: ${colors.white};
`;

export const CloseIcon = styled(CloseIconSvg)<StyledProps>`
  width: 24px;
  height: 24px;
  margin-left: auto;
  stroke: ${({ type }) => (type === "success" ? colors.white : colors.red_900)};
`;
