import { HeadCell } from "../../components/StickyHeaderTable/types";

export const headCells: HeadCell[] = [
  {
    id: "indexName",
    label: "Index",
    diffBackground: false,
  },
  {
    id: "decadeAvgDividendYield",
    label: "Dividend Yield",
    diffBackground: false,
    tooltip:
      "The 10-year annualized contribution of dividends to the index's overall performance, measured by the index’s average dividend yield over the past ten years.",
  },
  {
    id: "nominalEarningGrowth",
    label: "Nominal Earnings Growth",
    diffBackground: false,
    tooltip:
      "The 10-year annualized contribution of earnings per share growth on index performance, measured in local currency without inflation adjustment.",
  },
  {
    id: "valuationAdjustment",
    label: "Valuation Adjustment",
    diffBackground: false,
    tooltip:
      "The 10-year annualized contribution of changing valuations as reflected in +/- shifts of the index’s price-to-earnings ratio, which measures how much investors are paying for a dollar’s worth of earnings.",
  },
  {
    id: "currencyExchangeImpact",
    label: "Currency Exchange Impact",
    diffBackground: false,
    tooltip:
      "10-year annualized impact of currency exchange rates on performance, relative to the U.S. dollar. A strengthening dollar leads to a negative currency exchange impact. A weakening dollar leads to a positive impact. USA Indexes show 0.0%.",
  },
  {
    id: "unexplained",
    label: "Unexplained",
    diffBackground: false,
    tooltip:
      "The difference in the 10-year Annualized Nominal Return and the sum of the four performance drivers—a residual amount that wasn’t captured by the other variables.",
  },
  {
    id: "annualizedNominalReturn",
    label: "10-year Annualized Nominal Return",
    diffBackground: true,
    tooltip:
      "The total return, also known as the compound annual growth rate, of the index for the previous 10 years without adjusting for inflation.",
  },
  {
    id: "beginningPriceToEarningsRatio",
    label: "Beginning P/E Ratio",
    diffBackground: false,
    tooltip:
      "The index’s price-to-earnings ratio at the beginning of the 10-year measurement period. ",
  },
  {
    id: "endingPriceToEarningsRatio",
    label: "Ending P/E Ratio",
    diffBackground: false,
    tooltip:
      "The index’s price-to-earnings ratio at the end of the 10-year measurement period. ",
  },
];
