import React, { useState } from "react";
import moment, { Moment } from "moment";
import { TextFieldProps } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePickerContainer, Input, InputLabel } from "./styles";
import { DatePickerProps } from "./models";

const DatePicker = ({
  label,
  minDate,
  maxDate,
  value,
  onChange,
}: DatePickerProps) => {
  const [open, setOpen] = useState(false);

  const handleChange = (newValue: Moment | null) => {
    const newDate = moment(newValue) || moment();
    onChange(newDate);
  };

  const renderInput = (props: TextFieldProps) => (
    <Input
      {...props}
      onClick={() => setOpen(true)}
      inputProps={{ ...props.inputProps, readOnly: true }}
    />
  );

  return (
    <DatePickerContainer>
      {!!label && <InputLabel>{label}</InputLabel>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          // open picker from input
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChange}
          renderInput={renderInput}
          openTo="year"
          inputFormat="MM/yyyy"
          views={["year", "month"]}
          className="date-picker"
        />
      </LocalizationProvider>
    </DatePickerContainer>
  );
};

export default DatePicker;
