import React, { useState } from "react";
import {
  Arrow,
  BarText,
  StocksMargin,
  List,
  ListItem,
  BarItemContainer,
  BarContainer,
  ListItemHeader,
} from "./styles";

import arrowSource from "application/assets/images/icons/arrow-down.svg";
import { Filter, OneList } from "../DropGroup/styles";
import { PathNames } from "application/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";

const BarItem = ({
  title,
  isSelected,
  onClose,
  menuItems,
  showArrow = true,
  onClick = () => {},
}: {
  title: string;
  isArrow?: boolean;
  isSelected: boolean;
  onClose: (isClose: boolean) => void;
  menuItems: { name: string; path?: PathNames }[];
  showArrow?: boolean;
  onClick?: () => void;
}) => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleVisible = () => setVisible(!visible);

  const handleSelect = (item: { name: string; path: string }) => {
    setVisible(false);
    onClose(false);
    navigate(item.path);
  };

  const renderList = () => (
    <OneList>
      {menuItems.map((item) => {
        return item.path ? (
          <ListItem
            key={item.name}
            isSelected={location.pathname === item.path}
            onClick={() => handleSelect({ name: item.name, path: item.path! })}
          >
            <Typography variant="body1">{item.name}</Typography>
          </ListItem>
        ) : (
          <ListItemHeader>
            <Typography variant="body1">{item.name}</Typography>
          </ListItemHeader>
        );
      })}
    </OneList>
  );

  return (
    <BarItemContainer>
      <BarContainer visible={visible}>
        <BarText
          isSelected={isSelected}
          onClick={() => (showArrow ? toggleVisible() : onClick())}
        >
          <StocksMargin>{title}</StocksMargin>
          {showArrow && <Arrow src={arrowSource} alt="arrow" />}
        </BarText>
        {visible && <List>{renderList()}</List>}
      </BarContainer>
      {visible && <Filter onClick={toggleVisible} />}
    </BarItemContainer>
  );
};

export default BarItem;
