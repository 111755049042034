import React from "react";
import moment from "moment";
import { DateDot, UpdateDate } from "./styles";

const LastUpdateDate = ({
  date,
  showCurrency = true,
}: {
  date: string;
  showCurrency?: boolean;
}) => {
  return (
    <UpdateDate>
      Data as of {moment.utc(date).format("MMMM D, YYYY")}
      {showCurrency && <DateDot>•</DateDot>}
      {showCurrency && "Valued in U.S. Dollars"}
    </UpdateDate>
  );
};

export default LastUpdateDate;
