import Api from "../Api";
import {
  GetHistoricalStockParams,
  GetHistoricalStockDateCalculationParams,
} from "./models";
import { List } from "application/components/StickyHeaderTable/types";

class ApiHistoricalStock extends Api {
  static getHistoricalStock({ date }: GetHistoricalStockParams) {
    return ApiHistoricalStock.instance.axiosInst.get<List>(
      "/stock/historical",
      { params: { date } }
    );
  }

  static getHistoricalStockDateCalculation({
    date,
  }: GetHistoricalStockDateCalculationParams) {
    return ApiHistoricalStock.instance.axiosInst.get<{
      dateByYearAndMonth: string;
    }>("/stock/historical/calculation-date", { params: { date } });
  }
}

export default ApiHistoricalStock;
