import { colors } from "application/styles/colors";
import styled from "styled-components";

export const Filter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(5, 22, 56, 0.7);
`;

export const Container = styled.div`
  padding: 36px;
  box-sizing: border-box;
  width: 669px;
  max-height: 80vh;
  overflow-y: auto;
  background-color: ${colors.white};

  @media only screen and (max-width: 674px) {
    max-height: 100vh;
    padding: 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.stroke_grey};

  img {
    cursor: pointer;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  font-style: normal;
  color: ${colors.black};
  font-family: "Helvetica";
`;

export const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  color: ${colors.black};
  font-family: "Helvetica";
`;

export const Text = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  font-style: normal;
  color: ${colors.font_grey};
  font-family: "Helvetica";
`;
