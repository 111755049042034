import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  ButtonsContainer,
  ChartContainer,
  CloseButton,
  ModalContent,
  ModalOverlay,
} from "./styles";
import { ChartModalProps, FilterDataItem } from "./types";
import { useMediaQuery, useTheme } from "@mui/material";
import Chart from "features/chart";
import {
  resetYieldCurveIndiciesData,
  setSelectedIndexPair,
  yieldCurveSelector,
} from "common/bondSpreadSummary/store/slice";
import BondYieldCurveActions from "common/bondSpreadSummary/store/actions";

const YieldCurveChartModal = ({
  selectedIndex,
  onClose,
}: ChartModalProps) => {
  const dispatch = useAppDispatch();

  const requestData = useAppSelector(requestSelector);
  const loading =
    requestData.getYieldCurveFilters.loading ||
    requestData.getYieldCurveChart.loading;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const { indicesData, indexPair } = useAppSelector(yieldCurveSelector);

  const handleIndexPairSelect = (item: FilterDataItem) => {
    const indexCodeA = item.code.split("+")[0];
    const indexCodeB = item.code.split("+")[1];
    dispatch(setSelectedIndexPair({ indexCodeA, indexCodeB }));
  };

  useEffect(() => {
    handleIndexPairSelect(selectedIndex);
  }, []);

  useEffect(() => {
    if (indexPair.indexCodeA && indexPair.indexCodeB) {
      dispatch(BondYieldCurveActions.getCharts());
    }
  }, [indexPair.indexCodeA, indexPair.indexCodeB]);

  const onCloseHandler = () => {
    dispatch(resetYieldCurveIndiciesData());
    onClose();
  };

  const modalRootElement = document.getElementById("modal");

  return createPortal(
    <ModalOverlay onClick={onCloseHandler}>
      <ModalContent onClick={(event) => event.stopPropagation()}>
        <ChartContainer>
          <Chart
            loading={loading}
            indicesData={indicesData}
            filters={{ selectedMetric: selectedIndex, selectedIndices: { code: "", name: "" } }}
            isTablet={isTablet}
            adjustedChartHeight={270}
            isBloomberg
          />
        </ChartContainer>
        <ButtonsContainer>
          <CloseButton onClick={onCloseHandler}>
            <p>Close</p>
          </CloseButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalOverlay>,
    modalRootElement!
  );
};

export default YieldCurveChartModal;
