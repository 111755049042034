import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "application/store";
import { applicationConfig } from "application/config/applicationConfig";
import { historicalPageLoading } from "common/responseHandlerService/slice";
import SmallLoader from "application/components/SmallLoader";
import MainContainer from "application/components/MainContainer";
import NotifyPopUpTable from "application/components/NotifyPopUpTable";
import {
  Title,
  Loader,
  SubTitle,
  Container,
  BottomLogo,
  TitleContainer,
  UnderContentInfo,
  LogoWrapper,
  BottomMfruLogo,
} from "./styles";
import logoBlueSource from "application/assets/images/logo-blue.svg";
import mfruLogoSource from "application/assets/images/mfru-logo.svg";
import { resetState } from "./store/slice";
import BondsComparisonFilters from "application/components/BondsComparisonFilters";
import BondsComparisonTable from "application/components/BondsComparisonTable";
import BloombergInfo from "application/components/BloombergInfo";

const BondsComparison = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    //Clear state on component unmount
    return () => {
      dispatch(resetState());
    };
  }, []);

  const loading = useAppSelector(historicalPageLoading);
  const [isBloombergVisible, setIsBloombergVisible] = React.useState<boolean>(false);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));

  const toggleMSCIVisible = () => {
    setIsBloombergVisible(!isBloombergVisible);
  };

  return (
    <MainContainer>
      <Container>
        {loading && (
          <Loader>
            <SmallLoader />
          </Loader>
        )}

        <>
          <TitleContainer>
            <Title>Bond Market Performance Comparison Tool</Title>
            <SubTitle>
              You can select from 2 up to 5 indexes to calculate and compare
              metrics
            </SubTitle>
          </TitleContainer>

          <div>
            <BondsComparisonFilters />
            <BondsComparisonTable />
          </div>

          <UnderContentInfo>
            <BloombergInfo
              isBloombergVisible={isBloombergVisible}
              underTable
              onChange={toggleMSCIVisible}
            />
            <LogoWrapper>
              <BottomMfruLogo
                src={mfruLogoSource}
                alt="logo"
                onClick={() => {
                  window.open(applicationConfig.main_page, "_blank");
                }}
              />
              <BottomLogo
                src={logoBlueSource}
                alt="logo"
                onClick={() => {
                  window.open(applicationConfig.assetcamp, "_blank");
                }}
              />
            </LogoWrapper>
          </UnderContentInfo>
        </>
      </Container>
      {isTablet && <NotifyPopUpTable />}
    </MainContainer>
  );
};

export default BondsComparison;
