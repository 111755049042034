import React, { useState } from "react";
import moment, { Moment } from "moment";
import { TextFieldProps } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Input, InputLabel } from "./styles";
import { YearMonthPickerProps } from "./models";

const YearMonthPicker = ({
  label,
  minDate,
  maxDate,
  defaultValue,
  onChange,
}: YearMonthPickerProps) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const handleChange = (newValue: Moment | null) => {
    const newDate = moment(newValue) || moment();
    setValue(newDate);
  };

  const onMonthChange = (newValue: Moment | null) => {
    const newDate = moment(newValue) || moment();
    onChange(newDate);
  };

  const renderInput = (props: TextFieldProps) => (
    <Input
      {...props}
      onClick={() => setOpen(true)}
      inputProps={{ ...props.inputProps, readOnly: true }}
    />
  );

  return (
    <div>
      {!!label && <InputLabel>{label}</InputLabel>}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          // open picker from input
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          //
          value={value}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleChange}
          renderInput={renderInput}
          onMonthChange={onMonthChange}
          openTo="year"
          inputFormat="yyyy, MMMM"
          views={["year", "month"]}
        />
      </LocalizationProvider>
    </div>
  );
};

export default YearMonthPicker;
