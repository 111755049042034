import { FilterData } from "common/indicesPerformanceSummary/store/models";
import Api from "../Api";

class ApiBondSummary extends Api {
  static getBondSummary(endingDate: string) {
    return ApiBondSummary.instance.axiosInst.get<{
      data: string;
    }>("/bond/summary-table", {
      params: {
        endingDate,
      },
    });
  }

  static getBondFilters() {
    return ApiBondSummary.instance.axiosInst.get<FilterData>(
      "/bond/summary-table/filters"
    );
  }
}

export default ApiBondSummary;
