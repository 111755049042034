import { Filters } from "common/bondExpectedReturnsModeling/store/model";
import Api from "../Api";

class ApiBondExpectedReturnsModeling extends Api {
  static getFilters() {
    return ApiBondExpectedReturnsModeling.instance.axiosInst.get<Filters>(
      "/bond/expected-returns-modeling/filters"
    );
  }
  static getTableData({
    corporateDefaultRate,
    creditSpreadChange,
    defaultRecoveryRate,
    interestRateChange,
    timePeriod,
  }: {
    corporateDefaultRate: string;
    creditSpreadChange: string;
    defaultRecoveryRate: string;
    interestRateChange: string;
    timePeriod: string;
  }) {
    return ApiBondExpectedReturnsModeling.instance.axiosInst.get<{
      data: string;
    }>("/bond/expected-returns-modeling", {
      params: {
        corporateDefaultRate,
        creditSpreadChange,
        defaultRecoveryRate,
        interestRateChange,
        timePeriod,
      },
    });
  }
}

export default ApiBondExpectedReturnsModeling;
