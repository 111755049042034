import styled from "styled-components/macro";
import { colors } from "application/styles/colors";

interface StyledProps {
  visible?: boolean;
  isSelected?: boolean;
}

const mediaStep_1 = 1023;
const mediaStep_2 = 940;
const mediaStep_3 = 760;
const mediaStep_4 = 500;

export const Filter = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  position: fixed;
`;

export const Disabled = styled.div`
  z-index: 12;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const Label = styled.label`
  display: block;
  margin: 0 0 4px 4px;
  /* Font style */
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};

  @media (max-width: ${mediaStep_2}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: ${mediaStep_3}px) {
    grid-column: span 2;
  }

  @media (max-width: ${mediaStep_4}px) {
    grid-column: span 4;
  }
`;

export const Container = styled.div<StyledProps>`
  box-sizing: border-box;
  user-select: none;
  position: relative;
  z-index: ${({ visible }) => (visible ? 11 : 1)};
  outline: ${({ visible }) =>
    `4px solid ${visible ? colors.light_blue : "transparent"}`};

  @media (max-width: ${mediaStep_1}px) {
    outline: none;
  }
`;

export const Selected = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 10px 0 16px;
  background-color: ${colors.stripe_grey_light};
  img {
    width: 24px;
    height: 24px;
  }
  p {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    /* Font style */
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    font-family: "Helvetica";
    color: ${colors.black};
  }

  @media (max-width: ${mediaStep_2}px) {
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const List = styled.div`
  top: 56px;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  position: absolute;
  box-sizing: border-box;
  background: ${colors.white};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
`;

export const ListGroup = styled.div`
  margin: 20px 0;
  border-bottom: 1px solid ${colors.stripe_grey_light};
`;

export const OneList = styled.div`
  margin: 14px 0;
`;

export const ListLabel = styled.label`
  display: block;
  margin: 0 0 12px 24px;
  /* Font style */
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  font-style: normal;
  text-transform: uppercase;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;

export const ListItem = styled.div<StyledProps>`
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 10px 24px 10px 15px;
  p {
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-family: "Helvetica";
    color: ${colors.font_grey};
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  }
  &:hover {
    p {
      color: ${colors.blue_default};
    }
    background: rgba(48, 98, 197, 0.06);
  }
`;
