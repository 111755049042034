import styled, { css } from "styled-components";

import { PrimaryButtonProps } from "./types";
import { colors } from "application/styles/colors";

interface StyledProps {
  btn_type: PrimaryButtonProps["btn_type"];
}

export const LoaderContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

export const Container = styled.button<StyledProps>`
  width: 100%;
  height: 44px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  /* Font style */
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  font-style: normal;
  font-family: "Helvetica";
  text-transform: uppercase;

  ${({ btn_type }) => {
    const isPrimary = btn_type === "primary";
    return css`
      color: ${isPrimary ? colors.white : colors.navy_blue_default};
      border: ${isPrimary ? "none" : `1px solid ${colors.stroke_grey}`};
      background-color: ${isPrimary ? colors.brown_default : "transparent"};
      &:hover {
        background-color: ${isPrimary ? colors.brown_hover : "transparent"};
      }
    `;
  }};
`;
