import React from "react";

import { PrimaryButtonProps } from "./types";

import SmallLoader from "../SmallLoader";
import { Container, LoaderContainer } from "./styles";

const PrimaryButton = ({
  title,
  loading,
  btn_type = "primary",
  onClick,
  ...rest
}: PrimaryButtonProps) => {
  if (loading) {
    return (
      <LoaderContainer>
        <SmallLoader />
      </LoaderContainer>
    );
  }
  return (
    <Container btn_type={btn_type} onClick={onClick} {...rest}>
      {title}
    </Container>
  );
};

export default PrimaryButton;
