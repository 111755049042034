import styled from "styled-components";

import { colors } from "application/styles/colors";
import { LargeTitle } from "../../application/styles/text";

const mediaStep_1 = 1023;

export const Container = styled.div`
  position: relative;
  min-height: 101vh;

  @media only screen and (max-width: 1024px) {
    padding: 0 16px;
    min-height: unset;
  }
`;

export const TitleContainer = styled.div`
  margin: 52px 0 24px 0;

  @media only screen and (max-width: 1023px) {
    margin: 0;
  }
`;

export const Title = styled(LargeTitle)`
  text-align: left;

  @media only screen and (max-width: 1023px) {
    font-family: "Helvetica";
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 0;
  }
`;

export const SubTitle = styled.h4`
  margin-top: 20px;
  margin-bottom: 28px;
  /* Font style */
  font-size: 20px;
  font-weight: 700;
  color: ${colors.black};
  font-family: "Helvetica";
  @media only screen and (max-width: ${mediaStep_1}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Loader = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  padding-top: 35vh;
  display: flex;
  position: absolute;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const TableInfo = styled.p`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
  }
`;

export const DateDot = styled.span`
  margin: 0 4px;
`;

export const CompareButton = styled.button`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: ${colors.ochre};
  color: ${colors.white};
  border: none;
  padding: 14px 16px;
  cursor: pointer;
  text-transform: uppercase;

  img {
    margin-right: 10px;
  }
`;

export const UnderContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 647px) {
   flex-direction: column;
    align-items: flex-start;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: 647px) {
  margin-top: 12px;
  }
`;

export const BottomMfruLogo = styled.img`
  width: 95px;
  height: 38px;
  cursor: pointer;
  margin-right: 20px;
  
  @media (max-width: 1024px) {
    width: 75px;
    height: 30px;
    margin-left: 16px;
  }

  @media (max-width: 647px) {
    margin-left: 8px;
  }
`;

export const BottomLogo = styled.img`
  width: 92px;
  height: 32px;
  cursor: pointer;
  
  @media (max-width: 1024px) {
    width: 74px;
    height: 26px;
    margin-left: 16px;
  }

  @media (max-width: 647px) {
    margin-left: 8px;
  }
`;
