import { Product, Subscription } from "common/auth/store/model";

export const getSubscriptionSlogan = (
  product: Product | undefined,
  subscription: Subscription | undefined
) => {
  const isMonthlyActive = product?.period === "month";

  return isMonthlyActive ? "/ month" : "Paid in full annually";
};
