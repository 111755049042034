import React from "react";
import { Text, MobileAuth } from "./styles";

interface Props {
  type: string,
  navigateTo: any
}

const HeaderAuth = ({ type, navigateTo }: Props) => {
  return (
    <MobileAuth>
      <Text onClick={type === "sign-in" ? () => {} : navigateTo} isActive={type === "sign-in"}>
        Login
      </Text>
      <Text onClick={type === "sign-up" ? () => {} : navigateTo} isActive={type === "sign-up"}>
        Sign up
      </Text>
    </MobileAuth>
  );
};

export default HeaderAuth;