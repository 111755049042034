import CryptoJS from "crypto-js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import { RootState } from "application/store";
import ApiBondSummary from "integration/api/ApiBondSummary";
import { BondSummaryState } from "./models";

class BondSummaryActions {
  static getDataForFilters = createAsyncThunk(
    `bond-summary/${ResponseHandlerName.GetBondFilters}`,
    async (_, { rejectWithValue }) => {
      try {
        const res =
          await ApiBondSummary.getBondFilters();
        return res.data;
      } catch (error) {
        console.log("getIndicesPerformanceSummaryFilters Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };

  static getBondSummaryData = createAsyncThunk(
    `bond-summary/${ResponseHandlerName.GetBondSummary}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).bondSummary;
      const endingDate = filters.endingDate;

      try {
        const { data } =
          await ApiBondSummary.getBondSummary(
            endingDate
          );
        const decryptedData = BondSummaryActions.decryptData(
          data.data
        ) as {
          indices: BondSummaryState["bondSummaryData"]["data"];
          endDate: string;
        };
        return {
          data: decryptedData.indices,
          endingDate: decryptedData.endDate,
        };
      } catch (error) {
        console.log("getIndicesComparisonData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default BondSummaryActions;
