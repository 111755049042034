import React from "react";
import chartIcon from "application/assets/images/homeIcons/chart-icon.svg";
import { BondIndexStyled, BondIndexText, ChartImage } from "./styles";
import MainContainer from "application/components/MainContainer";

const Bonds = () => {
  return (
    <MainContainer>
      <BondIndexStyled>
        <ChartImage src={chartIcon} />
        <BondIndexText>Coming Soon...</BondIndexText>
      </BondIndexStyled>
    </MainContainer>
  );
};

export default Bonds;
