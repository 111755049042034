import moment from "moment";
import React, { useMemo } from "react";
import {
  DateBlock,
  Container,
  Separator,
  UpdateDate,
  ActionBlock,
  ButtonContainer,
  PickerContainer,
  ArrowBackContainer,
  ArrowBack,
} from "./styles";
import PrimaryButton from "../PrimaryButton";
import YearMonthPicker from "../YearMonthPicker";
import { TableDateActionsProps } from "./models";
import {useMediaQuery, useTheme} from "@mui/material";

const TableDateActions = ({
  compare,
  selectedCount,
  lastUpdatedDate,
  lastDate,
  compareLines,
  handleDateCalculation,
}: TableDateActionsProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const buttonText = useMemo(() => {
    return compare ? "Back to full list" : `Compare${isTablet ? "" : " indexes"}`;
  }, [compare]);

  return (
    <Container>
      <DateBlock>
        <PickerContainer>
          <YearMonthPicker
            label="Select Ending Date"
            minDate={moment("2017-12")}
            maxDate={moment(lastDate)}
            onChange={handleDateCalculation}
            defaultValue={moment(lastUpdatedDate)}
          />
        </PickerContainer>

        <UpdateDate>
          Data as of {moment(lastUpdatedDate).format("MMMM D, YYYY")} <br />
          Valued in U.S. Dollars
        </UpdateDate>
      </DateBlock>

      <Separator />

      <ActionBlock compare={compare}>
        <ArrowBackContainer compare={compare} onClick={compareLines}>
          <ArrowBack />
        </ArrowBackContainer>
        <ButtonContainer compare={compare}>
          <PrimaryButton title={buttonText} onClick={compareLines} disabled={selectedCount < 2} />
        </ButtonContainer>
      </ActionBlock>
    </Container>
  );
};

export default TableDateActions;
