import moment from "moment";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SettingProps } from "./types";
import { PathNames } from "application/routes";
import ValuationStockActions from "common/valuationReports/store/actions";
import AuthActions from "common/auth/store/actions";
import { homeSelector } from "common/valuationReports/store/slice";
import { authSelector, requestSelector } from "common";
import { useAppDispatch, useAppSelector } from "application/store";
import { getCurrencySymbol } from "application/helpers/currencyFormatter";

import UserAvatar from "../UserAvatar";
import SmallLoader from "../SmallLoader";

import {
  Main,
  Filter,
  InfoIcon,
  LockIcon,
  UserInfo,
  PlanLine,
  PlanText,
  PlanTitle,
  UserEmail,
  Container,
  LogOutIcon,
  PlanContent,
  PlanContainer,
  LinkContainer,
  LoaderContainer,
} from "./styles";

const Setting = ({ onClose }: SettingProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    customerPortalLink,
    cognitoData: { family_name, given_name, email },
  } = useAppSelector(authSelector);
  const { subscriptionInfo } = useAppSelector(homeSelector);
  const { currency, price, interval, nextPayment } = subscriptionInfo;
  const { getSubscriptionInfo, getCustomerPortalLink } =
    useAppSelector(requestSelector);
  const loading = getCustomerPortalLink.loading || getSubscriptionInfo.loading;

  const navigateTo = (route: PathNames) => () => navigate(route);

  const signOut = () => {
    dispatch(AuthActions.signOut());
  };

  const handleSubscriptionInfo = () => {
    window.open(customerPortalLink, "_blank");
  };

  const avatarName = given_name.charAt(0) + family_name.charAt(0);

  const subscriptionPlan =
    subscriptionInfo.interval === "month" ? "monthly" : "annual";

  useEffect(() => {
    if (subscriptionInfo.price === null) {
      dispatch(ValuationStockActions.getSubscriptionInfo());
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    if (!customerPortalLink) {
      dispatch(AuthActions.getCustomerPortalLink());
    }
  }, [customerPortalLink]);

  return (
    <>
      <Container>
        {loading && (
          <LoaderContainer>
            <SmallLoader />
          </LoaderContainer>
        )}

        <Main>
          <UserInfo>
            <UserAvatar name={avatarName} />
            <UserEmail>{email}</UserEmail>
          </UserInfo>
          <PlanContainer>
            <PlanTitle>
              Current Subscription: <span>{subscriptionPlan}</span>
            </PlanTitle>
            <PlanLine />
            {!loading && (
              <PlanContent>
                <PlanText>
                  {getCurrencySymbol(currency)}
                  {price} / {interval}
                </PlanText>
                <PlanText>
                  Next payment: {moment(nextPayment).format("MMM DD YYYY")}
                </PlanText>
              </PlanContent>
            )}
          </PlanContainer>
        </Main>
        <LinkContainer onClick={handleSubscriptionInfo}>
          <InfoIcon />
          <p>Subscription info</p>
        </LinkContainer>
        <LinkContainer onClick={navigateTo(PathNames.changePassword)}>
          <LockIcon />
          <p>Change password</p>
        </LinkContainer>
        <LinkContainer onClick={signOut}>
          <LogOutIcon />
          <p>Log out</p>
        </LinkContainer>
      </Container>
      <Filter onClick={onClose} />
    </>
  );
};

export default Setting;
