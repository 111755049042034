import { LargeTitle } from "application/styles/text";
import styled from "styled-components";
import { colors } from "application/styles/colors";
import { ReactComponent as GoToSummaryTableImage } from "application/assets/images/icons/go-to-summary-table.svg";

export const Title = styled(LargeTitle)`
  text-align: left;
  padding: 0 0 24px;

  @media (max-width: 1023px) {
    font-family: "Helvetica";
    font-size: 24px;
    line-height: 28px;
  }
`;

export const FiltersContainer = styled.div`
  display: block;
  justify-content: start;
  gap: 24px;
  margin: 16px 0 24px 0;

  @media (max-width: 1023px) {
    margin: 24px 16px 0 16px;
  }
`;

export const DropContainer = styled.div`
  display: flex;
  & > div {
    margin-right: 36px;
  }
  @media (max-width: 1023px) {
    flex-direction: column;
    margin-top: 24px;

    & > div {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const GoToSummaryTableButton = styled.button`
  display: flex;
  height: 44px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  gap: 8px;
  background: ${colors.ochre};
  border: none;
  cursor: pointer;

  p {
    color: ${colors.white};
    text-align: center;
    font-family: "Helvetica";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  }
`;

export const GoToSummaryTableIcon = styled(GoToSummaryTableImage)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: ${colors.white};
`;
