import styled from "styled-components";
import { colors } from "application/styles/colors";
import { ReactComponent as ArrowIcon } from "application/assets/images/icons/arrow-white.svg";
import { ReactComponent as CloseIconSvg } from "application/assets/images/icons/close-icon-test.svg";

export const Container = styled.div`
  bottom: 24px;
  z-index: 99;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  width: 389px;
  height: auto;
  display: flex;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  background-color: ${colors.green_a_400};

  @media only screen and (max-width: 647px) {
    width: calc(100% - 32px);
    position: absolute;
    height: 64px;
  }
`;

export const Text = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.white};
`;

export const InfoIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const InfoIconAccept = styled(ArrowIcon)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: ${colors.white};
`;

export const CloseIcon = styled(CloseIconSvg)`
  width: 24px;
  height: 24px;
  margin-left: auto;
  stroke: ${colors.white};
`;
