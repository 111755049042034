import CryptoJS from "crypto-js";
import { createAsyncThunk } from "@reduxjs/toolkit";

import ApiUser from "integration/api/ApiUser";
import { RootState } from "application/store";
import { FilterData, ValuationReportState } from "./model";
import ApiIndices from "integration/api/ApiIndices";
import ApiMetrics from "integration/api/ApiMetrics";
import { ResponseHandlerName } from "common/responseHandlerService/model";

class ValuationReportActions {
  static getMetrics = createAsyncThunk(
    `valuation-report/${ResponseHandlerName.GetMetrics}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiMetrics.getMetrics();
        return res.data;
      } catch (error) {
        console.log("getMetrics Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };

  static getIndices = createAsyncThunk(
    `valuation-report/${ResponseHandlerName.GetIndices}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiIndices.getIndices();
        const data = ValuationReportActions.decryptData(res.data.data) as FilterData;
        return data;
      } catch (error) {
        console.log("getIndices Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getIndicesData = createAsyncThunk(
    `valuation-report/${ResponseHandlerName.GetIndicesData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).valuationReport;

      const indices = filters.selectedIndices.code;
      const metric = filters.selectedMetric.code;

      try {
        const { data } = await ApiIndices.getIndicesData(indices, metric);
        const decryptedData = ValuationReportActions.decryptData(
          data.data
        ) as ValuationReportState["indicesData"];
        return decryptedData;
      } catch (error) {
        console.log("getIndicesData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getSubscriptionInfo = createAsyncThunk(
    `valuation-report/${ResponseHandlerName.GetSubscriptionInfo}`,
    async (_, { getState, rejectWithValue }) => {
      const { cognitoData } = (getState() as RootState).auth;
      const userId = cognitoData["custom:mfru_user_id"];
      try {
        const res = await ApiUser.getSubscriptionInfo(userId);
        return res.data;
      } catch (error) {
        console.log("getSubscriptionInfo Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default ValuationReportActions;
