import styled from "styled-components";

import { colors } from "application/styles/colors";

interface StyledProps {
  isActive: boolean;
}

export const Container = styled.div`
  padding: 4px;
  display: inline-flex;
  border-radius: 24px;
  background-color: ${colors.stripe_grey_light};
`;

export const Switch = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83px;
  height: 36px;
  border-radius: 20px;
  background-color: ${({ isActive }) =>
    isActive ? colors.blue_hover : "transparent"};
  cursor: ${({ isActive }) => (isActive ? "not-allowed" : "pointer")};

  p {
    text-transform: capitalize;
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: ${({ isActive }) => (isActive ? colors.white : colors.font_grey)};
  }

  @media only screen and (max-width: 674px) {
    width: 70px;
  }
`;
