import styled from "styled-components";

import { colors } from "application/styles/colors";
import { ReactComponent as MarkSvg } from "application/assets/images/icons/mark.svg";

export const Container = styled.div`
  position: relative;
  margin-bottom: 12px;
  padding: 36px 24px 24px 24px;
  background-color: ${colors.stripe_grey_light};

  @media (max-width: 1023px) {
    margin: 0 16px 24px;
  }
`;

export const MarkIcon = styled(MarkSvg)`
  top: -4px;
  width: 20px;
  height: 31px;
  position: absolute;
`;

export const Title = styled.h5`
  margin-bottom: 16px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.black};

  @media (max-width: 1023px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Description = styled.div`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.font_grey};

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
