import styled from "styled-components";

import { colors } from "./colors";

export const LargeTitle = styled.h2`
  align-self: flex-end;
  /* font style */
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  font-family: "Times";
  color: ${colors.black};
`;

export const Required = styled.span`
  color: ${colors.red};
`;
