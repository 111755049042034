import Api from "../Api";
import { FilterData } from "common/valuationReports/store/model";

class ApiTableData extends Api {
  static getTableData({dividendYield, earningGrowth, valuationAdjustment}: {dividendYield: string; earningGrowth: string; valuationAdjustment: string}) {
    return ApiTableData.instance.axiosInst.post<FilterData>("/stock/expected", {
    dividendYield, earningGrowth, valuationAdjustment
    });
  }
}

export default ApiTableData;
