import styled from "styled-components";

import { colors } from "application/styles/colors";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 36px 36px 0 36px;
    
    div:last-child {
        text-wrap: nowrap;
    }
  
  @media (max-width: 1023px) {
    flex-direction: column;
    padding: 24px 16px 0 16px;
  }
`;

export const IndexName = styled.h5`
  margin-bottom: 16px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.black};

  @media (max-width: 1023px) {
    font-size: 20px;
    margin-bottom: 8px;
  }
`;

export const MetricName = styled.h5`
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.font_grey};

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const LastPointContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0 0 0;
  border-top: 1px solid ${colors.stroke_grey};

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (max-width: 647px) {
    padding: 0;
    border-top: none;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid ${colors.stroke_grey};
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1023px) {
    margin-bottom: 8px;
  }
`;

export const LastPointDate = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.font_grey};

  @media (max-width: 1023px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const LastPointCircle = styled.div`
  width: 5px;
  height: 5px;
  margin: 0 16px;
  border-radius: 50%;
  background-color: ${colors.font_grey};
  
  @media (max-width: 647px) {
    display: none;
  }
`;

export const MobileLastPointCircle = styled.div`
  display: none;
  width: 5px;
  height: 5px;
  margin: 0 16px;
  border-radius: 50%;
  background-color: ${colors.font_grey};
  
  @media (max-width: 647px) {
    display: block;
    margin: 0 8px;
  }
`;

export const LastPointValue = styled.p`
  margin-right: 8px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const LastPointPercent = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.blue_default};

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const LastPointSD = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.font_grey};

  @media (max-width: 1023px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const PeriodContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
  img {
    margin-right: 8px;
  }

  @media (max-width: 1023px) {
    padding-top: 12px;
    margin-top: 12px;
    justify-content: flex-start;
    border-top: 1px solid ${colors.stroke_grey};
  }
`;
