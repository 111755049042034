import React from "react";

import { MSCIPopUpProps } from "./types";

import { Text, Container, Filter, Header, Title } from "./styles";
import closeIconSource from "application/assets/images/icons/close-icon.svg";

const MSCIInfoPopUp = ({ onClose }: MSCIPopUpProps) => {
  return (
    <Filter onClick={onClose}>
      <Container>
        <Header>
          <Title>Calculated from MSCI data</Title>
          <img src={closeIconSource} alt="close" onClick={onClose} />
        </Header>
        <Text>
          This report contains information (the “Information”) sourced from MSCI
          Inc., its affiliates or information providers (the “MSCI Parties”) and
          may have been used to calculate scores, ratings or other indicators.
          <br />
          <br />
          The Information is for internal use only, and may not be
          reproduced/redisseminated in any form, or used as a basis for or a
          component of any financial instruments or products or indices.
          <br />
          <br />
          The MSCI Parties do not warrant or guarantee the originality, accuracy
          and/or completeness of any data or Information herein and expressly
          disclaim all express or implied warranties, including of
          merchantability and fitness for a particular purpose.
          <br />
          <br />
          The Information is not intended to constitute investment advice or a
          recommendation to make (or refrain from making) any investment
          decision and may not be relied on as such, nor should it be taken as
          an indication or guarantee of any future performance, analysis,
          forecast or prediction.
          <br />
          <br />
          None of the MSCI Parties shall have any liability for any errors or
          omissions in connection with any data or Information herein, or any
          liability for any direct, indirect, special, punitive, consequential
          or any other damages (including lost profits) even if notified of the
          possibility of such damages.
        </Text>
      </Container>
    </Filter>
  );
};

export default MSCIInfoPopUp;
