import React, { useState } from "react";

import { DropGroupProps } from "./types";
import { FilterDataItem } from "common/valuationReports/store/model";

import {
  List,
  Label,
  Filter,
  Disabled,
  Selected,
  ListItem,
  Container,
  ListGroup,
  ListLabel,
  OneList,
} from "./styles";

import arrowSource from "application/assets/images/icons/arrow-down.svg";
import acceptSource from "application/assets/images/icons/green-accept.svg";
import OptionalTooltip from "../OptionalTooltip";

const DropGroup = ({
  data,
  label,
  disabled,
  selected,
  placeholder,
  onSelect,
  tooltip
}: DropGroupProps) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => setVisible(!visible);

  const handleSelect = (item: FilterDataItem) => {
    setVisible(false);
    onSelect(item);
  };

  const LABELS = Object.keys(data);
  const showLabel = LABELS.length > 1;

  const renderList = () =>
    LABELS.map((key) =>
      showLabel ? (
        <ListGroup key={key}>
          <ListLabel>{key}</ListLabel>
          {data[key].map((item) => {
            const isSelected = selected.name === item.name;
            return (
              <ListItem
                key={item.name}
                isSelected={isSelected}
                onClick={() => handleSelect(item)}
              >
                <p>{item.name}</p>
                {isSelected && <img src={acceptSource} alt="accept" />}
              </ListItem>
            );
          })}
        </ListGroup>
      ) : (
        <OneList key={key}>
          {data[key].map((item) => {
          const isSelected = selected.name === item.name;
          return (
            <ListItem
              key={item.name}
              isSelected={isSelected}
              onClick={() => handleSelect(item)}
            >
              <p>{item.name}</p>
              {isSelected && <img src={acceptSource} alt="accept" />}
            </ListItem>
          );
        })}
        </OneList>
      )
    );

  return (
    <div className="drop-group-wrapper">
      <OptionalTooltip text={tooltip || ''}><Label className="drop-group-label">{label}</Label></OptionalTooltip>
      <Container visible={visible} className="drop-group-container">
        {disabled && <Disabled />}
        <Selected onClick={toggleVisible} className="drop-group-selected">
          <p>{selected.name ? selected.name : placeholder}</p>
          <img src={arrowSource} alt="arrow" />
        </Selected>
        {visible && <List>{renderList()}</List>}
      </Container>
      {visible && <Filter onClick={toggleVisible} />}
    </div>
  );
};

export default DropGroup;
