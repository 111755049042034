export const removeScripts = (environment: string) => {
  if (environment !== "production") {
    const script = document.getElementById("fp-script");
    const scriptFunction = document.getElementById("fp-function");
    const matomoScript = document.getElementById("matomo-script");
    if (script) script.remove();
    if (scriptFunction) scriptFunction.remove();
    if (matomoScript) matomoScript.remove();
  }
};
