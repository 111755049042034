import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  ButtonContainer,
  CalculateButton,
  Container,
  DateRangeStyled,
  DropGroupStyled,
  FiltersContainer,
  DatePickerStyled,
} from "./styles";

import DatePicker from "../DatePicker";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  FilterData,
  FilterDataItem,
} from "common/indicesComparison/store/models";
import {
  indicesComparisonSelector,
  setEndingDate,
  setFilterIndices,
  setFilterMetric,
  setStartingDate,
} from "common/indicesComparison/store/slice";
import IndicesComparisonActions from "common/indicesComparison/store/actions";
import DropGroup from "../DropGroup";
import { requestSelector } from "common/responseHandlerService/slice";
import MultipleDropGroup from "../MultipleDropGroup";

const yearMonthToMoment = ({
  year,
  month,
}: {
  year: number;
  month: number;
}) => {
  return moment()
    .year(year)
    .month(month - 1);
};

const IndicesComparisonFilters = () => {
  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const {
    metrics,
    indices,
    indicesComparisonData,
    filters,
    dateLimits,
    comparisonDataSearched,
  } = useAppSelector(indicesComparisonSelector);

  const loading =
    requestData.getIndices.loading ||
    requestData.getIndicesComparisonData.loading ||
    requestData.getMetrics.loading ||
    requestData.getInceptionsComparisonData.loading;

  const dataRangeError =
    comparisonDataSearched && indicesComparisonData.data.length === 0;

  const handleIndicesChange = (item: FilterDataItem) => {
    dispatch(setFilterIndices(item));
  };

  const handleMetricSelect = (item: FilterDataItem) => {
    dispatch(setFilterMetric(item));
  };

  const handleSetStartingDate = (date: Moment) => {
    const formatedDate = date.format("yyyy-MM");
    dispatch(setStartingDate(formatedDate));
  };

  const handleSetEndingDate = (date: Moment) => {
    const formatedDate = date.format("yyyy-MM");
    dispatch(setEndingDate(formatedDate));
  };

  useEffect(() => {
    if (!indicesComparisonData.data.length) {
      Promise.all([
        dispatch(IndicesComparisonActions.getMetrics()),
        dispatch(IndicesComparisonActions.getIndices()),
        dispatch(IndicesComparisonActions.getInceptions()),
      ]).then((resArr) => {
        const metricRes = resArr[0].payload as FilterData;
        const metricKeys = Object.keys(metricRes);
        const firstMetric = metricRes[metricKeys[0]][0];
        const inceptions = resArr[2].payload;
        handleMetricSelect(firstMetric);
        handleSetStartingDate(
          yearMonthToMoment(inceptions.endingDate).subtract(1, "month")
        );
        handleSetEndingDate(yearMonthToMoment(inceptions.endingDate));
      });
    }
  }, []);

  const handleSubmitCalculation = useCallback(() => {
    if (filters.selectedIndices.length < 2) {
      return;
    } else if (
      moment(filters.startingDate).isAfter(moment(filters.endingDate)) ||
      moment(filters.endingDate).isBefore(moment(filters.startingDate))
    ) {
      return;
    } else if (filters.selectedMetric.code === "") {
      return;
    }
    dispatch(IndicesComparisonActions.getIndicesComparisonData());
  }, [filters]);

  const isSubmitCalculationDisabled = useMemo(() => {
    if (filters.selectedIndices.length < 2) {
      return true;
    } else if (
      moment(filters.startingDate).isAfter(moment(filters.endingDate)) ||
      moment(filters.endingDate).isBefore(moment(filters.startingDate))
    ) {
      return true;
    } else if (filters.selectedMetric.code === "") {
      return true;
    }
    return false;
  }, [filters]);

  return (
    <Container>
      <FiltersContainer>
        <MultipleDropGroup
          data={indices}
          label="Index to compare"
          disabled={loading}
          onSelect={handleIndicesChange}
          selected={filters.selectedIndices}
          maxSelected={5}
        />
        <DropGroupStyled>
          <DropGroup
            data={metrics}
            label="Metric to compare"
            disabled={loading}
            onSelect={handleMetricSelect}
            selected={filters.selectedMetric}
            placeholder="Metric name goes here"
          />
        </DropGroupStyled>
        <DatePickerStyled>
          {dateLimits.startingDate.year > 0 && (
            <DatePicker
              label="Starting Date"
              minDate={yearMonthToMoment({
                year: dateLimits.startingDate.year,
                month: dateLimits.startingDate.month,
              })}
              maxDate={yearMonthToMoment({
                year: dateLimits.endingDate.year,
                month: dateLimits.endingDate.month,
              })}
              onChange={handleSetStartingDate}
              value={moment(filters.startingDate)}
            />
          )}
        </DatePickerStyled>
        <DatePickerStyled>
          {dateLimits.endingDate.year > 0 && (
            <DatePicker
              label="Ending Date"
              minDate={
                filters.startingDate.length > 0
                  ? moment(filters.startingDate)
                  : moment()
              }
              maxDate={yearMonthToMoment({
                year: dateLimits.endingDate.year,
                month: dateLimits.endingDate.month,
              })}
              onChange={handleSetEndingDate}
              value={moment(filters.endingDate)}
            />
          )}
        </DatePickerStyled>
      </FiltersContainer>
      <ButtonContainer>
        <DateRangeStyled isError={dataRangeError}>
          {indicesComparisonData.data.length > 0
            ? `Return from ${moment(indicesComparisonData.fromDate).format(
                "MMMM D, YYYY"
              )} to ${moment(indicesComparisonData.toDate).format(
                "MMMM D, YYYY"
              )}`
            : comparisonDataSearched
            ? "Date out of range"
            : ""}
        </DateRangeStyled>
        <CalculateButton
          onClick={handleSubmitCalculation}
          disabled={isSubmitCalculationDisabled}
          isDisabled={isSubmitCalculationDisabled}
        >
          Calculate
        </CalculateButton>
      </ButtonContainer>
    </Container>
  );
};

export default IndicesComparisonFilters;
