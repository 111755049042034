import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import { ComparisonMetric, IndicesPerformanceSummaryState } from "./models";
import IndicesPerformanceSummaryActions from "./actions";
import AuthActions from "../../auth/store/actions";

const initialState: IndicesPerformanceSummaryState = {
  metrics: [],
  indicesSummaryData: {
    data: [],
    metric: { code: "", name: "" },
    endingDate: "",
  },
  filters: {
    selectedMetric: { code: "", name: "" },
    endingDate: "",
  },
  maxEndingDate: "",
};

export const indicesPerformanceSummarySlice = createSlice({
  name: "indices-summary",
  initialState,
  reducers: {
    setFilterMetric: (state, action: PayloadAction<ComparisonMetric>) => {
      state.filters.selectedMetric = action.payload;
    },
    setEndingDate: (state, action: PayloadAction<string>) => {
      state.filters.endingDate = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // getDataForFilters
    builder.addCase(
      IndicesPerformanceSummaryActions.getDataForFilters.fulfilled,
      (state, action) => {
        state.metrics = action.payload.metrics;
        state.maxEndingDate = action.payload.endDate;
      }
    );

    // getIndicesPerformanceSummaryData
    builder.addCase(
      IndicesPerformanceSummaryActions.getIndicesPerformanceSummaryData
        .fulfilled,
      (state, action) => {
        state.indicesSummaryData = action.payload;
      }
    );

    // signOut
    builder.addCase(AuthActions.signOut.pending, () => initialState);
  },
});

export default indicesPerformanceSummarySlice.reducer;
export const indicesPerformanceSummarySelector = () =>
  store.getState().indicesPerformanceSummary;
export const { setFilterMetric, setEndingDate, resetState } =
  indicesPerformanceSummarySlice.actions;
