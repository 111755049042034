import styled from "styled-components";
import { TableContainer, Table, TableHead } from "@mui/material";
import { colors } from "application/styles/colors";

interface TableRowStyledProps {
  isEvenRow: boolean;
  isWrong?: boolean;
}

const mediaStep_1 = 1023;
const mediaStep_2 = 760;

export const IndicesComparisonTableContainer = styled(TableContainer)`
  margin-top: 24px;
`;

export const IndicesComparisonTableStyled = styled(Table)`
  border: 1px solid var(--Grey-300, #e0e0e0);
  z-index: 0;
`;

export const TableHeadStyled = styled(TableHead)`
  background: ${colors.grey_100};
  th {
    width: 50%;
    color: ${colors.black};
    font-family: "Helvetica";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;

    @media only screen and (max-width: ${mediaStep_1}px) {
      font-size: 16px;
    }

    @media only screen and (max-width: ${mediaStep_2}px) {
      font-size: 12px;
    }
  }
`;

export const TableRowStyled = styled.tr<TableRowStyledProps>`
  background: ${({ isEvenRow, isWrong }) =>
    isWrong ? colors.noDataRow : isEvenRow ? colors.white : colors.grey_50};
  td {
    width: 50%;
    color: ${colors.black};
    font-family: "Helvetica";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media only screen and (max-width: ${mediaStep_1}px) {
      font-size: 16px;
    }

    @media only screen and (max-width: ${mediaStep_2}px) {
      font-size: 12px;
    }
  }
`;
