import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { store } from "application/store";
import { NotifyState } from "./model";

const initialState: NotifyState = {
  data: {
    text: "",
    type: "success",
    visible: false,
  },
};

export const notifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    setNotify: (state, action: PayloadAction<NotifyState["data"]>) => {
      state.data = action.payload;
    },
  },
});

export const { setNotify } = notifySlice.actions;
export default notifySlice.reducer;
export const notifySelector = () => store.getState().notify;
