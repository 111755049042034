import React from "react";
import { Container, Filter, Header, PasswordTip } from "./styles";
import closeIconSource from "../../assets/images/icons/close-icon.svg";

interface Props {
  onClose: () => void;
}

const PopUp = ({ onClose }: Props) => {
  return (
    <Filter onClick={onClose}>
      <Container>
        <Header>
          <img src={closeIconSource} alt="close" onClick={() => onClose()} />
        </Header>
        <PasswordTip>
          <li>
            <span>May contain letters, numbers, symbols</span>
          </li>
          <li>
            <span>At least 10 characters long</span>
          </li>
          <li>
            <span>Contain upper and lowercase letters</span>
          </li>
        </PasswordTip>
      </Container>
    </Filter>
  );
};

export default PopUp;