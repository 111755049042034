import styled from "styled-components/macro";

import { colors } from "application/styles/colors";

interface StyledProps {
  length?: number;
  isActive?: boolean;
}

export const ProcessCircle = styled.div<StyledProps>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? colors.ochre : colors.white};
`;

export const ProcessLine = styled.div<StyledProps>`
  width: 1px;
  background-color: ${colors.blue_default};
  height: ${({ length }) => `calc(100% / ${length})`};

  @media only screen and (max-width: 1023px) {
    height: 1px;
    width: ${({ length }) => `calc(100% / ${length})`};
  }
`;
