import styled from "styled-components";

import { colors } from "application/styles/colors";

export const Container = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${colors.oxford_blue};
  display: flex;
  align-items: center;
  justify-content: center;
  h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    color: ${colors.white};
    font-family: "Helvetica";
  }
`;
