import React from "react";

export interface Data {
  indexPairName: string;
  currentValue: number;
  previousMonthValue: number;
  priorYearLastUpdateValue: number;
  longTermAverage: number;
  standardDeviation: number;
  longTermAverageStartingYear: number;
  action: React.ReactNode;
}

export interface NoData extends Data {
  noData?: boolean;
  type?:
    | "U.S. 20+ Year Treasuries"
    | "U.S. 10-20 Year Treasuries"
    | "U.S. 7-10 Year Treasuries"
    | "U.S. 5-10 Year Treasuries"
    | "U.S. 5-7 Year Treasuries";
  indexPairType?:
    | "U.S. 20+ Year Treasuries"
    | "U.S. 10-20 Year Treasuries"
    | "U.S. 7-10 Year Treasuries"
    | "U.S. 5-10 Year Treasuries"
    | "U.S. 5-7 Year Treasuries";
}

export interface UnitsOfMeasurement {
  annualizedNominalReturn: string;
  expectedNominalAnnualizedReturn: string;
  currencyExchangeImpact: string;
  unexplained: string;
  nominalEarningGrowth: string;
  valuationAdjustment: string;
  beginningPriceToEarningsRatio: string;
  endingPriceToEarningsRatio: string;
  dividendYield: string;
  decadeAvgDividendYield: string;
  action: string;
}

export interface List {
  historicalStock: Data[];
  showButton: any;
  compareLines: boolean;
  headCells: HeadCell[];
  onSelectedCompare?: any;
  selectedCompare?: Data[] | NoData[];
  buttonSection: number;
  valuationSummary?: boolean;
}

export interface HeadCell {
  id: keyof Data;
  label: string;
  secondLabel: string;
  diffBackground: boolean;
  tooltip?: string;
}

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy: string;
  rowCount: number;
}

export interface DesktopProps {
  headCells: HeadCell[];
  compareLines: boolean;
  selectedCompare?: Data[] | NoData[];
  selected: Data[];
  order: number;
  orderBy: keyof Data;
  rows: NoData[];
  dataUnitsOfMeasurement: UnitsOfMeasurement;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  handleClick: (event: React.MouseEvent<unknown>, row: Data) => void;
  buttonSection: number;
}

export enum IndexTypes {
  fiveSevenYears = "U.S. 5-7 Year Treasuries",
  fiveYears = "U.S. 5-10 Year Treasuries",
  sevenYears = "U.S. 7-10 Year Treasuries",
  tenYears = "U.S. 10-20 Year Treasuries",
  twentyYears = "U.S. 20+ Year Treasuries",
}
