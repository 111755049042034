import styled from "styled-components";
import { colors } from "../../styles/colors";

export const ReportLastUpdateContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ReportLastUpdate = styled.div`
  background: ${colors.lastUpdate};
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 12px 16px 12px 16px;
  width: fit-content;

  svg {
    path {
      fill: ${colors.blue_default};
    }
  }

  @media only screen and (max-width: 1023px) {
    margin-right: 16px;
  }
`;

export const Text = styled.p`
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.oxford_blue};

  @media only screen and (max-width: 1023px) {
    font-size: 14px;
  }

  &:last-child {
    font-weight: 700;
  }
`;
