import { ProcessStep } from "./types";

import planIcon from "application/assets/images/icons/plan-icon.svg";
import profileIcon from "application/assets/images/icons/profile-icon.svg";
import locationIcon from "application/assets/images/icons/location-icon.svg";
import cardCreditIcon from "application/assets/images/icons/card-credit-icon.svg";
import documentsFileIcon from "application/assets/images/icons/documents-file-checkmark.svg";

export const SIGN_UP_PROCESS_STEPS: ProcessStep[] = [
  { id: "1", title: "Account Info", icon: profileIcon, step: 1 },
  { id: "2", title: "Add Billing Address", icon: locationIcon, step: 2 },
  { id: "3", title: "Free Trial and Subscription Details", icon: planIcon, step: 3 },
  { id: "4", title: "Terms and Conditions", icon: documentsFileIcon, step: 4 },
  { id: "5", title: "Trial and Account Confirmation", icon: cardCreditIcon, step: 5 },
];

export const UPDATE_SUBSCRIPTION_PROCESS_STEPS: ProcessStep[] = [
  { id: "1", title: "Choose Subscription Plan", icon: planIcon, step: 1 },
  { id: "2", title: "Checkout and Payment", icon: cardCreditIcon, step: 2 },
];
