import * as yup from "yup";

import { CommonErrors } from "common/errorService";
import { emailValidation } from "application/helpers/validations";

export const SignInSchema = yup
  .object({
    email: emailValidation,
    password: yup.string().required(CommonErrors.requiredField),
  })
  .required();
