import * as yup from "yup";

import { Errors } from "../constants";
import { CommonErrors } from "../../errorService";
import { emailValidation } from "application/helpers/validations";
import { passwordValidation } from "application/helpers/validations";

export const ForgotSchema = yup
  .object({
    email: emailValidation,
  })
  .required();

export const ResetSchema = yup
  .object({
    code: yup.string().required(CommonErrors.requiredField),
    password: passwordValidation,
    password_repeat: yup
      .string()
      .required(CommonErrors.requiredField)
      .oneOf([yup.ref("password")], Errors.passwordConfirmation),
  })
  .required();
