import * as yup from "yup";

import { Errors } from "common/auth/constants";
import { CommonErrors } from "common/errorService";

export const emailValidation = yup
  .string()
  .required(CommonErrors.requiredField)
  .email(CommonErrors.emailFormat);

export const passwordValidation = yup
  .string()
  .required(CommonErrors.requiredField)
  .min(10, Errors.passwordMin)
  .matches(/^(?=.*[a-z])/, Errors.passwordLetterUppercaseAndLowercase)
  .matches(/^(?=.*[A-Z])/, Errors.passwordLetterUppercaseAndLowercase)
  .matches(/^\S*$/, Errors.passwordWhiteSpace);
