import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { PaymentProps } from "./types";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  authSelector,
  setAuthenticated,
  setOnLoad,
} from "common/auth/store/slice";
import { applicationConfig } from "application/config/applicationConfig";
import ApiUser from "integration/api/ApiUser";
import Form from "./Form";
import SideBar from "features/side-bar";
import FullScreenLoader from "../../../application/components/FullScreenLoader";

import {
  PaymentContent,
  PaymentPlanName,
  PaymentContainer,
  PaymentPlanTitle,
  PaymentPlanHeader,
  PaymentPlanPrice,
  PaymentPlanContainer,
  PaymentPlanDescription,
  PaymentTotal,
} from "./styles";
import { colors } from "application/styles/colors";
import {
  BoldText,
  SignUpSubTitle,
  SignUpTitle,
} from "common/auth/signUp/styles";
import { AuthWrapper, AuthContainer, AuthActionText } from "common/auth/styles";
import { PathNames } from "../../../application/routes";
import AuthActions from "../store/actions";
import { UserStatusEnum } from "../../../integration/api/ApiUser/types";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");

const Payment = ({ type }: PaymentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    signUpUserInfo: {
      registrationStep: { email, password },
    },
    stripe: { clientSecret },
    signUp: { userId, priceId },
    products,
    subscription,
  } = useAppSelector(authSelector);

  if (subscription.noPaymentNeeded) {
    const checkUserStatus = async () => {
      try {
        const {
          data: { userStatus },
        } = await ApiUser.getUserInfo(userId);
        if (userStatus === UserStatusEnum.ACTIVE) {
          if (email.length === 0) {
            dispatch(setAuthenticated(true));
            navigate(PathNames.home);
            return;
          } else {
            await handleSuccess();
          }
        }
      } catch (error) {
        console.log("checkUserStatus Error:", error);
      }
    };

    checkUserStatus();
  }

  const handleSuccess = async () => {
    dispatch(setOnLoad);
    await dispatch(AuthActions.singIn({ email, password })).then((res) => {
      if (res.type.endsWith("/fulfilled")) {
        (window as any).fpr("refferal", {
          email: email,
        });
        navigate(PathNames.home);
      }
    });
  };

  const handlePrivacy = () => {
    window.open(applicationConfig.privacy_policy, "_blank");
  };

  const activeProduct = useMemo(() => {
    return products.find((p) => p.priceId === priceId);
  }, [priceId, products]);

  useEffect(() => {
    const subscriptionInfo = window.sessionStorage.getItem("subscription");

    if (!subscriptionInfo) {
      navigate(PathNames.registration);
    }

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 300);
  }, []);

  const options = {
    clientSecret,
    appearance: {
      variables: {
        borderRadius: "0px",
        fontFamily: "Helvetica",
        colorDanger: colors.red,
        colorText: colors.font_grey,
        colorBackground: colors.white,
        colorPrimary: colors.blue_hover,
      },
    },
  };

  const discountText = useMemo(() => {
    if (subscription.discount?.amount) {
      if (subscription.discount?.uom.toLowerCase() === "usd") {
        return `$${subscription.discount?.amount}`;
      } else {
        return `${subscription.discount?.amount}%`;
      }
    }

    return "0";
  }, [subscription]);

  return subscription.noPaymentNeeded ? (
    <FullScreenLoader />
  ) : (
    <Elements stripe={stripePromise} options={options}>
      <AuthWrapper>
        {type === "sign-up" ? (
          <SideBar type="sign-up" activeStep={5} />
        ) : (
          <SideBar type="update-subscription" activeStep={2} recovery />
        )}
        <AuthContainer isSignUp>
          <PaymentContent>
            <SignUpTitle>Trial and Account Confirmation</SignUpTitle>
            <SignUpSubTitle>
              <BoldText>The first 7 days are free for new users.&nbsp;</BoldText>
              You can cancel within the trial or continue with an annual plan.{" "}
            </SignUpSubTitle>
            <SignUpSubTitle>
              Card information helps us limit trials to one per person.
            </SignUpSubTitle>
            <SignUpSubTitle>
              Returning users are not eligible for free trials and will be
              charged at checkout.
            </SignUpSubTitle>
            <SignUpSubTitle>
              Payments are securely handled by Stripe. Asset Camp does not store
              your payment data.{" "}
              <AuthActionText onClick={handlePrivacy}>
                {" "}
                Learn more.
              </AuthActionText>
            </SignUpSubTitle>

            <PaymentContainer>
              <PaymentPlanContainer>
                <PaymentPlanTitle>Selected plan</PaymentPlanTitle>
                {activeProduct?.period === "year" ? (
                  <PaymentPlanHeader>
                    <PaymentPlanName>Annual</PaymentPlanName>
                    <PaymentPlanPrice>
                      ${activeProduct?.price} / year
                    </PaymentPlanPrice>
                  </PaymentPlanHeader>
                ) : (
                  <PaymentPlanHeader>
                    <PaymentPlanName>Monthly</PaymentPlanName>
                    <PaymentPlanPrice>
                      ${activeProduct?.price} / month
                    </PaymentPlanPrice>
                  </PaymentPlanHeader>
                )}
                {subscription.discount?.amount && (
                  <>
                    <PaymentPlanHeader>
                      <PaymentPlanName>Discount</PaymentPlanName>
                      <PaymentPlanPrice>{discountText}</PaymentPlanPrice>
                    </PaymentPlanHeader>
                  </>
                )}
                {subscription.taxes?.map((item) => {
                  return (
                    <PaymentPlanHeader key={item.amount}>
                      <PaymentPlanName>Taxes</PaymentPlanName>
                      <PaymentPlanPrice>
                        ${item.amount} {item.inclusive ? "Included" : ""}
                      </PaymentPlanPrice>
                    </PaymentPlanHeader>
                  );
                })}
                {subscription.total !== subscription.oldPrice && (
                  <PaymentTotal>
                    <p>Total</p>
                    <p>
                      $
                      {subscription.total
                        ? subscription.total
                        : activeProduct?.price}
                    </p>
                  </PaymentTotal>
                )}
                {activeProduct?.period === "year" && (
                  <PaymentPlanDescription>
                    {type === "sign-up" && (
                      <li>
                        New users’ first 7 days are free. You may cancel any
                        time during this period and you will not be charged.
                      </li>
                    )}
                    {type === "sign-up" && (
                      <li>
                        A new account’s subscription payment will be 7 days
                        after sign-up. Reminders will be sent.
                      </li>
                    )}
                    {type === "sign-up" && (
                      <li>
                        Returning users are not eligible for free trials and
                        will be charged at checkout.
                      </li>
                    )}
                    <li>
                      Your subscription will be renewed annually, with an email
                      notice sent 45 days before renewal.
                    </li>
                  </PaymentPlanDescription>
                )}
              </PaymentPlanContainer>
              <Form userId={userId} type={type} />
            </PaymentContainer>
          </PaymentContent>
        </AuthContainer>
      </AuthWrapper>
    </Elements>
  );
};

export default Payment;
