export type ResponseHandlerState = {
  [name in ResponseHandlerName]: ResponseHandlerData;
};

export interface ResponseHandlerData {
  loading: boolean;
  error: string;
}

export enum ResponseHandlerName {
  // Auth
  OnLoad = "onLoad",
  SignOut = "signOut",
  SignIn = "signIn",
  CreateUser = "createUser",
  GetCountries = "getCountries",
  UpdateUserBillingAddress = "updateUserBillingAddress",
  ResetPassword = "resetPassword",
  ChangePassword = "changePassword",
  ForgotPassword = "forgotPassword",
  GetProducts = "getProducts",
  CreateSubscription = "createSubscription",
  GetCustomerPortalLink = "getCustomerPortalLink",
  // Home
  GetMetrics = "getMetrics",
  GetIndices = "getIndices",
  GetIndicesData = "getIndicesData",
  GetSubscriptionInfo = "getSubscriptionInfo",
  //Expect Stock
  GetExpectedStockTableData = "getExpectedStockTableData",

  //Bond Valuation
  GetExpectedBondTableData = "getExpectedBondTableData",
  GetBondIndicesData = "getBondIndicesData",

  //historicalStock
  GetHistoricalStock = "getHistoricalStock",
  GetHistoricalStockDateCalculation = "getHistoricalStockDateCalculation",
  GetHistoricalStockLastUpdatedDate = "getHistoricalStockLastUpdatedDate",

  //Index comparison
  GetIndicesComparisonMetrics = "getIndicesComparisonMetrics",
  GetIndicesComparisonData = "getIndicesComparisonData",
  GetInceptionsComparisonData = "getInceptionsComparisonData",

  //valuationSummary
  GetMetricsValuation = "getMetricsValuation",
  GetBondMetricsValuation = "getBondMetricsValuation",

  //Indices performance summary
  GetIndicesPerformanceSummary = "getIndicesPerformanceSummary",
  GetIndicesPerformanceSummaryFilters = "getIndicesPerformanceSummaryFilters",
  //Bond summary
  GetBondSummary = "getBondSummary",
  GetBondFilters = "getBondSummaryFilters",

  //Yield curves
  GetYieldCurveTableData = "getYieldCurveTableData",
  GetYieldCurveFilters = "getYieldCurveFilters",
  GetYieldCurveChart = "getYieldCurveChart",

  // Yield summary
  GetStockBondYieldMetrics = "getStockBondYieldMetrics",
  GetStockBondYieldTableData = "getStockBondYieldTableData",
  GetStockBondYieldCharts = "getStockBondYieldCharts",

  // Expected Returns Modeling
  GetExpectedReturnsModelingFilters = "getExpectedReturnsModelingFilters",
  GetExpectedReturnsModelingTableData = "getExpectedReturnsModelingTableData",
}
