import React, {useEffect, useState} from "react";
import { CloseIcon, Container, Text } from "./styles";

const NotifyPopUpTable = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, 3000)
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  }

  return (
    isVisible ? (
      <Container>
        <Text>Please open table on the desktop to see more data on the screen</Text>
        <CloseIcon onClick={handleClose} />
      </Container>
    ) : <></>
  )
}

export default NotifyPopUpTable;