import { HeadCell } from "../../components/StickyHeaderTable/types";

export const headCells: HeadCell[] = [
  {
    id: "indexName",
    label: "Index",
    diffBackground: false,
  },
  {
    id: "decadeAvgDividendYield",
    label: "Dividend Yield",
    diffBackground: false,
    tooltip:
      "The expected 10-year annualized contribution to performance by dividends paid to common stock shareholders.",
  },
  {
    id: "nominalEarningGrowth",
    label: "Nominal Earnings Growth",
    diffBackground: false,
    tooltip:
      "The expected 10-year annualized contribution of nominal earnings per share growth, assuming constant exchange rates and without inflation adjustment.",
  },
  {
    id: "valuationAdjustment",
    label: "Valuation Adjustment",
    diffBackground: false,
    tooltip:
      "The expected 10-year annualized contribution of changing valuations as reflected in +/- shifts of the index’s price-to-earnings ratio, which measures how much investors are paying for a dollar’s worth of earnings.",
  },
  {
    id: "annualizedNominalReturn",
    label: "10-year Expected Annualized Nominal Return",
    diffBackground: true,
    tooltip:
      "The expected 10-year total return, also known as the compound annual growth rate, for the index. Based on the sum of the dividend yield, earnings growth, and valuation adjustment.",
  },
  {
    id: "beginningPriceToEarningsRatio",
    label: "Beginning P/E Ratio",
    diffBackground: false,
    tooltip:
      "The index’s current price-to-earnings ratio, used in calculating the expected valuation adjustment.",
  },
  {
    id: "endingPriceToEarningsRatio",
    label: "Ending P/E Ratio",
    diffBackground: false,
    tooltip:
      "The index’s expected price-to-earnings ratio ten years from now, used in calculating the expected valuation adjustment.",
  },
];
