import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  ButtonsContainer,
  ChartContainer,
  CloseButton,
  ModalContent,
  ModalOverlay,
  SeeFullChartButton,
} from "./styles";
import { ChartModalProps, FilterDataItem } from "./types";
import { useMediaQuery, useTheme } from "@mui/material";
import Chart from "features/chart";
import { useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";
import {
  homeSelector,
  resetState as resetBondIndiciesData,
  setFilterIndices,
  setFilterMetric,
} from "common/fixedIncomeCharts/store/slice";
import BondValuationActions from "common/fixedIncomeCharts/store/actions";

const METRICS_WITH_TWO_DECIMAL_Y_LABEL = [
  "average_maturity",
  "modified_duration",
  "duration_to_worst",
];

const BondChartModal = ({
  selectedMetric,
  selectedIndex,
  doNotShowDecimals = false,
  onClose,
}: ChartModalProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const requestData = useAppSelector(requestSelector);
  const loading =
    requestData.getIndices.loading ||
    requestData.getIndicesData.loading ||
    requestData.getMetrics.loading;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const { indicesData, filters } = useAppSelector(homeSelector);

  const handleIndicesSelect = (item: FilterDataItem) => {
    dispatch(setFilterIndices(item));
  };

  const handleMetricSelect = (item: FilterDataItem) => {
    dispatch(setFilterMetric(item));
  };

  useEffect(() => {
    handleIndicesSelect(selectedIndex);
    handleMetricSelect(selectedMetric);
  }, []);

  useEffect(() => {
    if (filters.selectedIndices.code && filters.selectedMetric.code) {
      dispatch(BondValuationActions.getIndicesData());
    }
  }, [filters]);

  const onCloseHandler = () => {
    dispatch(resetBondIndiciesData());
    onClose();
  };

  const seeFullChartHandler = () => {
    Promise.all([
      dispatch(BondValuationActions.getMetrics()),
      dispatch(BondValuationActions.getIndices()),
    ]).then(() => navigate(PathNames.fixedIncomeCharts));
  };

  const modalRootElement = document.getElementById("modal");

  return createPortal(
    <ModalOverlay onClick={onCloseHandler}>
      <ModalContent onClick={(event) => event.stopPropagation()}>
        <ChartContainer>
          <Chart
            loading={loading}
            indicesData={indicesData}
            filters={filters}
            isTablet={isTablet}
            adjustedChartHeight={270}
            withNumberFormater
            paddingLeft={60}
            averageWidth="82"
            isBloomberg
            doNotShowDecimals={doNotShowDecimals}
            numberOfDecimalsYLabels={
              METRICS_WITH_TWO_DECIMAL_Y_LABEL.includes(selectedMetric.code)
                ? 2
                : undefined
            }
          />
        </ChartContainer>
        <ButtonsContainer>
          <CloseButton onClick={onCloseHandler}>
            <p>Close</p>
          </CloseButton>
          <SeeFullChartButton onClick={seeFullChartHandler}>
            <p>See full chart</p>
          </SeeFullChartButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalOverlay>,
    modalRootElement!
  );
};

export default BondChartModal;
