import axios from "axios";

import {
  CreateUserParams,
  CreateUserResponse,
  CreateSubscriptionParams,
  CreateSubscriptionResponse,
  UpdateUserBillingAddressParams,
} from "./types";
import { Product } from "common/auth/store/model";
import { applicationConfig } from "application/config/applicationConfig";

class ApiAuth {
  private static _instance: ApiAuth;

  axiosInst = axios.create({ baseURL: applicationConfig.API_URL });

  static get instance(): ApiAuth {
    if (ApiAuth._instance == null) {
      ApiAuth._instance = new ApiAuth();
    }
    return ApiAuth._instance;
  }

  static createUser(data: CreateUserParams) {
    return ApiAuth.instance.axiosInst.post<CreateUserResponse>("/users", data);
  }

  static updateUserBillingAddress(
    userId: string,
    data: UpdateUserBillingAddressParams
  ) {
    return ApiAuth.instance.axiosInst.post(
      `/users/${userId}/billing-address`,
      data
    );
  }

  static getCountries() {
    return ApiAuth.instance.axiosInst.get<{ [key: string]: string }>(
      "/users/countries"
    );
  }

  static getProducts() {
    return ApiAuth.instance.axiosInst.get<Product[]>("/subscriptions/products");
  }

  static createSubscription(data: CreateSubscriptionParams) {
    return ApiAuth.instance.axiosInst.post<CreateSubscriptionResponse>(
      "/subscriptions",
      data
    );
  }
}

export default ApiAuth;
