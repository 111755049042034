import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthActions from "../store/actions";
import { ChangePasswordDataT } from "./types";
import { PathNames } from "application/routes";
import { ChangePasswordSchema } from "./validations";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  requestSelector,
  resetErrors,
} from "common/responseHandlerService/slice";

import SideBar from "features/side-bar";
import PrimaryInput from "application/components/PrimaryInput";
import PrimaryButton from "application/components/PrimaryButton";

import {
  AuthTitle,
  AuthWrapper,
  AuthContent,
  AuthContainer,
  AuthInputWrapper,
  AuthBottomWrapper,
} from "../styles";
import {useMediaQuery, useTheme} from "@mui/material";
import MobileFooter from "../../../application/components/MobileFooter";
import MobileCopyrightBlock from "../../../application/components/MobileCopyright";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    changePassword: { loading, error },
  } = useAppSelector(requestSelector);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangePasswordDataT>({
    resolver: yupResolver(ChangePasswordSchema),
  });

  const onSubmit = (data: ChangePasswordDataT) => {
    dispatch(AuthActions.changePassword(data)).then((res) => {
      if (res.type.endsWith("/fulfilled")) {
        navigate(PathNames.home);
      }
    });
  };

  useEffect(() => {
    if (error) {
      setError("old_password", { message: error });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  return (
    <AuthWrapper>
      <SideBar type="sign-in" recovery />
      <AuthContainer>
        <AuthContent>
          <AuthTitle>Change password</AuthTitle>

          <AuthInputWrapper>
            <PrimaryInput
              id="old_password"
              type="password"
              register={register}
              label="Old Password"
              placeholder="Old Password"
              error={errors.old_password?.message}
            />
          </AuthInputWrapper>

          <AuthInputWrapper>
            <PrimaryInput
              id="password"
              type="password"
              register={register}
              label="New Password"
              placeholder="New Password"
              error={errors.password?.message}
            />
          </AuthInputWrapper>

          <PrimaryInput
            type="password"
            register={register}
            id="password_repeat"
            label="Enter New Password Again"
            placeholder="Enter New Password Again"
            error={errors.password_repeat?.message}
          />
          {!isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                title="Back"
                btn_type="light"
                onClick={() => navigate(-1)}
              />
              <PrimaryButton
                title="Change password"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              />
            </AuthBottomWrapper>
          )}
        </AuthContent>
      </AuthContainer>

      {isTablet && (
        <MobileFooter>
          {isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                title="Back"
                btn_type="light"
                onClick={() => navigate(-1)}
              />
              <PrimaryButton
                title="Change password"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              />
            </AuthBottomWrapper>
          )}
          <MobileCopyrightBlock />
        </MobileFooter>
      )}
    </AuthWrapper>
  );
};

export default ChangePassword;
