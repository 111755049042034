import { createSlice } from "@reduxjs/toolkit";

import { store } from "application/store";
import { HistoricalState } from "./models";
import HistoricalStockActions from "./actions";
import AuthActions from "../../auth/store/actions";

const initialState: HistoricalState = {
  data: null,
  lastUpdatedDate: "",
};

export const historical = createSlice({
  name: "historical",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // signOut
    builder.addCase(AuthActions.signOut.pending, () => initialState);

    // getHistoricalStockActions
    builder.addCase(
      HistoricalStockActions.getHistoricalStock.fulfilled,
      (state, action) => {
        state.data = action.payload;
      }
    );

    // getHistoricalStockDateCalculation
    builder.addCase(
      HistoricalStockActions.getHistoricalStockDateCalculation.fulfilled,
      (state, action) => {
        state.lastUpdatedDate = action.payload.dateByYearAndMonth;
      }
    );

    // getHistoricalStockLastUpdatedDate
    builder.addCase(
      HistoricalStockActions.getHistoricalStockLastUpdatedDate.fulfilled,
      (state, action) => {
        state.lastUpdatedDate = action.payload.lastUpdatedDate;
      }
    );
  },
});

export default historical.reducer;
export const historicalSelector = () => store.getState().historicalStock;
export const HistoricalReduxAction = historical.actions;
