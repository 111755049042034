import React from "react";
import { BloombergWrapper, Text, InfoIcon, BloombergDescription, BloombergInfoContainer } from "./styles";
import BloombergInfoPopUp from "features/chart/components/BloombergInfoPopUp";

type Props = {
  isBloombergVisible: boolean;
  onChange: any;
  underTable?: boolean
}

const BloombergInfo = ({isBloombergVisible, onChange, underTable}: Props) => {
  return (
    <BloombergWrapper underTable={underTable}>
      <BloombergInfoContainer onClick={onChange}>
        <Text>Sourced or calculated from Bloomberg Index Services Limited data</Text>
        <InfoIcon />
      </BloombergInfoContainer>
      <BloombergDescription underTable={underTable}>
        This information is for internal use only
        {!underTable && <br />} and may not be reproduced or shared in any form
      </BloombergDescription>
      {isBloombergVisible && <BloombergInfoPopUp onClose={onChange} />}
    </BloombergWrapper>
  );
};

export default BloombergInfo;
