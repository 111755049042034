const apiUrl = process.env.REACT_APP_API_URL;

export const applicationConfig = {
  API_URL: apiUrl,
  privacy_policy: "https://assetcamp.com/terms-and-policies/",
  refunds: "https://assetcamp.com/terms-and-policies/#refunds-and-cancellation",
  cookies: "https://assetcamp.com/terms-and-policies/#privacy-policy",
  main_page: "https://moneyfortherestofus.com/",
  how_to_page: "https://assetcamp.com/guides/",
  assetcamp: "https://assetcamp.com/",
  assetcamp_about: "https://assetcamp.com/about/",
  assetcamp_webinar: "https://assetcamp.com/member-webinar ",
  bond_funds: "https://assetcamp.com/guides/sample-stock-funds/",
  account_info: "https://app.assetcamp.com/",
};
