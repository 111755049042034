import { Auth } from "aws-amplify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CreateUserParams,
  UpdateUserBillingAddressParams,
} from "integration/api/ApiAuth/types";
import { CognitoData } from "./model";
import ApiAuth from "integration/api/ApiAuth";
import { RootState } from "application/store";
import ApiUser from "integration/api/ApiUser";
import { setNotify } from "common/notify/slice";
import { UserStatusEnum } from "integration/api/ApiUser/types";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import { NotifyMassages } from "application/components/NotifyPopUp/constants";

class AuthActions {
  static generateCognitoData = async () => {
    const response = await Auth.currentSession();
    const payload = response.getIdToken().payload;
    const token = response.getAccessToken().getJwtToken();

    return {
      token,
      email: payload.email,
      given_name: payload.given_name,
      family_name: payload.family_name,
      "custom:mfru_user_id": payload["custom:mfru_user_id"],
    } as CognitoData;
  };

  static onLoad = createAsyncThunk(
    `auth/${ResponseHandlerName.OnLoad}`,
    async (_, { rejectWithValue }) => {
      try {
        const cognitoData = await AuthActions.generateCognitoData();
        const {
          data: { userStatus },
        } = await ApiUser.getUserInfo(cognitoData["custom:mfru_user_id"]);

        return { cognitoData, userStatus };
      } catch (error) {
        console.log("onLoad Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static singIn = createAsyncThunk(
    `auth/${ResponseHandlerName.SignIn}`,
    async (
      { email, password }: { email: string; password: string },
      { rejectWithValue, dispatch }
    ) => {
      try {
        const response = await Auth.signIn(email, password);
        const cognitoData = await AuthActions.generateCognitoData();
        const {
          data: { userStatus },
        } = await ApiUser.getUserInfo(cognitoData["custom:mfru_user_id"]);

        // Show success sign in message
        if (userStatus === UserStatusEnum.ACTIVE) {
          dispatch(
            setNotify({
              visible: true,
              type: "success",
              text: NotifyMassages.signInSuccess,
            })
          );
        }

        return { cognitoData, userStatus };
      } catch (error) {
        await Auth.signOut();
        console.log("singIn Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static forgotPassword = createAsyncThunk(
    `auth/${ResponseHandlerName.ForgotPassword}`,
    async ({ email }: { email: string }, { rejectWithValue }) => {
      try {
        await Auth.forgotPassword(email);
        return email;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static resetPassword = createAsyncThunk(
    `auth/${ResponseHandlerName.ResetPassword}`,
    async (
      { password, code }: { code: string; password: string },
      { getState, dispatch, rejectWithValue }
    ) => {
      const email = (getState() as RootState).auth.resetEmailValue;
      try {
        await Auth.forgotPasswordSubmit(email, code, password);
        dispatch(
          setNotify({
            visible: true,
            type: "success",
            text: NotifyMassages.resetPasswordSuccess,
          })
        );
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static changePassword = createAsyncThunk(
    `auth/${ResponseHandlerName.ChangePassword}`,
    async (
      { password, old_password }: { old_password: string; password: string },
      { dispatch, rejectWithValue }
    ) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, old_password, password);
        dispatch(
          setNotify({
            visible: true,
            type: "success",
            text: NotifyMassages.changePasswordSuccess,
          })
        );
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static createUser = createAsyncThunk(
    `auth/${ResponseHandlerName.CreateUser}`,
    async (formData: CreateUserParams, { rejectWithValue }) => {
      try {
        const { data } = await ApiAuth.createUser(formData);
        window.sessionStorage.setItem("accountInfo", JSON.stringify(formData));
        window.sessionStorage.setItem("userId", data.userId.toString());

        return { response: data, formData };
      } catch (error: any) {
        if (error.response.data.message) {
          return rejectWithValue(error.response.data);
        }
        return rejectWithValue(error);
      }
    }
  );

  static updateUserBillingAddress = createAsyncThunk(
    `auth/${ResponseHandlerName.UpdateUserBillingAddress}`,
    async (
      formData: UpdateUserBillingAddressParams,
      { rejectWithValue, getState }
    ) => {
      const { userId } = (getState() as RootState).auth.signUp;
      try {
        const response = await ApiAuth.updateUserBillingAddress(
          userId,
          formData
        );

        window.sessionStorage.setItem("billingAddress", JSON.stringify(formData));
        return { formData };
      } catch (error) {
        console.log("updateUserBillingAddress Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getCountries = createAsyncThunk(
    `auth/${ResponseHandlerName.GetCountries}`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await ApiAuth.getCountries();
        return response;
      } catch (error) {
        console.log("getCountries Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getProducts = createAsyncThunk(
    `auth/${ResponseHandlerName.GetProducts}`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await ApiAuth.getProducts();

        window.sessionStorage.setItem("products", JSON.stringify(response.data));
        return response;
      } catch (error) {
        console.log("getProducts Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static createSubscription = createAsyncThunk(
    `auth/${ResponseHandlerName.CreateSubscription}`,
    async (_, { getState, rejectWithValue }) => {
      const { signUp, subscription } = (getState() as RootState).auth;
      const subscriptionData = {
        ...signUp,
        coupon: subscription.coupon,
        subscriptionId: subscription.subscriptionId,
      };
      try {
        const response = await ApiAuth.createSubscription(subscriptionData);

        window.sessionStorage.setItem("subscription", JSON.stringify(response.data));

        return response;
      } catch (error) {
        console.log("createSubscription Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static signOut = createAsyncThunk(
    `auth/${ResponseHandlerName.SignOut}`,
    async () => {
      return Auth.signOut();
    }
  );

  static getCustomerPortalLink = createAsyncThunk(
    `auth/${ResponseHandlerName.GetCustomerPortalLink}`,
    async (_, { getState, rejectWithValue }) => {
      const {
        auth: { cognitoData },
      } = getState() as RootState;
      const mfru_user_id = cognitoData["custom:mfru_user_id"];
      try {
        const response = await ApiUser.getCustomerPortalLink(mfru_user_id);
        return response.data.customerPortalLink;
      } catch (error) {
        console.log("getCustomerPortalLink Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default AuthActions;
