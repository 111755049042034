import Api from "../Api";

class ApiIndicesComparison extends Api {
  static getIndicesComparisonData(
    indicesToCompare: string[],
    metricToCompare: string,
    startingDate: { year: number; month: number },
    endingDate: { year: number; month: number }
  ) {
    return ApiIndicesComparison.instance.axiosInst.post(
      "/stock/index-performance-comparison",
      {
        indicesToCompare,
        metricToCompare,
        startingDate,
        endingDate,
      }
    );
  }
}

export default ApiIndicesComparison;
