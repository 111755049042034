import * as yup from "yup";

import { Errors } from "../constants";
import { CommonErrors } from "../../errorService";
import { passwordValidation } from "application/helpers/validations";

export const ChangePasswordSchema = yup
  .object({
    password: passwordValidation,
    password_repeat: yup
      .string()
      .required(CommonErrors.requiredField)
      .oneOf([yup.ref("password")], Errors.passwordConfirmation),
  })
  .required();
