import { createAsyncThunk } from "@reduxjs/toolkit";

import ApiLastDate from "integration/api/ApiHome";
import ApiHistoricalStock from "integration/api/ApiHistoricalStock";
import { ResponseHandlerName } from "common/responseHandlerService/model";

class HistoricalStockActions {
  // date: string // 2022-09 // only year and month
  static getHistoricalStock = createAsyncThunk(
    `historicalStock/${ResponseHandlerName.GetHistoricalStock}`,
    async (date: string | undefined, { rejectWithValue }) => {
      try {
        const { data } = await ApiHistoricalStock.getHistoricalStock({ date });
        return data;
      } catch (error) {
        console.log("getHistoricalStock Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  // date: string // 2022-09 // only year and month
  static getHistoricalStockDateCalculation = createAsyncThunk(
    `historicalStock/${ResponseHandlerName.GetHistoricalStockDateCalculation}`,
    async (date: string, { rejectWithValue }) => {
      try {
        const { data } =
          await ApiHistoricalStock.getHistoricalStockDateCalculation({ date });
        return data;
      } catch (error) {
        console.log("getHistoricalStockDateCalculation Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getHistoricalStockLastUpdatedDate = createAsyncThunk(
    `historicalStock/${ResponseHandlerName.GetHistoricalStockLastUpdatedDate}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await ApiLastDate.getLastUpdateDate();
        return data;
      } catch (error) {
        console.log("getHistoricalStockLastUpdatedDate Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default HistoricalStockActions;
