import Api from "../Api";

class ApiInceptionsData extends Api {
  static getInceptionsData() {
    return ApiInceptionsData.instance.axiosInst.get(
      "/stock/index-performance-comparison/inceptions"
    );
  }
}

export default ApiInceptionsData;