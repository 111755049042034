import Api from "../Api";

class ApiLastDate extends Api {
  static getLastUpdateDate() {
    return ApiLastDate.instance.axiosInst.get<{ lastUpdatedDate: string }>(
      "/reports"
    );
  }
}

export default ApiLastDate;
