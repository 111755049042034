const getLocalData = () => {
  let billingAddress = null,
    accountInfo = null,
    subscription = {
      clientSecret: "",
      coupon: null,
      subscriptionId: "",
      oldPrice: null,
      newPrice: null,
      couponStatus: null,
      discount: null,
      taxes: [],
      total: null,
      noPaymentNeeded: false
    },
    userId = "",
    priceId = "",
    products = [];

  if (window.sessionStorage.getItem("billingAddress")) {
    billingAddress = JSON.parse(window.sessionStorage.getItem("billingAddress")!);
  }

  if (window.sessionStorage.getItem("accountInfo")) {
    accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo")!);
  }

  if (window.sessionStorage.getItem("userId")) {
    userId = window.sessionStorage.getItem("userId")!;
  }

  if (window.sessionStorage.getItem("priceId")) {
    priceId = window.sessionStorage.getItem("priceId")!;
  }

  if (window.sessionStorage.getItem("subscription")) {
    subscription = JSON.parse(window.sessionStorage.getItem("subscription")!);
  }

  if (window.sessionStorage.getItem("products")) {
    products = JSON.parse(window.sessionStorage.getItem("products")!);
  }
  return {
    billingAddress,
    accountInfo,
    subscription,
    userId,
    priceId,
    products,
  }
}

export default getLocalData;