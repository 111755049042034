import styled from "styled-components";
import {colors} from "../../styles/colors";

export const MobileCopyright = styled.div`
  display: flex;
  justify-content: center;
`;

export const CopyrightText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.blue_default};
`;

export const CopyrightCircle = styled(CopyrightText)`
  margin: 0 10px;
`;

export const CopyrightAnd = styled(CopyrightText)`
  margin: 0 5px;
`;

export const CopyrightBlock = styled.div`
  display: flex;
`;

export const CopyrightAction = styled.a`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  font-family: "Helvetica";
  text-decoration: none;
  color: ${colors.brown_default};
`;