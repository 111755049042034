import styled from "styled-components";
import {colors} from "../../styles/colors";

export const Filter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(5, 22, 56, 0.7);
`;

export const Container = styled.div`
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 16px;
  background-color: ${colors.white};
`;

export const Header = styled.div`
  display: flex;
  padding-bottom: 24px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.stroke_grey};

  img {
    cursor: pointer;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  font-weight: 300;
  line-height: 28px;
  font-style: normal;
  color: ${colors.font_grey};
  font-family: "Helvetica";
`;

export const PasswordTip = styled.ul`
  list-style: inside;
  padding-left: 5px;
  li {
    margin-bottom: 8px;
    
    span {
      left: -5px;
      position: relative;
    }
  }
`;