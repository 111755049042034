import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import { SubscriptionPlanProps } from "./types";
import AuthActions from "common/auth/store/actions";
import {
  authSelector,
  setProduct,
  setDiscount,
  setDiscountDetails,
  setTaxes,
  setBillingAddressError,
  setPaymentError,
} from "common/auth/store/slice";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import { getSubscriptionSlogan } from "application/helpers/textFormatter";
import { getCurrencySymbol } from "application/helpers/currencyFormatter";

import SideBar from "features/side-bar";
import SmallLoader from "application/components/SmallLoader";
import PlanSwitcher from "application/components/PlanSwitcher";
import PrimaryButton from "application/components/PrimaryButton";

import {
  Loader,
  PlanTitle,
  PlanPrice,
  PlanHeader,
  PlanContainer,
  PlanArrowIcon,
  PlanDescription,
  PlanPriceContainer,
  PlanPriceDescription,
  PlanDescriptionContainer,
  CouponContainer,
  CouponInput,
  CouponApplyBtn,
  PromoCodeInputWrapper,
  CouponInputLabel,
  DiscountPlanPrice,
  PlanPriceOld,
  PlanPriceDescriptionContainer,
  FreeTrialDetailsList,
  FreeTrialDetailsTitle,
} from "./styles";
import {
  AuthWrapper,
  AuthContainer,
  AuthBottomWrapper,
  MobileConfirmButton,
  SubscriptionInfo,
  InfoIconAccept,
  InfoIcon,
} from "common/auth/styles";
import {
  SignUpTitle,
  SignUpContent,
  SignUpSubTitle,
} from "common/auth/signUp/styles";
import { useMediaQuery, useTheme } from "@mui/material";
import MobileFooter from "../../../application/components/MobileFooter";
import MobileCopyrightBlock from "../../../application/components/MobileCopyright";

//@ts-ignore
import warningSource from "application/assets/images/icons/warning.png";
import BillingAddressError from "../../../application/components/BillingAddressError";
import PaymentError from "../../../application/components/PaymentError";

const SubscriptionPlan = ({ type }: SubscriptionPlanProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(true);
  const [isContinue, setIsContinue] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { createSubscription, getProducts } = useAppSelector(requestSelector);
  const loading = createSubscription.loading || getProducts.loading;
  const planLoading = getProducts.loading;
  const {
    products,
    signUp,
    subscription,
    billingAddressError,
    isPaymentError,
  } = useAppSelector(authSelector);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const onSubmit = () => {
    if (isContinue) {
      handleSuccess();
    } else {
      handleApplyCoupon(true);
    }
  };

  const handleSuccess = () => {
    switch (type) {
      case "sign-up":
        navigate(PathNames.registrationTerms);
        break;
      case "update-subscription":
        navigate(PathNames.updateSubscriptionPayment);
        break;
      default:
        break;
    }
  };

  const onPlanChange = (id: string) => {
    window.sessionStorage.setItem("priceId", id.toString());
    dispatch(setProduct(id));
  };

  const activeProduct = useMemo(() => {
    return products.find((p) => p.priceId === signUp.priceId);
  }, [signUp.priceId, subscription]);

  const sloganText = useMemo(() => {
    return getSubscriptionSlogan(activeProduct, subscription);
  }, [activeProduct]);

  useEffect(() => {
    const billingAddress = window.sessionStorage.getItem("billingAddress");

    if (!billingAddress && type !== "update-subscription") {
      navigate(PathNames.registration);
    }

    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 300);

    dispatch(AuthActions.getProducts());
  }, []);

  const handleBillingAddressError = () => {
    dispatch(setBillingAddressError(false));
  };

  const handleApplyCoupon = (isNext?: boolean) => {
    if (subscription.coupon !== inputValue) {
      dispatch(setDiscount(inputValue));
    }

    dispatch(AuthActions.createSubscription()).then((res: any) => {
      if (res.type.endsWith("/fulfilled")) {
        setIsContinue(res.type.endsWith("/fulfilled"));
      }

      if (res.type.endsWith("/rejected")) {
        if (res.payload.response.data.cause === "INCORRECT_BILLING_ADDRESS") {
          dispatch(setBillingAddressError(true));
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          dispatch(setPaymentError(true));

          setTimeout(() => {
            dispatch(setPaymentError(false));
          }, 3000);
          return;
        }
      }

      if (res.payload.data?.couponStatus === "APPLIED") {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }

      dispatch(setDiscountDetails(res.payload.data));
      dispatch(setTaxes(res.payload.data.taxes));

      if (isNext && res.type.endsWith("/fulfilled")) {
        handleSuccess();
      } else {
        setOpen(true);
        setInputValue("");
      }
    });
  };

  return (
    <AuthWrapper>
      {type === "sign-up" ? (
        <SideBar type="sign-up" activeStep={3} />
      ) : (
        <SideBar type="update-subscription" activeStep={1} recovery />
      )}
      <AuthContainer isSignUp>
        <SignUpContent>
          <SignUpTitle>Subscription Plan</SignUpTitle>
          {products.length > 1 && (
            <SignUpSubTitle>
              Choose a monthly or annual subscription
            </SignUpSubTitle>
          )}

          <PlanContainer>
            {planLoading ? (
              <Loader>
                <SmallLoader />
              </Loader>
            ) : (
              <>
                <PlanHeader>
                  <PlanTitle>{activeProduct?.name}</PlanTitle>
                  {products.length > 1 && (
                    <PlanSwitcher
                      data={products}
                      onSelect={onPlanChange}
                      activePlanPriceId={activeProduct?.priceId || ""}
                    />
                  )}
                </PlanHeader>

                <PlanPriceContainer>
                  <PlanPriceDescriptionContainer>
                    {subscription.couponStatus === "APPLIED" &&
                    subscription.newPrice !== subscription.oldPrice &&
                    activeProduct?.price === subscription.oldPrice ? (
                      <DiscountPlanPrice>
                        <PlanPriceOld>
                          {getCurrencySymbol(activeProduct?.currency || "")}
                          {subscription.oldPrice}
                        </PlanPriceOld>
                        <PlanPrice>
                          {getCurrencySymbol(activeProduct?.currency || "")}
                          {subscription.newPrice}
                        </PlanPrice>
                      </DiscountPlanPrice>
                    ) : (
                      <PlanPrice>
                        {getCurrencySymbol(activeProduct?.currency || "")}
                        {activeProduct?.price}
                      </PlanPrice>
                    )}
                    <PlanPriceDescription>{sloganText}</PlanPriceDescription>
                  </PlanPriceDescriptionContainer>
                  {activeProduct?.trialDays && activeProduct.trialDays > 0 && (
                    <PlanDescription
                      isBoldText
                    >{`Free for the first ${activeProduct?.trialDays} days!`}</PlanDescription>
                  )}
                </PlanPriceContainer>

                <PlanDescriptionContainer>
                  <PlanArrowIcon />
                  <PlanDescription>
                    Free Trial: Full access to Asset Camp with no charge for 7
                    days. (New accounts only)
                  </PlanDescription>
                </PlanDescriptionContainer>
                <PlanDescriptionContainer>
                  <PlanArrowIcon />
                  <PlanDescription>
                    Your subscription will be renewed annually, with an email
                    notice sent 45 days before renewal.
                  </PlanDescription>
                </PlanDescriptionContainer>
                <PlanDescriptionContainer>
                  <PlanArrowIcon />
                  <PlanDescription>
                    We ask for your payment card information to help limit
                    trials to one per person.
                  </PlanDescription>
                </PlanDescriptionContainer>
                <CouponContainer>
                  <CouponInputLabel>Promo code</CouponInputLabel>
                  <PromoCodeInputWrapper>
                    <CouponInput
                      value={inputValue}
                      onChange={(e) => setInputValue(e.currentTarget.value)}
                      placeholder="Promo code"
                      id="Promo_code_input"
                    />
                    <CouponApplyBtn
                      disabled={inputValue.length === 0}
                      variant="outlined"
                      onClick={() => handleApplyCoupon(false)}
                    >
                      APPLY
                    </CouponApplyBtn>
                    <SubscriptionInfo open={open} isSuccess={isSuccess}>
                      {isSuccess ? (
                        <InfoIconAccept />
                      ) : (
                        <InfoIcon src={warningSource} alt="info icon" />
                      )}
                      {isSuccess
                        ? "Promo code was successfully applied"
                        : "Promo code is not applicable"}
                    </SubscriptionInfo>
                  </PromoCodeInputWrapper>
                </CouponContainer>
                {activeProduct?.trialDays && activeProduct.trialDays > 0 && (
                  <FreeTrialDetailsTitle>
                    Free Trial Details
                  </FreeTrialDetailsTitle>
                )}
                {activeProduct?.trialDays && activeProduct.trialDays > 0 && (
                  <FreeTrialDetailsList>
                    <li>
                      Returning users are not eligible for free trials and will
                      be charged at checkout.
                    </li>
                    <li>
                      Your 7-day free trial will start once you complete setting
                      up your account and click 'Subscribe.'
                    </li>
                    <li>
                      The free trial includes full access to Asset Camp’s tools,
                      charts, webinars, and educational information.
                    </li>
                    <li>
                      Unless canceled, your annual subscription will
                      automatically begin at the end of your trial, charging the
                      annual price minus any promotional discounts.
                    </li>
                    <li>
                      You may cancel your trial or account anytime after
                      creation by visiting your 'Subscription Info' page at{" "}
                      <span>app.assetcamp.com.</span>
                    </li>
                  </FreeTrialDetailsList>
                )}
              </>
            )}
            {billingAddressError && (
              <BillingAddressError onClick={handleBillingAddressError} />
            )}
          </PlanContainer>

          {!isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                btn_type="light"
                title="Back"
                onClick={() => {
                  handleBillingAddressError();
                  navigate(-1);
                }}
              />
              <PrimaryButton
                title="Continue"
                loading={loading}
                onClick={onSubmit}
              />
            </AuthBottomWrapper>
          )}
        </SignUpContent>
        {isTablet && (
          <MobileFooter>
            {isMobile && (
              <MobileConfirmButton>
                <AuthBottomWrapper>
                  <PrimaryButton
                    btn_type="light"
                    title="Back"
                    onClick={() => {
                      handleBillingAddressError();
                      navigate(-1);
                    }}
                  />
                  <PrimaryButton
                    title="Continue"
                    loading={loading}
                    onClick={onSubmit}
                  />
                </AuthBottomWrapper>
              </MobileConfirmButton>
            )}
            <MobileCopyrightBlock />
          </MobileFooter>
        )}
      </AuthContainer>
      {isPaymentError && <PaymentError />}
    </AuthWrapper>
  );
};

export default SubscriptionPlan;
