import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { FC, useEffect, useState } from "react";

import {
  resetErrors,
  requestSelector,
} from "common/responseHandlerService/slice";
import { LoginDataT } from "./types";
import AuthActions from "../store/actions";
import { SignInSchema } from "./validations";
import { authSelector } from "../store/slice";
import { PathNames } from "application/routes";
import { useAppDispatch, useAppSelector } from "application/store";

import SideBar from "features/side-bar";
import Checkbox from "application/components/CheckBox";
import PrimaryInput from "application/components/PrimaryInput";
import PrimaryButton from "application/components/PrimaryButton";
import SubscriptionDeclinedPopUp from "application/components/SubscriptionDeclinedPopUp";

import {
  AuthTitle,
  AuthContent,
  AuthWrapper,
  AuthContainer,
  AuthActionText,
  AuthInputWrapper,
  AuthActionNormalText,
  BottomActionContainer,
  MobileConfirmButton,
} from "../styles";
import { ForgotContainer, RememberContainer } from "./styles";
import { useTheme, useMediaQuery } from "@mui/material";
import MobileCopyrightBlock from "../../../application/components/MobileCopyright";
import MobileFooter from "../../../application/components/MobileFooter";

const Login: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    signIn: { loading, error },
  } = useAppSelector(requestSelector);
  const [checked, setChecked] = useState(false);
  const { isVisibleSubscriptionDeclinedPopUp } = useAppSelector(authSelector);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginDataT>({
    resolver: yupResolver(SignInSchema),
  });

  const onSubmit = (data: LoginDataT) => {
    dispatch(resetErrors());
    dispatch(AuthActions.singIn(data));
  };

  useEffect(() => {
    if (error) {
      setError("email", { message: error });
      setError("password", { message: error });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  return (
    <>
      {isVisibleSubscriptionDeclinedPopUp && <SubscriptionDeclinedPopUp />}
      <AuthWrapper>
        <SideBar type="sign-in" />
        <AuthContainer>
          <AuthContent>
            <AuthTitle>Login</AuthTitle>

            <AuthInputWrapper>
              <PrimaryInput
                id="email"
                register={register}
                label="Email Address"
                placeholder="Email Address"
                error={errors.email?.message}
              />
            </AuthInputWrapper>

            <AuthInputWrapper>
              <PrimaryInput
                id="password"
                type="password"
                label="Password"
                register={register}
                placeholder="Password"
                error={errors.password?.message}
              />
            </AuthInputWrapper>

            <ForgotContainer>
              <RememberContainer>
                <Checkbox
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
                <AuthActionNormalText>Remember me</AuthActionNormalText>
              </RememberContainer>
              <AuthActionText
                onClick={() => navigate(PathNames.passwordRecovery)}
              >
                Forgot password?
              </AuthActionText>
            </ForgotContainer>
            {!isMobile && (
              <>
                <PrimaryButton
                  title="Log in"
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                />
                <BottomActionContainer>
                  <AuthActionNormalText>
                    Don't have an account?
                  </AuthActionNormalText>
                  <AuthActionText onClick={() => {
                    window.sessionStorage.clear();
                    navigate(PathNames.registration);
                  }}>
                    Sign up
                  </AuthActionText>
                </BottomActionContainer>
              </>
            )}
          </AuthContent>
          {isTablet && (
            <MobileFooter>
              {isMobile && (
                <>
                  <MobileConfirmButton>
                    <PrimaryButton
                      title="Log in"
                      loading={loading}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </MobileConfirmButton>
                </>
              )}
              <MobileCopyrightBlock />
            </MobileFooter>
          )}
        </AuthContainer>
      </AuthWrapper>
    </>
  );
};

export default Login;
