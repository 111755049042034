import React from "react";
import { MobileConfirmWrapper } from "./styles";

const MobileFooter = ({ children }: any) => {
  return (
    <MobileConfirmWrapper>
      {children}
    </MobileConfirmWrapper>
  );
};

export default MobileFooter;