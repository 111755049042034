import React, { useMemo, useState } from "react";
import { MultipleDropGroupProps } from "./types";
import { FilterDataItem } from "common/valuationReports/store/model";
import {
  List,
  Label,
  Filter,
  Disabled,
  Selected,
  ListItem,
  Container,
  ListGroup,
  ListLabel,
  OneList,
  Wrapper,
} from "./styles";
import arrowSource from "application/assets/images/icons/arrow-down.svg";
import acceptSource from "application/assets/images/icons/green-accept.svg";
import { Checkbox } from "@mui/material";

const MultipleDropGroup = ({
  data,
  label,
  disabled,
  selected,
  maxSelected,
  onSelect,
}: MultipleDropGroupProps) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => setVisible(!visible);

  const handleSelect = (item: FilterDataItem, isSelected: boolean) => {
    if (isSelected || selected.length < maxSelected) onSelect(item);
  };

  const LABELS = Object.keys(data);
  const showLabel = LABELS.length > 1;

  const selectedNames = selected.map((i) => i.name);

  const doSelectedMaximum = useMemo(() => {
    return selected.length === maxSelected;
  }, [selected, maxSelected]);

  const renderList = () =>
    LABELS.map((key) =>
      showLabel ? (
        <ListGroup key={key}>
          <ListLabel>{key}</ListLabel>
          {data[key].map((item) => {
            const isSelected = selectedNames.includes(item.name);
            return (
              <ListItem
                key={item.name}
                isSelected={isSelected}
                onClick={() => handleSelect(item, isSelected)}
              >
                <Checkbox
                  color="primary"
                  checked={isSelected}
                  disabled={doSelectedMaximum && !isSelected}
                  inputProps={{
                    "aria-labelledby": `multiple-drop-group-${item.name}`,
                  }}
                />
                <p>{item.name}</p>
              </ListItem>
            );
          })}
        </ListGroup>
      ) : (
        <OneList key={key}>
          {data[key].map((item) => {
            const isSelected = selectedNames.includes(item.name);
            return (
              <ListItem
                key={item.name}
                isSelected={isSelected}
                onClick={() => handleSelect(item, isSelected)}
              >
                <p>{item.name}</p>
                {isSelected && <img src={acceptSource} alt="accept" />}
              </ListItem>
            );
          })}
        </OneList>
      )
    );

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Container visible={visible}>
        {disabled && <Disabled />}
        <Selected onClick={toggleVisible}>
          <p>{`${selected.length} selected`}</p>
          <img src={arrowSource} alt="arrow" />
        </Selected>
        {visible && <List>{renderList()}</List>}
      </Container>
      {visible && <Filter onClick={toggleVisible} />}
    </Wrapper>
  );
};

export default MultipleDropGroup;
