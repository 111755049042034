import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

export const SortArrow = () => {
  return (
    <SvgIcon viewBox="12px 16px 0 0" style={{width: "12px", height: "16px", marginLeft: "8px"}}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0L12 6H0L6 0ZM6 16.4004L0 10.4004H12L6 16.4004Z" fill="#757575"/>
    </SvgIcon>
  )
};

export const SortArrowDown = () => {
  return (
    <SvgIcon viewBox="12px 6px 0 0" style={{width: "12px", height: "6px", marginLeft: "8px"}}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0L6 6L12 0" fill="#757575"/>
    </SvgIcon>
  )
};

export const SortArrowUp = () => {
  return (
    <SvgIcon viewBox="2px 6px 0 0" style={{width: "12px", height: "6px", marginLeft: "8px"}}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6L6 0L0 6" fill="#757575"/>
    </SvgIcon>
  )
}