import React, { useState } from "react";
import { Container, ContentContainer } from "./styles";
import Footer from "../Footer";
import PrimaryHeader from "../PrimaryHeader";

const MainContainer = ({
  date,
  children,
}: {
  date?: string;
  children: any;
}) => {
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  const handleMenuAction = (isClose: boolean) => {
    setMenuVisible(isClose);
  };

  return (
    <Container menuVisible={menuVisible} className="main-container">
      <ContentContainer className="main-content-container">
        <PrimaryHeader menuVisible={menuVisible} onClose={handleMenuAction} />
        {children}
      </ContentContainer>
      <Footer date={date} />
    </Container>
  );
};

export default MainContainer;
