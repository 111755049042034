import React, { FC, useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import MainContainer from "../../components/MainContainer";
import { ErrorLeftSide, ErrorImg, ErrorWrapper, Title, ErrorText, ButtonWrapper } from "./styles";
import errorImgSource from "application/assets/images/error-image.svg";
import PrimaryButton from "../../components/PrimaryButton";
import { PathNames } from "../../routes";

const Error: FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    for ( const key in PathNames) {
      if (PathNames[key as keyof typeof PathNames] === pathname) {
        navigate("/");
      }
    }
  }, []);

  return (
    <MainContainer>
      <ErrorWrapper>
        <ErrorLeftSide>
          <Title>Oops. Page not found.</Title>
          <ErrorText>
            It seems an error occurred.
            Hit back or return to the home page.
            If you keep having problems please email us at <a href="mailto:help@assetcamp.com">help@assetcamp.com</a>
          </ErrorText>
          <ButtonWrapper>
            <PrimaryButton
              title="go to home Page"
              loading={false}
              onClick={() => {navigate(PathNames.home)}}
            />
          </ButtonWrapper>
        </ErrorLeftSide>
        <div>
          <ErrorImg src={errorImgSource} alt="error" />
        </div>
      </ErrorWrapper>
    </MainContainer>
  )
};

export default Error;
