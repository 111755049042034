import React from "react";

import { CheckBoxProps } from "./types";

import { CheckBoxContainer } from "./styles";

import arrowIcon from "application/assets/images/icons/arrow-white-small.svg";

const Checkbox = ({ error, checked, onClick }: CheckBoxProps) => {
  return (
    <CheckBoxContainer checked={checked} onClick={onClick} error={error}>
      {checked && <img src={arrowIcon} alt="arrow" />}
    </CheckBoxContainer>
  );
};

export default Checkbox;
