import React from "react";
import { Navigate } from "react-router-dom";
import { PathNames } from "./constants";

import HomePage from "common/home";
import StockValuation from "common/valuationReports";
import LoginPage from "common/auth/signIn";
import ErrorPage from "../pages/error/ErrorPage";
import PaymentPage from "common/auth/payment";
import TermsPage from "common/auth/signUp/Terms";
import ChangePasswordPage from "common/auth/changePassword";
import RegistrationPage from "common/auth/signUp/Registration";
import SubscriptionPlanPage from "common/auth/subscription-plan";
import RegistrationBillingPage from "common/auth/signUp/BillingData";
import ResetPasswordPage from "common/auth/passwordRecovery/ResetPassword";
import ForgotPasswordPage from "common/auth/passwordRecovery/ForgotPassword";
import ExpectStock from "common/expectedStock";
import HistoricalStock from "../../common/historicalStock/HistoricalStock";
import IndicesComparison from "../../common/indicesComparison";
import ValuationSummary from "common/valuationSummary";
import IndicesSummary from "../../common/indicesPerformanceSummary";
import Bonds from "../../common/bonds";
import StockBondYieldsSpreadComparison from "common/stockBondYieldsComparison";
import CurveSpreadSummary from "common/bondSpreadSummary";
import BondValuationSummary from "common/bondValuationSummary";
import BondIndicesSummary from "common/bondIndicesPerformanceSummary";
import BondsComparison from "common/bondsComparison";
import FixedIncomeCharts from "common/fixedIncomeCharts";
import BondExpectedReturnsModeling from "common/bondExpectedReturnsModeling";

export const authRouts = [
  {
    path: PathNames.login,
    title: "Login",
    component: <LoginPage />,
  },
  // Sign up
  {
    path: PathNames.registration,
    title: "Registration",
    component: <RegistrationPage />,
  },
  {
    path: PathNames.registrationBilling,
    title: "Registration Billing",
    component: <RegistrationBillingPage />,
  },
  {
    path: PathNames.registrationSubscriptionPlan,
    title: "Registration Subscription Plan",
    component: <SubscriptionPlanPage type="sign-up" />,
  },
  {
    path: PathNames.registrationPayment,
    title: "Registration Payment",
    component: <PaymentPage type="sign-up" />,
  },
  {
    path: PathNames.registrationTerms,
    title: "Registration Terms",
    component: <TermsPage />,
  },
  // Password recovery
  {
    path: PathNames.passwordRecovery,
    title: "PasswordRecovery",
    component: <ForgotPasswordPage />,
  },
  {
    path: PathNames.passwordRecoveryReset,
    title: "PasswordRecoveryReset",
    component: <ResetPasswordPage />,
  },
  // Update subscription
  {
    path: PathNames.updateSubscriptionPlan,
    title: "Update Subscription Plan",
    component: <SubscriptionPlanPage type="update-subscription" />,
  },
  {
    path: PathNames.updateSubscriptionPayment,
    title: "Update Subscription Payment",
    component: <PaymentPage type="update-subscription" />,
  },
  {
    path: "/*",
    title: "Login Redirection",
    component: <Navigate to={PathNames.login} />,
  },
];

export const publicRouts = [
  {
    path: PathNames.home,
    title: "Home",
    component: <HomePage />,
  },
  {
    path: PathNames.valuationReports,
    title: "Stock Report",
    component: <StockValuation />,
  },
  {
    path: PathNames.changePassword,
    title: "ChangePassword",
    component: <ChangePasswordPage />,
  },
  {
    path: PathNames.expectStock,
    title: "Expect Stock Market Returns",
    component: <ExpectStock />,
  },
  {
    path: PathNames.historicalStock,
    title: "Historical Stock",
    component: <HistoricalStock />,
  },
  {
    path: PathNames.indicesComparison,
    title: "Performance Comparison Tool",
    component: <IndicesComparison />,
  },
  {
    path: PathNames.indicesSummary,
    title: "Returns Summary Table",
    component: <IndicesSummary />,
  },
  {
    path: PathNames.bondIndicesSummary,
    title: "Returns Summary Table",
    component: <BondIndicesSummary />,
  },
  {
    path: "/*",
    title: "Error",
    component: <ErrorPage />,
  },
  {
    path: PathNames.valuationSummary,
    title: "Stock Summary",
    component: <ValuationSummary />,
  },
  {
    path: PathNames.stockBondComparison,
    title: "Stock Bond Comparison",
    component: <StockBondYieldsSpreadComparison />,
  },
  {
    path: PathNames.curveSpreadSummary,
    title: "Curve Spread Summary",
    component: <CurveSpreadSummary />,
  },
  {
    path: PathNames.bondValuationSummary,
    title: "Fixed Income Summary Tables",
    component: <BondValuationSummary />,
  },
  {
    path: PathNames.bonds,
    title: "Bonds",
    component: <Bonds />,
  },
  {
    path: PathNames.bondsComparison,
    title: "Performance Comparison Tool",
    component: <BondsComparison />,
  },
  {
    path: PathNames.fixedIncomeCharts,
    title: "Fixed Income Charts",
    component: <FixedIncomeCharts />,
  },
  {
    path: PathNames.expectedReturnsModeling,
    title: "Expected Returns Modeling",
    component: <BondExpectedReturnsModeling />,
  },
];
