import styled from "styled-components";
import { TextField } from "@mui/material";
import { colors } from "application/styles/colors";

const mediaStep_1 = 1160;
const mediaStep_2 = 1023;
const mediaStep_3 = 940;
const mediaStep_4 = 760;

export const Input = styled(TextField)`
  width: 100%;
  background-color: ${colors.stripe_grey_light};
  input {
    padding: 12px;
    font-family: "Helvetica";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: ${mediaStep_3}px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
  fieldset {
    border: none;
  }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  /* Font style */
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  font-family: "Helvetica";
  color: ${colors.font_grey};

  @media (max-width: ${mediaStep_3}px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const DatePickerContainer = styled.div`
  box-sizing: border-box;
  .date-picker {
    height: 44px;
    width: 258px;
    @media only screen and (max-width: ${mediaStep_1}px) {
      width: 224.5px;
    }

    @media only screen and (max-width: ${mediaStep_2}px) {
      min-width: 184px;
      width: auto;
    }

    @media only screen and (max-width: ${mediaStep_3}px) {
      min-width: 150;
      width: auto;
    }

    @media only screen and (max-width: ${mediaStep_4}px) {
      min-width: 150;
      width: 100%;
    }
  }
`;
