import styled from "styled-components";
import { Link } from "@mui/material";
import { colors } from "../../styles/colors";

export const FooterGroup = styled.div`
  width: 1140px;
  margin: 0 auto;
  margin-top: 56px;

  @media only screen and (max-width: 1160px) {
    width: 990px;
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }

  @media only screen and (max-width: 647px) {
    width: 100%;
  }
`;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${colors.grey_400};
  box-sizing: border-box;
  display: flex;
  padding: 24px 0;

  @media (max-width: 1023px) {
    padding: 16px 0;
    margin-top: 24px;
  }
`;

export const Container = styled.div`
  width: 1140px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 16px;
  }

  @media (max-width: 647px) {
    flex-direction: column;
  }
`;

export const LinksSection = styled.div`
  display: flex;

  @media (max-width: 647px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const FooterLink = styled(Link)`
  &:not(:first-child) {
    margin-left: 40px;
  }

  @media (max-width: 1023px) {
    &:not(:first-child) {
      margin-left: 24px;
    }

    &:first-child {
      order: 1;
    }

    &:nth-child(2) {
      order: 3;
    }

    &:nth-child(3) {
      order: 2;
    }

    &:nth-child(4) {
      order: 4;
    }
  }

  @media (max-width: 647px) {
    display: flex;
    min-width: 50%;
    margin-bottom: 8px !important;

    &:not(:first-child) {
      margin-left: unset;
    }
  }
`;
