import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import { BondSummaryState } from "./models";
import AuthActions from "../../auth/store/actions";
import BondSummaryActions from "./actions";

const initialState: BondSummaryState = {
  bondSummaryData: {
    data: [],
    endingDate: "",
  },
  filters: {
    endingDate: "",
  },
  maxEndingDate: "",
};

export const bondSummarySlice = createSlice({
  name: "bond-summary",
  initialState,
  reducers: {
    setBondEndingDate: (state, action: PayloadAction<string>) => {
      state.filters.endingDate = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // getDataForFilters
    builder.addCase(
      BondSummaryActions.getDataForFilters.fulfilled,
      (state, action) => {
        state.maxEndingDate = action.payload.endDate;
      }
    );

    // getIndicesPerformanceSummaryData
    builder.addCase(
      BondSummaryActions.getBondSummaryData.fulfilled,
      (state, action) => {
        state.bondSummaryData = action.payload;
      }
    );

    // signOut
    builder.addCase(AuthActions.signOut.pending, () => initialState);
  },
});

export default bondSummarySlice.reducer;
export const bondSummarySelector = () => store.getState().bondSummary;
export const { setBondEndingDate, resetState } = bondSummarySlice.actions;
