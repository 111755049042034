import CryptoJS from "crypto-js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import ApiMetrics from "integration/api/ApiMetrics";
import ApiIndices from "integration/api/ApiIndices";
import ApiIndicesComparison from "integration/api/ApiIndicesComparison";
import { FilterData, IndicesComparisonState } from "./models";
import { RootState } from "application/store";
import ApiInceptionsData from "integration/api/ApiGetInceptions";

class IndicesComparisonActions {
  static getMetrics = createAsyncThunk(
    `indices-comparison/${ResponseHandlerName.GetIndicesComparisonMetrics}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiMetrics.getIndicesComparisonMetrics();
        return res.data;
      } catch (error) {
        console.log("getMetrics Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };

  static getIndices = createAsyncThunk(
    `indices-comparison/${ResponseHandlerName.GetIndices}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiIndices.getIndices();
        const data = IndicesComparisonActions.decryptData(
          res.data.data
        ) as FilterData;
        return data;
      } catch (error) {
        console.log("getIndices Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getInceptions = createAsyncThunk(
    `indices-comparison/${ResponseHandlerName.GetInceptionsComparisonData}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiInceptionsData.getInceptionsData();
        return res.data;
      } catch (error) {
        console.log("getIndices Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getIndicesComparisonData = createAsyncThunk(
    `indices-comparison/${ResponseHandlerName.GetIndicesComparisonData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).indicesComparison;

      const indices = filters.selectedIndices.map((i) => i.code);
      const metric = filters.selectedMetric.code;
      const startingYear = filters.startingDate.split("-")[0];
      const startingMonth = filters.startingDate.split("-")[1];
      const endingYear = filters.endingDate.split("-")[0];
      const endingMonth = filters.endingDate.split("-")[1];

      try {
        const res = await ApiIndicesComparison.getIndicesComparisonData(
          indices,
          metric,
          { year: Number(startingYear), month: Number(startingMonth) },
          { year: Number(endingYear), month: Number(endingMonth) }
        );
        const data = IndicesComparisonActions.decryptData(res.data.data) as {
          indices: IndicesComparisonState["indicesComparisonData"]["data"];
          startDate: string;
          endDate: string;
        };

        return {
          data: data.indices,
          metric: filters.selectedMetric,
          startingDate: filters.startingDate,
          endingDate: filters.endingDate,
          fromDate: data.startDate,
          toDate: data.endDate
        };
      } catch (error) {
        console.log("getIndicesComparisonData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default IndicesComparisonActions;
