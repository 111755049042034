import React from "react";

import { Loader, LoaderContainer } from "./styles";

const FullScreenLoader = () => {
  return (
    <LoaderContainer>
      <Loader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Loader>
    </LoaderContainer>
  );
};

export default FullScreenLoader;
