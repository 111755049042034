import ApiLastDate from "../../../integration/api/ApiHome";

class LastUpdateDateActions {
  static getLastUpdateDate = async () => {
    try {
      const res = await ApiLastDate.getLastUpdateDate();

      return res.data;
    } catch (error) {
      console.log("getDate Error: ", error);
    }
  }
}

export default LastUpdateDateActions;
