import { FilterData } from "common/indicesPerformanceSummary/store/models";
import Api from "../Api";

class ApiIndicesPerformanceSummary extends Api {
  static getIndicesPerformanceSummary(metric: string, endingDate: string) {
    return ApiIndicesPerformanceSummary.instance.axiosInst.get<{
      data: string;
    }>("/stock/index-performance-summary", {
      params: {
        metric,
        endingDate,
      },
    });
  }

  static getIndicesPerformanceSummaryFilters() {
    return ApiIndicesPerformanceSummary.instance.axiosInst.get<FilterData>(
      "/stock/index-performance-summary/filters"
    );
  }
}

export default ApiIndicesPerformanceSummary;
