import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "application/store";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import ApiDividend from "integration/api/ApiTableData";

class ExpectedStockActions {
  static getExpectedStockTableData = createAsyncThunk(
    `expect-stock/${ResponseHandlerName.GetExpectedStockTableData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).expectStock;
      const dividendYield = filters.selectedDividend.code;
      const earningGrowth = filters.selectedEarnings.code;
      const valuationAdjustment = filters.selectedValuation.code;
      try {
        const res = await ApiDividend.getTableData({dividendYield, earningGrowth, valuationAdjustment});
        return res.data;
      } catch (error) {
        console.log("getExpectedStockTableData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default ExpectedStockActions;
