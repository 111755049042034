import React from "react";
import moment from "moment";
import {
  VictoryAxis,
  VictoryLine,
  VictoryChart,
  VictoryTooltip,
  VictoryScatter,
  VictoryVoronoiContainer,
} from "victory";

import { ChartHeight, ChartWidth, SpecificMetricCodes } from "./constants";
import { ChartLineDataItem, ChartProps, ChartState } from "./types";
import { numberFormatter } from "application/helpers/numberFormatter";
import { applicationConfig } from "application/config/applicationConfig";

import ChartHeader from "./components/ChartHeader";
import MSCIInfoPopUp from "./components/MSCIInfoPopUp";
import VictoryAverage from "./components/VictoryAverage";
import ItemDescription from "./components/ItemDescription";
import SmallLoader from "application/components/SmallLoader";
import VictoryCustomTooltip from "features/chart/components/VictoryCustomTooltip";

import {
  Loader,
  BottomLogo,
  NoDataText,
  chartStyles,
  ChartWrapper,
  TipsContainer,
  ChartSmallText,
  ChartLongText,
  BottomContainer,
  NoDataContainer,
  AverageContainer,
  EarningContainer,
  LogoWrapper,
  MetricValueRange,
  LogosWrapper,
  BottomMfruLogo,
  SpecificMetricSign,
  SpecificMetricContainer,
} from "./styles";
import { colors } from "application/styles/colors";
import logoBlueSource from "application/assets/images/logo-blue.svg";
import MSCIInfo from "../../application/components/MSCIInfo";
import mfruLogoSource from "../../application/assets/images/mfru-logo.svg";
import { Box } from "@mui/system";
import BloombergInfo from "application/components/BloombergInfo";
import BloombergInfoPopUp from "./components/BloombergInfoPopUp";

class Chart extends React.Component<ChartProps, ChartState> {
  state = {
    IsMSCIVisible: false,
    isBloombergVisible: false,
  };

  toggleMSCIVisible = () => {
    this.setState({ IsMSCIVisible: !this.state.IsMSCIVisible });
  };

  toggleBloombergVisible = () => {
    this.setState({ isBloombergVisible: !this.state.isBloombergVisible });
  };

  mainYTickLabels = (x: number) => {
    const isPercent = this.props.indicesData.unitOfMeasurement === "PERCENTAGE";
    if (isPercent) {
      return `${numberFormatter(x)}%`;
    } else if (this.props.numberOfDecimalsYLabels) {
      const fractionalDigitsNumber = this.props.numberOfDecimalsYLabels;
      return Number(x).toLocaleString(undefined, {
        minimumFractionDigits: fractionalDigitsNumber,
        maximumFractionDigits: fractionalDigitsNumber,
      });
    } else if (this.props.withNumberFormater) {
      return Number(x).toLocaleString();
    } else {
      return numberFormatter(x);
    }
  };

  standardDeviationTickLabel = (x: number) => {
    const deviationLevel = this.props.indicesData.standardDeviationLevels?.find(
      (item) => item.value === x
    );
    return `${deviationLevel?.level || 0} SD`;
  };

  handleMainPage = () => {
    window.open(applicationConfig.assetcamp, "_blank");
  };

  render() {
    const { IsMSCIVisible } = this.state;
    const { indicesData, filters, loading, isBloomberg = false } = this.props;
    const { axisStyle, yLeftAxisStyle, yRightAxisStyle } = chartStyles;
    const isPercent = indicesData.unitOfMeasurement === "PERCENTAGE";
    let itemsCount = 0;
    const specificMetric = SpecificMetricCodes.includes(
      filters.selectedMetric.code
    );

    const data = indicesData.data.map((item, index) => {
      let val = item.value;

      if (
        indicesData.metricValueRange &&
        (item.value > indicesData.metricValueRange ||
          item.value < -indicesData.metricValueRange)
      ) {
        if (item.value < -indicesData.metricValueRange) {
          itemsCount++;
          val = -indicesData.metricValueRange;
        } else if (item.value > indicesData.metricValueRange) {
          itemsCount++;
          val = indicesData.metricValueRange;
        }
      }

      const haveMonthMovingAvg =
        indicesData.movingAverageData?.length > 0 &&
        index < indicesData.movingAverageData?.length;

      return {
        b: val,
        c: item.value,
        sd: item.valueInSd,
        a: moment(item.date, "YYYY-MM-DD").toDate(),
        m: haveMonthMovingAvg
          ? indicesData.movingAverageData[index].value
          : null,
      };
    }) as ChartLineDataItem[];

    const difference = SpecificMetricCodes.includes(
      filters.selectedMetric.code
    );

    const rightAxisData = indicesData.standardDeviationLevels?.map((item) => {
      if (indicesData.metricValueRange && indicesData.standardDeviationLevels) {
        if (item.value > 0) {
          if (item.value <= indicesData.metricValueRange) {
            return item.value;
          } else {
            return null;
          }
        } else {
          if (item.value >= -indicesData.metricValueRange) {
            return item.value;
          } else {
            return null;
          }
        }
      } else {
        return item.value;
      }
    });

    if (!data.length && !loading) {
      return (
        <ChartWrapper>
          <NoDataContainer>
            <NoDataText>
              This metric is not available due to a lack of data
            </NoDataText>
          </NoDataContainer>
        </ChartWrapper>
      );
    }

    return (
      <div>
        <ChartWrapper className="chart-wrapper">
          {loading && (
            <Loader>
              <SmallLoader />
            </Loader>
          )}

          <ChartHeader
            data={data}
            filters={filters}
            isPercent={isPercent}
            withNumberFormater={this.props.withNumberFormater}
            doNotShowDecimals={!!this.props.doNotShowDecimals}
          />

          <VictoryChart
            width={ChartWidth}
            height={this.props.adjustedChartHeight ?? ChartHeight}
            padding={{
              top: 25,
              right: 50,
              left: this.props.paddingLeft ?? 50,
              bottom: 54,
            }}
            containerComponent={
              <VictoryVoronoiContainer
                voronoiDimension="x"
                labels={() => " "}
                voronoiBlacklist={["average-line"]}
                labelComponent={
                  <VictoryTooltip
                    flyoutComponent={
                      <VictoryCustomTooltip
                        withNumberFormater={this.props.withNumberFormater}
                        isPercent={isPercent}
                        difference={difference}
                        doNotShowDecimals={this.props.doNotShowDecimals}
                      />
                    }
                  />
                }
              />
            }
          >
            {/* Main Y */}
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              tickCount={10}
              style={yLeftAxisStyle}
              tickFormat={this.mainYTickLabels}
            />

            {/* Main Axis */}
            <VictoryAxis
              offsetY={35}
              tickCount={10}
              style={axisStyle}
              tickFormat={(y) => moment(y).format("YYYY")}
            />

            {/* Standard deviation levels Axis */}
            {!!this.props.indicesData.standardDeviationLevels?.length && (
              <VictoryAxis
                dependentAxis
                offsetX={590}
                style={yRightAxisStyle}
                tickValues={rightAxisData}
                tickFormat={this.standardDeviationTickLabel}
              />
            )}

            {/* Main line */}
            <VictoryLine
              x="a"
              y="b"
              data={data}
              style={{
                data: { stroke: colors.blue_400, strokeWidth: 1 },
              }}
            />

            {/* Month moving average line */}
            {this.props.indicesData.movingAverageData?.length > 0 && (
              <VictoryLine
                x="a"
                y="m"
                data={data}
                style={{
                  data: { stroke: colors.brown_hover, strokeWidth: 1 },
                }}
              />
            )}

            {/* Active month moving avg point circle */}
            {!!data.length &&
              this.props.indicesData.movingAverageData?.length > 0 && (
                <VictoryScatter
                  x="a"
                  y="m"
                  data={data}
                  style={chartStyles.movingAvgScatterStyle}
                  size={({ active }) => (active ? 3 : 0)}
                />
              )}

            {/* Active point circle */}
            {!!data.length && (
              <VictoryScatter
                x="a"
                y="b"
                data={data}
                style={chartStyles.scatterStyle}
                size={({ active }) => (active ? 3 : 0)}
              />
            )}

            {/* Average line */}
            {!!indicesData.average && (
              <VictoryLine
                x="a"
                data={data}
                labels={[""]}
                name="average-line"
                y={() => indicesData.average || 0}
                labelComponent={
                  <VictoryAverage
                    isPercent={isPercent}
                    value={indicesData.average}
                    averageWidth={this.props.averageWidth}
                    withNumberFormater={this.props.withNumberFormater}
                    doNotShowDecimals={this.props.doNotShowDecimals}
                  />
                }
                style={{
                  data: { stroke: colors.brown_hover, strokeWidth: 1 },
                }}
              />
            )}
          </VictoryChart>
          {indicesData.metricValueRange && itemsCount > 0 && (
            <MetricValueRange>
              <p>
                Values capped at +/- {indicesData.metricValueRange}
                {indicesData.unitOfMeasurement === "PERCENTAGE" && "%"} for
                charting purposes
              </p>
            </MetricValueRange>
          )}

          <BottomContainer>
            <LogoWrapper>
              {isBloomberg ? (
                <BloombergInfo
                  isBloombergVisible={this.state.isBloombergVisible}
                  underTable
                  onChange={this.toggleBloombergVisible}
                />
              ) : (
                <MSCIInfo
                  isMSCIVisible={this.state.IsMSCIVisible}
                  onChange={this.toggleMSCIVisible}
                />
              )}

              {this.props.isTablet && (
                <LogosWrapper>
                  <BottomMfruLogo
                    src={mfruLogoSource}
                    alt="logo"
                    onClick={() => {
                      window.open(applicationConfig.main_page, "_blank");
                    }}
                  />
                  <BottomLogo
                    src={logoBlueSource}
                    alt="logo"
                    onClick={this.handleMainPage}
                  />
                </LogosWrapper>
              )}
            </LogoWrapper>

            <TipsContainer>
              {!!indicesData.average && (
                <AverageContainer>
                  <ChartSmallText>Average</ChartSmallText>
                </AverageContainer>
              )}
              {specificMetric && !indicesData.average ? (
                <SpecificMetricContainer>
                  <Box>
                    <SpecificMetricSign />
                  </Box>
                  <ChartSmallText>{filters.selectedMetric.name}</ChartSmallText>
                </SpecificMetricContainer>
              ) : (
                <></>
              )}

              {(!!specificMetric || filters.selectedMetric.name) && (
                <EarningContainer>
                  {filters.selectedMetric.name.includes('minus') ? (
                    <ChartLongText>
                      <span>{filters.selectedMetric.name.split('minus')[0]}</span>
                      <span>minus</span>
                      <span>{filters.selectedMetric.name.split('minus')[1]}</span>
                    </ChartLongText>
                  ) : (
                    <ChartSmallText>
                      {specificMetric
                        ? `Index Price - ${
                          filters.selectedMetric.code.includes("local")
                            ? "Local Currency"
                            : "USD"
                        }`
                        : filters.selectedMetric.name ?? ""}
                    </ChartSmallText>
                  )}
                </EarningContainer>
              )}
            </TipsContainer>

            {!this.props.isTablet && (
              <LogosWrapper>
                <BottomMfruLogo
                  src={mfruLogoSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.main_page, "_blank");
                  }}
                />
                <BottomLogo
                  src={logoBlueSource}
                  alt="logo"
                  onClick={this.handleMainPage}
                />
              </LogosWrapper>
            )}
          </BottomContainer>
        </ChartWrapper>

        <ItemDescription
          title={filters.selectedMetric.name || "About metric name"}
          description={filters.selectedMetric.description || ""}
        />
        <ItemDescription
          title={filters.selectedIndices.name || "About index name"}
          description={filters.selectedIndices.description || ""}
        />

        {IsMSCIVisible ? (
          isBloomberg ? (
            <BloombergInfoPopUp onClose={this.toggleMSCIVisible} />
          ) : (
            <MSCIInfoPopUp onClose={this.toggleMSCIVisible} />
          )
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default Chart;
