import styled from "styled-components";
import { colors } from "../../application/styles/colors";

const mediaSterp_1 = 1160;
const mediaStep_2 = 647;

export const BondIndexStyled = styled.div`
  display: flex;
  width: 1140px;
  padding-top: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: ${mediaSterp_1}px) {
    width: 100%;
  }
`;

export const ChartImage = styled.img`
  display: flex;
  width: 600px;
  padding: 8px 7px 0px 7px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: ${mediaStep_2}px) {
    width: 320px;
  }
`;

export const BondIndexText = styled.p`
  color: ${colors.black};
  font-family: Times;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
`;
