export interface GetUserInfoResponse {
  userId: string;
  userStatus: UserStatusEnum;
}

export enum UserStatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export interface GetCustomerPortalLinkResponse {
  customerPortalLink: string;
}
