import React, { useEffect, useRef, useState } from "react";
import ExpectedReturnsModelingActions from "./store/actions";
import { SelectedFilter } from "./store/model";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  homeSelector,
  setCorporateDefaultRateFilter,
  setCreditSpreadChangeFilter,
  setDefaultRecoveryRateFilter,
  setInterestRateChangeFilter,
  setTimePeriodFilter,
} from "./store/slice";
import DropGroup from "application/components/DropGroup";
import {
  Title,
  Container,
  DropContainer,
  FiltersContainer,
  TitleWrapper,
  BottomLogo,
  UnderContentInfo,
  BottomMfruLogo,
  LogoWrapper,
  TableInfoWrapper,
  CompareButton,
  ArrowBackContainer,
  ArrowBack,
} from "./styles";
import { Loader } from "../../features/chart/styles";
import SmallLoader from "../../application/components/SmallLoader";
import BondExpectedReturnsModelingTable from "../../application/components/BondExpectedReturnsModelingTable";
import MainContainer from "../../application/components/MainContainer";
import {
  Data,
  NoData,
} from "../../application/components/BondExpectedReturnsModelingTable/types";
import { find, propEq } from "ramda";
import logoBlueSource from "../../application/assets/images/logo-blue.svg";
import { applicationConfig } from "../../application/config/applicationConfig";
import ReportLastUpdateAction from "../home/actions/ReportLastUpdateAction";
import LastUpdateDate from "../../application/components/LastUpdateDate";
import NotifyPopUpTable from "../../application/components/NotifyPopUpTable";
import { useMediaQuery, useTheme } from "@mui/material";
import mfruLogoSource from "../../application/assets/images/mfru-logo.svg";
import BloombergInfo from "application/components/BloombergInfo";

const BondExpectedReturnsModeling = () => {
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [compare, setCompare] = React.useState<boolean>(false);
  const [isBloombergVisible, setIsBloombergVisible] =
    React.useState<boolean>(false);
  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);
  const [date, setDate] = useState<string>("");
  const buttonSectionRef = useRef<HTMLDivElement>(null);
  const [buttonSectionHeight, setButtonSectionHeight] = useState<number>(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const { filters, selectedFilterValues, tableData } =
    useAppSelector(homeSelector);
  const loading =
    requestData.getExpectedReturnsModelingTableData.loading ||
    requestData.getExpectedReturnsModelingFilters.loading;

  useEffect(() => {
    dispatch(
      ExpectedReturnsModelingActions.getExpectedReturnsModelingFilters()
    );

    if (!date.length) {
      Promise.all([ReportLastUpdateAction.getLastUpdateDate()]).then((data) => {
        setDate(data[0]?.lastUpdatedDate || "");
      });
    }

    if (buttonSectionRef.current) {
      setButtonSectionHeight(buttonSectionRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    //if at least one filter has selected code then all others are initialized with default values
    if (selectedFilterValues.corporateDefaultRate.code.length > 0) {
      dispatch(
        ExpectedReturnsModelingActions.getExpectedReturnsModelingTableData()
      );
    }
  }, [selectedFilterValues]);

  useEffect(() => {
    let arr: Data[] = [];

    selectedCompare.map((item: Data) => {
      // @ts-ignore
      let dataItem: Data | NoData = find(propEq("indexName", item.indexName))(
        tableData
      );

      if (!dataItem) {
        dataItem = {
          ...item,
          noData: true,
        };
      }

      return (arr = [...arr, dataItem]);
    });

    setSelectedCompare(arr);
  }, [tableData]);

  const handleSelectTimePeriod = (item: SelectedFilter) => {
    dispatch(setTimePeriodFilter(item));
  };
  const handleSelectInterestRate = (item: SelectedFilter) => {
    dispatch(setInterestRateChangeFilter(item));
  };
  const handleSelectSpreadChange = (item: SelectedFilter) => {
    dispatch(setCreditSpreadChangeFilter(item));
  };
  const handleSelectDefaultRate = (item: SelectedFilter) => {
    dispatch(setCorporateDefaultRateFilter(item));
  };
  const handleSelectRecoveryRate = (item: SelectedFilter) => {
    dispatch(setDefaultRecoveryRateFilter(item));
  };

  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const compareLines = () => {
    setCompare(!compare);
  };

  const toggleBloombergisible = () => {
    setIsBloombergVisible(!isBloombergVisible);
  };

  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  function capitalizeYear(str: string) {
    return str.replace(/\b(year)\b/i, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1);
    });
  }

  function getNumberFromString(str: string) {
    const match = str.match(/\d+/);
    return match ? parseInt(match[0], 10) : null;
  }

  function getAnnualized(str: string) {
    const numberOfYears = getNumberFromString(str);
    return numberOfYears && numberOfYears > 1 ? "Annualized" : "";
  }

  const headCells: any[] = [
    {
      id: "indexName",
      label: "Index",
      diffBackground: false,
    },
    {
      id: "beginningYtm",
      label: "Starting Yield-to-Maturity",
      diffBackground: false,
      tooltip:
        "The base expected annualized return before accounting for interest rate changes or defaults. This measure uses the most recently available yield-to-worst metric. Yield-to-worst reflects the impact of bonds that are expected to be called or redeemed early.",
    },
    {
      id: "absolutePriceChange",
      label: "Absolute Price Change",
      diffBackground: false,
      tooltip:
        "The one-time price change, either a gain or a loss, resulting from an increase or decrease in interest rates or credit spreads.",
    },
    {
      id: "interestRateChangeAnnualImpact",
      label: "Interest Rate Change Annual Impact",
      diffBackground: false,
      tooltip:
        "The annual return impact caused by changes in interest rates.",
    },
    {
      id: "creditSpreadChangeAnnualImpact",
      label: "Credit Spread Change Annual Impact",
      diffBackground: false,
      tooltip:
        "The annual return impact due to changes in credit spreads, reflecting adjustments to the incremental yield from credit risk.",
    },
    {
      id: "corporateDefaultAnnualImpact",
      label: "Corporate Default Annual Impact",
      diffBackground: false,
      tooltip:
        "The annual return impact of defaults on corporate and emerging markets bonds, after accounting for recovery rates.",
    },
    {
      id: "yearExpectedAnnualizedNominalReturn",
      label: `${capitalizeYear(
        selectedFilterValues.timePeriod.name
      )} Expected ${getAnnualized(
        selectedFilterValues.timePeriod.name
      )} Nominal Return`,
      diffBackground: true,
      tooltip:
        "The total expected return, or compound return, for the index, factoring in the starting yield-to-maturity, annual defaults, and one-time changes in interest rates and spreads.",
    },
  ];

  return (
    <MainContainer>
      <Container>
        <TitleWrapper>
          <Title>Bond Market Expected Returns Modeling</Title>
          <LastUpdateDate date={date} showCurrency={false} />
        </TitleWrapper>
        <FiltersContainer>
          <DropContainer>
            <DropGroup
              data={{ timePeriod: filters.timePeriod.filterValues }}
              label="Time Period"
              disabled={loading}
              onSelect={handleSelectTimePeriod}
              selected={selectedFilterValues.timePeriod}
              placeholder="Time Period name goes here"
              tooltip="The duration of the expected return forecast."
            />
            <DropGroup
              data={{
                interestRateChange: filters.interestRateChange.filterValues,
              }}
              label="Interest Rate Change"
              disabled={loading}
              onSelect={handleSelectInterestRate}
              selected={selectedFilterValues.interestRateChange}
              placeholder="Interest Rate Change name goes here"
              tooltip="The one-time percentage increase or decrease in overall market interest rates."
            />
            <DropGroup
              data={{ spreadChange: filters.creditSpreadChange.filterValues }}
              label="Spread Change"
              disabled={loading}
              onSelect={handleSelectSpreadChange}
              selected={selectedFilterValues.creditSpreadChange}
              placeholder="Spread Change name goes here"
              tooltip="The one-time percentage increase or decrease in yield spreads, reflecting changes in credit risk premiums."
            />
            <DropGroup
              data={{
                corporateDefaultRate: filters.corporateDefaultRate.filterValues,
              }}
              label="Corporate Default Rate"
              disabled={loading}
              onSelect={handleSelectDefaultRate}
              selected={selectedFilterValues.corporateDefaultRate}
              placeholder="Corporate Default Rate name goes here"
              tooltip="The annual percentage of corporate and emerging markets bonds that default."
            />
            <DropGroup
              data={{
                defaultRecoveryRate: filters.defaultRecoveryRate.filterValues,
              }}
              label="Default Recovery Rate"
              disabled={loading}
              onSelect={handleSelectRecoveryRate}
              selected={selectedFilterValues.defaultRecoveryRate}
              placeholder="Default Recovery Rate name goes here"
              tooltip="The percentage of principal recovered annually in the event of bond defaults. The yearly default impact is calculated as the corporate default rate multiplied by (1 - the default recovery rate)."
            />
          </DropContainer>
        </FiltersContainer>
        {loading && (
          <Loader>
            <SmallLoader />
          </Loader>
        )}
        {!loading && (
          <div>
            <TableInfoWrapper ref={buttonSectionRef} compare={compare}>
              {!compare && (
                <CompareButton
                  onClick={compareLines}
                  disabled={selectedCount < 2}
                >
                  COMPARE {isMobile ? "" : " INDEXES"}
                </CompareButton>
              )}
              {compare && (
                <>
                  <CompareButton compare={compare} onClick={compareLines}>
                    back to full list
                  </CompareButton>
                  <ArrowBackContainer onClick={compareLines}>
                    <ArrowBack />
                  </ArrowBackContainer>
                </>
              )}
            </TableInfoWrapper>

            {tableData && (
              <BondExpectedReturnsModelingTable
                expectedReturnsModelingData={tableData}
                showButton={showButton}
                compareLines={compare}
                headCells={headCells}
                onSelectedCompare={handleSelectedCompare}
                selectedCompare={selectedCompare}
                buttonSection={buttonSectionHeight}
              />
            )}
            <UnderContentInfo>
              <BloombergInfo
                isBloombergVisible={isBloombergVisible}
                underTable
                onChange={toggleBloombergisible}
              />
              <LogoWrapper>
                <BottomMfruLogo
                  src={mfruLogoSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.main_page, "_blank");
                  }}
                />
                <BottomLogo
                  src={logoBlueSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.assetcamp, "_blank");
                  }}
                />
              </LogoWrapper>
            </UnderContentInfo>
          </div>
        )}
      </Container>
      {isTablet && <NotifyPopUpTable />}
    </MainContainer>
  );
};

export default BondExpectedReturnsModeling;
