import styled from "styled-components";

import { colors } from "application/styles/colors";

export const PaymentContent = styled.div`
  max-width: 742px;
  padding: 0 16px;

  @media only screen and (max-width: 674px) {
    width: calc(100% - 32px);
  }
`;

export const PaymentContainer = styled.div`
  display: flex;

  form {
    flex: 1;
  }

  @media only screen and (max-width: 674px) {
    flex-direction: column;
  }
`;

export const PaymentPlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-right: 24px;
  border: 1px solid ${colors.grey_400};
  width: 230px;

  @media only screen and (max-width: 674px) {
    width: auto;
    margin-right: 0;
    margin-bottom: 16px;
    padding: 12px;
  }
`;

export const PaymentPlanHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media only screen and (max-width: 674px) {
    margin-bottom: 4px;
  }
`;

export const PaymentPlanTitle = styled.h6`
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid ${colors.stroke_grey};
  /* font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    border: none;
    padding-bottom: 0;
    margin-bottom: 8px;
  }
`;

export const PaymentPlanName = styled.h5`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.font_grey};
`;

export const PaymentPlanPrice = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.font_grey};
`;

export const PaymentPlanDescription = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 12px;
  /* font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    display: none;
  }

  li {
    margin-bottom: 8px;
  }

  li:last-child {
    margin-bottom: 0;
  }
`;

export const PaymentTotal = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.stroke_grey};
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding: 16px 0;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    padding-bottom: 0;
  }
`;

export const PaymentError = styled.p`
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: ${colors.red};
  font-family: "Helvetica";
`;
