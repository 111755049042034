import { LargeTitle } from "application/styles/text";
import styled from "styled-components";
import { colors } from "../../application/styles/colors";
import { ReactComponent as ArrowBackIcon } from "../../application/assets/images/icons/arrow-back.svg";

interface Props {
  compare?: boolean;
}

const mediaStep_2 = 1023;
const mediaStep_3 = 940;
const mediaStep_4 = 760;
const mediaStep_5 = 647;

export const Container = styled.div`
  position: relative;
  min-height: 101vh;

  @media only screen and (max-width: ${mediaStep_2}px) {
    min-height: unset;
    padding: 0 16px;
  }
`;

export const TitleWrapper = styled.div`
  margin: 52px 0 24px 0;

  @media only screen and (max-width: ${mediaStep_2}px) {
    margin: 0;
  }
`;

export const Title = styled(LargeTitle)`
  font-family: "Time";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  text-align: left;

  @media (max-width: ${mediaStep_2}px) {
    font-family: "Helvetica";
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 0;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  margin: 32px 0 24px 0;

  @media (max-width: ${mediaStep_2}px) {
    justify-content: center;
    margin: 24px 0 16px;
  }
`;

export const DropContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  justify-content: space-between;

  .drop-group-container {
    width: 170px;
  }

  @media (max-width: ${mediaStep_2}px) {
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;

    > div {
      display: block;
      flex-grow: 1;
      margin-left: 0;
      width: 100%;
    }

    .drop-group-container {
      width: 230px;
    }
  }

  @media (max-width: ${mediaStep_5}px) {
    display: flex;
    flex-direction: column;
    > div {
      margin-left: 0 !important;
      width: 100%;
    }
  }

  .drop-group-selected {
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      color: ${colors.black};
    }
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    .drop-group-selected {
      height: 44px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .drop-group-label {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 4px 4px;
    }
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    .drop-group-selected {
      p {
        font-size: 14px;
      }
    }

    .drop-group-label {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media only screen and (max-width: ${mediaStep_4}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: ${mediaStep_5}px) {
    width: 100%;
    grid-column: span 4;
    .drop-group-wrapper {
      width: 100%;
      grid-column: span 4;
    }
  }
`;

export const CompareButton = styled.button<Props>`
  width: auto;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: ${colors.ochre};
  color: ${colors.white};
  border: none;
  padding: 14px 16px;
  cursor: pointer;
  text-transform: uppercase;

  &[disabled] {
    background: ${colors.grey_500};
  }

  @media (max-width: ${mediaStep_5}px) {
    display: ${({ compare }) => (compare ? "none" : "unset")};
  }
`;

export const ArrowBackContainer = styled.div`
  display: none;
  background: ${colors.ochre};

  @media (max-width: ${mediaStep_5}px) {
    display: block;
  }
`;

export const ArrowBack = styled(ArrowBackIcon)`
  padding: 10px;
  stroke: ${colors.font_grey};
`;

export const TableInfo = styled.p`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
`;

export const TableInfoWrapper = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;

  @media (max-width: ${mediaStep_2}px) {
    margin: 16px 0;
    align-items: flex-end;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 16px);
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background: #fff;
    }
  }

  @media (max-width: ${mediaStep_5}px) {
    flex-direction: ${({ compare }) => (compare ? "row-reverse" : "row")};
  }
`;

export const UnderContentInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${mediaStep_5}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-end;

  @media (max-width: ${mediaStep_5}px) {
    margin-top: 12px;
  }
`;

export const BottomMfruLogo = styled.img`
  width: 95px;
  height: 38px;
  cursor: pointer;
  margin-right: 20px;

  @media (max-width: ${mediaStep_2}px) {
    width: 75px;
    height: 30px;
    margin-left: 16px;
  }

  @media (max-width: ${mediaStep_5}px) {
    margin-left: 8px;
  }
`;

export const BottomLogo = styled.img`
  width: 92px;
  height: 32px;
  cursor: pointer;

  @media (max-width: ${mediaStep_2}px) {
    width: 74px;
    height: 26px;
    margin-left: 16px;
  }

  @media (max-width: ${mediaStep_5}px) {
    margin-left: 8px;
  }
`;
