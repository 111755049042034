export const ChartWidth = 600;
export const ChartHeight = 340;

export const ToolTipHeight = 72;
export const ToolTipWidth = 130;
export const ToolTipBigWidth = 150;
export const ToolTipHeightWithMovingAvg = 90;

export const ScatterDataSize = 10;

export const SpecificMetricCodes = [
  "three_months_price_avg",
  "ten_months_price_avg",
  "three_months_price_avg_local",
  "ten_months_price_avg_local",
];
