import React from "react";
import { MSCIBloombergIWrapper, Text, InfoIcon, MSCIBloombergDescription, MSCIBloombergInfoContainer } from "./styles";
import MSCIBloombergInfoPopUp from "../../../features/chart/components/MSCIBloombergInfoPopUp";

type Props = {
  isMSCIVisible: boolean;
  onChange: any;
  underTable?: boolean
}

const MSCIBloombegInfo = ({isMSCIVisible, onChange, underTable}: Props) => {
  return (
    <MSCIBloombergIWrapper underTable={underTable}>
      <MSCIBloombergInfoContainer onClick={onChange}>
        <Text>Sourced or calculated from MSCI and Bloomberg Index Services Limited data</Text>
        <InfoIcon />
      </MSCIBloombergInfoContainer>
      <MSCIBloombergDescription underTable={underTable}>
        This information is for internal use only
        {!underTable && <br />} and may not be reproduced or shared in any form
      </MSCIBloombergDescription>
      {isMSCIVisible && <MSCIBloombergInfoPopUp onClose={onChange} />}
    </MSCIBloombergIWrapper>
  );
};

export default MSCIBloombegInfo;
