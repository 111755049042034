import styled from "styled-components";
import { colors } from "application/styles/colors";

const mediaStep_1 = 1160;
const mediaStep_2 = 940;
const mediaStep_3 = 760;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: scroll;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 1140px;
  max-height: calc(100vh - 20px);
  background: ${colors.white};
  overflow: auto;

  @media only screen and (max-width: ${mediaStep_1}px) {
    width: 100%;
    padding: 16px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    height: fit-content;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 760px;
  flex-shrink: 0;
  border: 1px solid ${colors.grey_400};

  .item-description {
    display: none;
  }

  .chart-wrapper {
    border: none;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    height: fit-content;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 614px;
  margin-left: auto;
  gap: 24px;
  padding: 32px 33px;
  p {
    text-align: center;
    font-family: "Helvetica";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    margin: auto;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const CloseButton = styled.button`
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border: 1px solid ${colors.grey_400};
  cursor: pointer;
  p {
    line-height: 36px;
    color: ${colors.oxford_blue};
  }
`;

export const SeeFullChartButton = styled.button`
  display: flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  background: ${colors.ochre};
  cursor: pointer;
  p {
    line-height: 36px;
    color: ${colors.white};
  }
`;
