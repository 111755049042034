import styled from "styled-components";
import { Button } from "@mui/material";

import { colors } from "application/styles/colors";
import { ReactComponent as ArrowIcon } from "application/assets/images/icons/arrow-white.svg";

interface StyledProps {
  isBoldText?: boolean;
}

export const PlanContainer = styled.div`
  padding: 32px 32px 52px 32px;
  min-height: 349px;
  box-sizing: border-box;
  border: 1px solid ${colors.grey_400};

  @media only screen and (max-width: 1023px) {
    padding: 16px;
  }

  @media only screen and (max-width: 674px) {
    border: none;
    padding: 0;
  }
`;

export const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlanHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

export const PlanTitle = styled.h6`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black};
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 4px;
  margin-bottom: 12px;
  border-bottom: 1px solid ${colors.stroke_grey};
`;

export const PlanPriceDescriptionContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const PlanPrice = styled.h5`
  margin-right: 9px;
  /* font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
  color: ${colors.ochre};

  @media only screen and (max-width: 674px) {
    line-height: 40px;
  }
`;

export const PlanPriceOld = styled.h5`
  /* font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: ${colors.grey_500};
  margin-right: 12px;
  text-decoration: line-through;

  //@media only screen and (max-width: 674px) {
  //  line-height: 40px;
  //}
`;

export const PlanPriceDescription = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    line-height: 20px;
  }
`;

export const PlanDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

export const PlanArrowIcon = styled(ArrowIcon)`
  stroke: ${colors.ochre};
  min-width: 24px;
  min-height: 24px;

  @media only screen and (max-width: 674px) {
    display: none;
  }
`;

export const PlanDescription = styled.p<StyledProps>`
  margin-left: 8px;
  /* font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: ${({ isBoldText: isBold }) => (isBold ? 600 : 300)};
  font-size: 16px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    font-size: 14px;
    margin-left: unset;
  }
`;

export const CouponContainer = styled.div`
  margin-top: 24px;
  position: relative;
`;

export const CouponInput = styled.input`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.grey_500};
  padding: 12px;
  background: ${colors.stripe_grey_light};
  border: none;

  input {
    padding: 0;
  }

  @media only screen and (max-width: 674px) {
    flex-grow: 1;
  }
`;

export const CouponInputLabel = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.font_grey};
`;

export const CouponApplyBtn = styled(Button)`
  font-family: "Helvetica" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: ${colors.oxford_blue} !important;
  padding: 14px 30px !important;
  border: 1px solid ${colors.grey_400} !important;
  border-radius: 0 !important;

  &[disabled] {
    opacity: 0.5;
  }
`;

export const PromoCodeInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const DiscountPlanPrice = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 674px) {
    margin-top: 8px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FreeTrialDetailsTitle = styled.p`
  margin-top: 20px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    font-size: 14px;
  }
`;

export const FreeTrialDetailsList = styled.ul`
  margin-top: 12px;
  padding-left: 16px;
  list-style-type: disc;
  list-style-position: outside;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.font_grey};

  @media only screen and (max-width: 674px) {
    font-size: 14px;
  }

  li {
    margin-bottom: 12px;
  }

  li:last-child {
    margin-bottom: 0;
  }

  span {
    text-decoration: underline;
  }
`;
