import Api from "../Api";

class ApiIndices extends Api {
  static getIndices() {
    return ApiIndices.instance.axiosInst.get<{ data: string }>("/indices");
  }

  static getIndicesData(index: string, metric: string) {
    return ApiIndices.instance.axiosInst.get<{ data: string }>(
      "/indices/data",
      { params: { index, metric } }
    );
  }
}

export default ApiIndices;
