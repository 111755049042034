import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import { FilterDataItem, StockBondYieldComparison } from "./model";
import StockBondYieldSummary from "./actions";
import { BondIndicesData } from "common/bondValuationSummary/store/model";

const initialIndicesData: BondIndicesData = {
  average: 0,
  data: [],
  movingAverageData: [],
  unitOfMeasurement: "ABSOLUTE",
  standardDeviationLevels: [],
}

const initialState: StockBondYieldComparison = {
  metrics: {},
  tableData: {},
  filters: {
    selectedMetric: { code: "", name: "" },
    selectedIndices: { code: "", name: "" },
  },
  indicesData: initialIndicesData,
  chartsData: {},
};

export const stockBondYieldComparisonSlice = createSlice({
  name: "stock-bond-yield-summary",
  initialState,
  reducers: {
    setStockBondYieldFilterMetric: (
      state,
      action: PayloadAction<FilterDataItem>
    ) => {
      state.filters.selectedMetric = action.payload;
    },
    setStockBondYieldFilterIdices: (
      state,
      action: PayloadAction<FilterDataItem>
    ) => {
      state.filters.selectedIndices = action.payload;
    },
    resetBondIndiciesData: (state) => {
      state.indicesData = initialIndicesData;
    },
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    // getTableData
    builder.addCase(
      StockBondYieldSummary.getValuationSummaryTableData.fulfilled,
      (state, action) => {
        state.tableData = action.payload;
      }
    );
    builder.addCase(StockBondYieldSummary.getMetrics.fulfilled, (state, action) => {
      state.metrics = action.payload;
    });
    builder.addCase(StockBondYieldSummary.getCharts.fulfilled, (state, action) => {
      state.indicesData = action.payload;
    });
  },
});

export default stockBondYieldComparisonSlice.reducer;
export const stockBondYieldSummarySelector = () => store.getState().stockBondYieldComparison;
export const { setStockBondYieldFilterMetric, setStockBondYieldFilterIdices, reset, resetBondIndiciesData } =
stockBondYieldComparisonSlice.actions;
