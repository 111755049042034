import { colors } from "application/styles/colors";
import styled from "styled-components";

import { AuthTitle } from "../styles";

export const ResetTitle = styled(AuthTitle)`
  margin-bottom: 24px;
`;

export const ResetDescription = styled.p`
  margin-bottom: 32px;
  /* Font-style */
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  text-align: center;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;

export const ResendText = styled(ResetDescription)`
  margin-bottom: 0;
  text-align: left;

  @media only screen and (max-width: 674px) {
    font-size: 12px;
  }
`;

export const NoCodeContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: 40px;
  p:first-child {
    margin-right: 8px;
  }

  @media only screen and (max-width: 1023px) {
    margin-bottom: 60px;
  }
`;
