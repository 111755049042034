import React, { FC, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { authSelector } from "common";
import { authRouts, publicRouts } from "./routes";
import AuthActions from "common/auth/store/actions";
import NotifyPopUp from "application/components/NotifyPopUp";
import { useAppDispatch, useAppSelector } from "application/store";
import FullScreenLoader from "application/components/FullScreenLoader";

export const AppRouter: FC = () => {
  const dispatch = useAppDispatch();
  const { isAuthenticated, isOnLoad } = useAppSelector(authSelector);

  useEffect(() => {
    dispatch(AuthActions.onLoad());
  }, []);

  const routes = isAuthenticated ? publicRouts : authRouts;

  if (isOnLoad) {
    return <FullScreenLoader />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, component }) => (
          <Route path={path} element={component} key={path} />
        ))}
      </Routes>
      <NotifyPopUp />
    </BrowserRouter>
  );
};
