import React, { useState } from "react";

import { PrimaryInputProps } from "./types";

import {
  Tip,
  Error,
  Input,
  Label,
  EyeIcon,
  Wrapper,
  InputContainer,
  InfoIcon,
} from "./styles";
import { Required } from "application/styles/text";
import passwordActiveIcon from "application/assets/images/icons/password-active.svg";
import { useMediaQuery, useTheme } from "@mui/material";

const PrimaryInput = ({
  id,
  label,
  error,
  tip = "",
  required,
  register,
  onMobileClick,
  isSignUp,
  ...rest
}: PrimaryInputProps) => {
  const [type, setType] = useState(rest.type);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(674));
  const toggleType = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <Wrapper>
      <Label>
        {label} {required && <Required>*</Required>}
        {(isMobile && type === "password" && isSignUp) && <InfoIcon onClick={onMobileClick} />}
      </Label>
      <InputContainer error={error}>
        <Input {...rest} {...register(id)} type={type} />
        {rest.type === "password" && (
          <EyeIcon src={passwordActiveIcon} alt="eye" onClick={toggleType} />
        )}
      </InputContainer>
      {!!error ? <Error>{error}</Error> : <Tip>{tip}</Tip>}
    </Wrapper>
  );
};

export default PrimaryInput;
