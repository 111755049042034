import axios from "axios";
import { Auth } from "aws-amplify";

import { applicationConfig } from "application/config/applicationConfig";

class Api {
  private static _instance: Api;

  axiosInst = axios.create({ baseURL: applicationConfig.API_URL });

  async getToken() {
    try {
      const currentSession = await Auth.currentSession();
      const token = currentSession.getAccessToken().getJwtToken();
      return token;
    } catch (error) {
      console.log("getToken: Error", error);
      return null;
    }
  }

  constructor() {
    // declare a request interceptor
    this.axiosInst.interceptors.request.use(
      async (config) => {
        // Set Cognito JWT Token
        const token = await this.getToken();
        if (config.headers && token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }

        // perform a task before the request is sent
        return config;
      },
      (error) => {
        console.log("[API] request ERROR: ", error);
        // handle the error
        return Promise.reject(error);
      }
    );

    // declare a response interceptor
    this.axiosInst.interceptors.response.use(
      (response) => {
        // do something with the response data
        return response;
      },
      (error) => {
        console.log("[API] response ERROR: ", error);
        // handle the response error
        return Promise.reject(error);
      }
    );
  }

  static get instance(): Api {
    if (Api._instance == null) {
      Api._instance = new Api();
    }
    return Api._instance;
  }
}

export default Api;
