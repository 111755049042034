import styled, { css } from "styled-components";

import { colors } from "application/styles/colors";
import { ReactComponent as InfoIconSvg } from "application/assets/images/icons/info-icon.svg";
import { ReactComponent as LockIconSvg } from "application/assets/images/icons/lock-icon.svg";
import { ReactComponent as LogOutIconSvg } from "application/assets/images/icons/log-out-icon.svg";

export const Filter = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  position: fixed;
`;

export const LoaderContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const Container = styled.div`
  top: 90px;
  right: -16px;
  width: 400px;
  z-index: 12;
  position: absolute;
  padding-bottom: 16px;
  background-color: ${colors.white};
  box-shadow: 0px 2px 18px rgba(0, 0, 0, 0.25);

  &:before {
    content: "";
    width: 0;
    height: 0;
    top: -12px;
    right: 12px;
    position: absolute;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid ${colors.white};
  }

  @media (max-width: 1023px) {
    right: 16px;
    top: 72px;
  }
  
  @media (max-width: 454px) {
   width: 100%;
    right: 0;
  }
`;

export const Main = styled.div`
  padding: 24px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const UserEmail = styled.p`
  margin-left: 12px;
  /* Font style */
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;

export const PlanContainer = styled.div`
  padding: 20px;
  min-height: 103px;
  margin-top: 24px;
  box-sizing: border-box;
  border: 1px solid ${colors.stroke_grey};
`;

export const PlanTitle = styled.h5`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};

  span {
    text-transform: capitalize;
  }
`;

export const PlanLine = styled.div`
  height: 1px;
  margin: 12px 0;
  background-color: ${colors.stroke_grey};
`;

export const PlanContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PlanText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;

const iconStyles = css`
  width: 16px;
  height: 16px;
  stroke: ${colors.font_grey};
`;

export const InfoIcon = styled(InfoIconSvg)`
  ${iconStyles};
`;

export const LockIcon = styled(LockIconSvg)`
  ${iconStyles};
`;

export const LogOutIcon = styled(LogOutIconSvg)`
  ${iconStyles};
`;

export const LinkContainer = styled.div`
  padding: 8px;
  display: flex;
  cursor: pointer;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
  }
  p {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    font-family: "Helvetica";
    color: ${colors.font_grey};
  }
  &:hover {
    background: rgba(48, 98, 197, 0.06);
    p {
      color: ${colors.blue_default};
    }
  }
  &:hover ${InfoIcon}, &:hover ${LockIcon}, &:hover ${LogOutIcon} {
    stroke: ${colors.blue_default};
  }
`;
