import React from "react";

import {
  SIGN_UP_PROCESS_STEPS,
  UPDATE_SUBSCRIPTION_PROCESS_STEPS,
} from "features/side-bar/constants";

import ProcessStep from "../ProcessStep";

import {
  ProcessActive,
  ProcessActiveCircle,
  ProcessLineContainer,
  ProcessActiveCircleInside,
} from "./styles";
import { ProcessAnimationProps } from "./types";

const ProcessAnimation = ({
  type,
  progress,
  activeStep,
}: ProcessAnimationProps) => {
  const PROCESS_STEPS =
    type === "sign-up"
      ? SIGN_UP_PROCESS_STEPS
      : UPDATE_SUBSCRIPTION_PROCESS_STEPS;

  return (
    <ProcessLineContainer>
      <ProcessActive progress={progress}>
        <ProcessActiveCircle>
          <ProcessActiveCircleInside />
        </ProcessActiveCircle>
      </ProcessActive>
      {PROCESS_STEPS.map((process_step, index) => {
        return (
          <ProcessStep
            key={process_step.id}
            processLength={PROCESS_STEPS.length}
            isActive={activeStep >= process_step.step}
            renderLine={index !== PROCESS_STEPS.length - 1}
          />
        );
      })}
    </ProcessLineContainer>
  );
};

export default ProcessAnimation;
