import React, { useMemo } from "react";

import { ItemDescriptionProps } from "./types";

import { Container, Description, MarkIcon, Title } from "./styles";

const ItemDescription = ({ title, description }: ItemDescriptionProps) => {
  const lines = useMemo(() => description.split("\n"), [description]);

  return (
    <Container className="item-description">
      <MarkIcon />
      <Title>{title}</Title>
      <Description>
        {lines.length && (
          lines.map((l) => (
            l.length > 0 && (
              <span key={l}>
                {l}
                <br />
                <br />
              </span>
            )
          ))
        )}

      </Description>
    </Container>
  );
};

export default ItemDescription;
