import React from "react";
import { SmallLoaderAbsolutePosition } from "./styles";

const SmallLoaderWrapper = ({children}: any) => {
  return (
    <SmallLoaderAbsolutePosition>
      {children}
    </SmallLoaderAbsolutePosition>
  )
};

export default SmallLoaderWrapper;
