import React from "react";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import AuthActions from "common/auth/store/actions";
import { useAppDispatch, useAppSelector } from "application/store";
import { authSelector, setIsVisibleSubscriptionDeclinedPopUp } from "common";

import PrimaryButton from "../PrimaryButton";

import { Text, Name, Filter, Header, Container } from "./styles";
import bellIconSource from "application/assets/images/icons/bell.svg";
import closeIconSource from "application/assets/images/icons/close-icon.svg";

const SubscriptionDeclinedPopUp = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cognitoData } = useAppSelector(authSelector);

  const handleSubscriptionInfo = () => {
    dispatch(setIsVisibleSubscriptionDeclinedPopUp(false));
    navigate(PathNames.updateSubscriptionPlan);
  };

  const handleClose = () => {
    dispatch(AuthActions.signOut());
    dispatch(setIsVisibleSubscriptionDeclinedPopUp(false));
  };

  return (
    <Filter>
      <Container>
        <Header>
          <img src={bellIconSource} alt="bell" />
          <img src={closeIconSource} alt="close" onClick={handleClose} />
        </Header>
        <Name>Hello {cognitoData.given_name}! </Name>
        <Text>
          It looks like your subscription has expired. Please renew your
          subscription or choose another subscription plan to continue using
          your account.
        </Text>

        <PrimaryButton
          title="go to subscriptions"
          onClick={handleSubscriptionInfo}
        />
      </Container>
    </Filter>
  );
};

export default SubscriptionDeclinedPopUp;
