import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "application/store";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import ApiBondYieldSummary from "integration/api/ApiBondsYieldSummary";
import CryptoJS from "crypto-js";

class StockBondYieldComparison {
  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };
  static getValuationSummaryTableData = createAsyncThunk(
    `stock-bond-yield-summary/${ResponseHandlerName.GetStockBondYieldTableData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).stockBondYieldComparison;

      const metric = filters.selectedMetric.code;
      try {
        const { data } = await ApiBondYieldSummary.getBondYieldSummaryTableData(
          {
            stockMetricCode: metric,
          }
        );
        const decryptedData = StockBondYieldComparison.decryptData(data.data);
        return decryptedData;
      } catch (error) {
        console.log("getValuationSummaryTableData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static getMetrics = createAsyncThunk(
    `stock-bond-yield-summary/${ResponseHandlerName.GetStockBondYieldMetrics}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await ApiBondYieldSummary.getBondYieldSummaryMetrics();
        const decryptedData = StockBondYieldComparison.decryptData(data.data);
        return decryptedData;
      } catch (error) {
        console.log("getMetrics Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getCharts = createAsyncThunk(
    `stock-bond-yield-summary/${ResponseHandlerName.GetStockBondYieldCharts}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).stockBondYieldComparison;

      const metric = filters.selectedMetric.code;
      const idicie = filters.selectedIndices.code;
      try {
        const { data } = await ApiBondYieldSummary.getBondCharts(metric, idicie);
        const decryptedData = StockBondYieldComparison.decryptData(data.data);
        return decryptedData;
      } catch (error) {
        console.log("getMetrics Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default StockBondYieldComparison;
