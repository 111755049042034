import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import { FilterDataItem, IndicesComparisonState } from "./models";
import IndicesComparisonActions from "./actions";
import AuthActions from "../../auth/store/actions";

const initialState: IndicesComparisonState = {
  metrics: {},
  indices: {},
  comparisonDataSearched: false,
  indicesComparisonData: {
    data: [],
    startingDate: "",
    endingDate: "",
    fromDate: "",
    toDate: ""
  },
  filters: {
    selectedMetric: { code: "", name: "" },
    selectedIndices: [],
    startingDate: "",
    endingDate: "",
  },
  dateLimits: {
    startingDate: { year: 0, month: 0 },
    endingDate: { year: 0, month: 0 },
  },
};

export const indicesComparisonSlice = createSlice({
  name: "indices-comparison",
  initialState,
  reducers: {
    setFilterMetric: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedMetric = action.payload;
    },
    setFilterIndices: (state, action: PayloadAction<FilterDataItem>) => {
      const currSelectedIndices = [...state.filters.selectedIndices];
      const idx = currSelectedIndices.findIndex(
        (i) => i.code === action.payload.code
      );
      if (idx === -1) {
        currSelectedIndices.push(action.payload);
      } else {
        currSelectedIndices.splice(idx, 1);
      }
      state.filters.selectedIndices = currSelectedIndices;
    },
    setStartingDate: (state, action: PayloadAction<string>) => {
      state.filters.startingDate = action.payload;
    },
    setEndingDate: (state, action: PayloadAction<string>) => {
      state.filters.endingDate = action.payload;
    },
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    // getIndices
    builder.addCase(
      IndicesComparisonActions.getIndices.fulfilled,
      (state, action) => {
        state.indices = action.payload;
      }
    );

    // getIndicesComparisonData
    builder.addCase(
      IndicesComparisonActions.getIndicesComparisonData.fulfilled,
      (state, action) => {
        state.indicesComparisonData = action.payload;
        state.comparisonDataSearched = true;
      }
    );

    //getInceptionsData
    builder.addCase(
      IndicesComparisonActions.getInceptions.fulfilled,
      (state, action) => {
        state.dateLimits = action.payload;
      }
    );

    // signOut
    builder.addCase(AuthActions.signOut.pending, () => initialState);
  },
});

export default indicesComparisonSlice.reducer;
export const indicesComparisonSelector = () =>
  store.getState().indicesComparison;
export const {
  setFilterIndices,
  setFilterMetric,
  setStartingDate,
  setEndingDate,
  resetState,
} = indicesComparisonSlice.actions;
