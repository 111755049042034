import {
  ChartWidth,
  ChartHeight,
  ToolTipWidth,
  ToolTipHeight,
  ToolTipHeightWithMovingAvg,
  ToolTipBigWidth,
} from "../constants";

export function getTooltipPosition(x: number, y: number, hasMovingAvg: boolean, withNumberFormater: boolean) {
  const ShadowValue = 5;
  const xMargin = 7;
  const yMargin = 5;
  let tooltipX = x + xMargin;
  let tooltipY = y - yMargin;
  const tooltipWidth = withNumberFormater ? ToolTipBigWidth : ToolTipWidth;

  const sumX = x + tooltipWidth + ShadowValue;
  if (sumX > ChartWidth) {
    tooltipX = x - tooltipWidth - ShadowValue - xMargin / 2;
  }

  const finalToolTipHeight = hasMovingAvg
    ? ToolTipHeightWithMovingAvg
    : ToolTipHeight;

  const sumY = y + finalToolTipHeight + ShadowValue;
  if (sumY > ChartHeight / 1.5) {
    tooltipY = y - finalToolTipHeight - ShadowValue + yMargin * 2;
  }

  return { tooltipX, tooltipY };
}
