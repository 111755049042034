export const colors = {
  white: "#fff",
  black: "#000",

  red: "#FF1744",
  red_900: "#B71C1C",
  red_100: "#FFCDD2",

  grey: "#EFEFEF",
  grey_50: "#FAFAFA",
  grey_100: "#F5F5F5",
  grey_300: "#E0E0E0",
  grey_400: "#BDBDBD",
  grey_500: "#9E9E9E",
  grey_600: "#757575",
  font_grey: "#595959",
  stroke_grey: "#DDDDDD",
  stripe_grey_light: "#F1F1F1",

  ochre: "#DB7C29",
  brown_hover: "#C06B21",
  brown_default: "#D5874F",
  blue_hover: "#4A78D3",
  blue_100: "#BBDEFB",
  blue_400: "#42A5F5",
  blue_700: "#1976D2",
  light_blue: "#D0E1F2",
  oxford_blue: "#051638",
  blue_default: "#3062C5",
  navy_blue_hover: "#122B50",
  navy_blue_default: "#011737",

  green_a_400: "#4CAF50",

  cell: "#F9FCFF",
  lastUpdate: "#E8F1FF",
  selectedRow: "#F5F6FB",
  noDataRow: "#fff9f0",
  cellSorting: "#E3E3E3",
  annualCellSorting: "#95CFF6"
};
