import styled from "styled-components";

export const UpdateDate = styled.p`
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;

  @media only screen and (max-width: 1024px) {
    font-family: 'Helvetica';
    font-size: 13px;
    margin-top: 16px;
  }
`;

export const DateDot = styled.span`
  margin: 0 4px;
`;