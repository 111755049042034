import { IndexTypes, NoData } from "./types";

// we need this value of 100 because of sorting!
// if value equal to 0 it produces issue with blinking sorting
export const globalMock: NoData[] = [
  {
    type: IndexTypes.global,
    indexName: "globalMock",
    decadeAvgDividendYield: 100,
    nominalEarningGrowth: 100,
    valuationAdjustment: 100,
    annualizedNominalReturn: 100,
    currencyExchangeImpact: 100,
    unexplained: 100,
    beginningPriceToEarningsRatio: 100,
    endingPriceToEarningsRatio: 100,
    action: undefined,
  },
];

export const developedMarketsMock: NoData[] = [
  {
    type: IndexTypes.developedMarkets,
    indexName: "developedMarketsMock",
    decadeAvgDividendYield: 100,
    nominalEarningGrowth: 100,
    valuationAdjustment: 100,
    annualizedNominalReturn: 100,
    currencyExchangeImpact: 100,
    unexplained: 100,
    beginningPriceToEarningsRatio: 100,
    endingPriceToEarningsRatio: 100,
    action: undefined,
  },
];

export const emergingMarketslMock: NoData[] = [
  {
    type: IndexTypes.emergingMarkets,
    indexName: "emergingMarketslMock",
    decadeAvgDividendYield: 100,
    nominalEarningGrowth: 100,
    valuationAdjustment: 100,
    annualizedNominalReturn: 100,
    currencyExchangeImpact: 100,
    unexplained: 100,
    beginningPriceToEarningsRatio: 100,
    endingPriceToEarningsRatio: 100,
    action: undefined,
  },
];

export const countriesMock: NoData[] = [
  {
    type: IndexTypes.countries,
    indexName: "countriesMock",
    decadeAvgDividendYield: 100,
    nominalEarningGrowth: 100,
    valuationAdjustment: 100,
    annualizedNominalReturn: 100,
    currencyExchangeImpact: 100,
    unexplained: 100,
    beginningPriceToEarningsRatio: 100,
    endingPriceToEarningsRatio: 100,
    action: undefined,
  },
];
