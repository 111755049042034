import styled from "styled-components";

import { colors } from "application/styles/colors";
import { ReactComponent as ArrowIcon } from "application/assets/images/icons/arrow-white.svg";

interface StyledProps {
  marginBottom?: number;
}

interface SignUpProps {
  isSignUp?: boolean;
}

interface SuccessProps {
  open?: boolean;
  isSuccess?: boolean;
}

export const AuthWrapper = styled.div`
  display: flex;
  min-height: 100vh;

  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const AuthContainer = styled.div<SignUpProps>`
  flex: 1;
  display: flex;
  padding-top: 80px;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: ${({ isSignUp }) => isSignUp ? "calc(100vh - 147px)" : "calc(100vh - 88px)"};
    justify-content: space-between;
    overflow-y: auto;
    padding-top: ${({ isSignUp }) => isSignUp ? "180px" : "120px"};
    padding-bottom: 60px;
  }

  @media only screen and (max-width: 674px) {
    padding-bottom: 280px;
     padding-top: ${({ isSignUp }) => isSignUp ? "140px" : "120px"};
  }
`;

export const AuthContent = styled.div`
  width: 357px;

  @media only screen and (max-width: 1023px) {
    width: 642px;
  }

  @media only screen and (max-width: 674px) {
    width: calc(100% - 32px);
    padding: 0 16px;
  }
`;

export const AuthTitle = styled.h2`
  margin-bottom: 32px;
  /* Font-style */
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  font-family: "Times";
  color: ${colors.black};
`;

export const AuthActionNormalText = styled.span`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.font_grey};
`;

export const AuthActionText = styled.span`
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  color: ${colors.ochre};
  font-family: "Helvetica";

  @media only screen and (max-width: 674px) {
    font-size: 16px;
  }
`;

export const AuthBottomWrapper = styled.div`
  display: flex;
  margin-top: 32px;
  & > button:first-child {
    margin-right: 24px;
  }

  //@media only screen and (max-width: 1023px) {
  //  width: 642px;
  //}

  @media only screen and (max-width: 674px) {
    margin: 0 auto 24px;
    width: 100%;
  }
`;

export const AuthInputWrapper = styled.div`
  margin-bottom: 16px;
`;

export const AuthDoubleInputWrapper = styled.div<StyledProps>`
  display: flex;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 16}px;

  div {
    flex: 1;
  }
  & > div:first-child {
    margin-right: 24px;
  }

  @media only screen and (max-width: 674px) {
    flex-direction: column;
    margin-bottom: 16px;

    & > div:first-child {
      margin-right: unset;
      padding-bottom: 16px;
    }
  }
`;

export const AuthText = styled.div`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.font_grey};
`;

export const BottomActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  & > span:first-child {
    margin-right: 12px;
  }

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const MobileConfirmWrapper = styled.div`
  width: calc(100% - 32px);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
  padding: 16px;
`;

export const MobileConfirmButton = styled.div`
  width: 642px;
  margin: 0 auto 24px;

  @media only screen and (max-width: 674px) {
    width: 100%;
    margin: 0 auto 12px;
  }
`;

export const SubscriptionInfo = styled.div<SuccessProps>`
  position: absolute;
  display: ${({ open }) => open ? "flex" : "none"};
  align-items: center;
  bottom: 8px;
  left: 0;
  transform: translateY(24px);
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${({ isSuccess }) => isSuccess ? colors.green_a_400 : "#E65100"};
`;

export const InfoIconAccept = styled(ArrowIcon)`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  stroke: ${colors.green_a_400};
`;

export const InfoIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 8px;
`;