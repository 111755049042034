import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  DescriptionContainer,
  CalculateButton,
  Container,
  DateRangeStyled,
  FiltersContainer,
  DatePickerStyled,
  CompareButton,
  ArrowBackContainer,
  ArrowBack,
} from "./styles";
import DatePicker from "../DatePicker";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  ComparisonMetric,
  FilterData,
} from "common/indicesPerformanceSummary/store/models";
import {
  indicesPerformanceSummarySelector,
  setEndingDate,
  setFilterMetric,
} from "common/indicesPerformanceSummary/store/slice";
import DropGroup from "../DropGroup";
import { requestSelector } from "common/responseHandlerService/slice";
import { useMediaQuery, useTheme } from "@mui/material";
import BondIndicesPerformanceSummaryActions from "common/bondIndicesPerformanceSummary/store/actions";
import BondSummaryActions from "common/bondIndicesPerformanceSummary/store/actions";
import {
  bondSummarySelector,
  setBondEndingDate,
} from "common/bondIndicesPerformanceSummary/store/slice";

const MIN_ALLOWED_DATE = moment("2022-06-01");

interface Props {
  compare: boolean;
  compareLines: () => void;
  selectedCount: number;
}

const BondReturnsSummaryFilters = React.forwardRef(
  ({ compare, compareLines, selectedCount }: Props, ref: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1024));
    const dispatch = useAppDispatch();
    const requestData = useAppSelector(requestSelector);
    const { bondSummaryData, filters, maxEndingDate } =
      useAppSelector(bondSummarySelector);

    const loading =
      requestData.getIndicesPerformanceSummary.loading ||
      requestData.getMetrics.loading;

    const handleSetEndingDate = (date: Moment) => {
      const formatedDate = date.format("yyyy-MM");
      dispatch(setBondEndingDate(formatedDate));
    };

    const handleSubmitCalculation = useCallback(() => {
      dispatch(BondSummaryActions.getBondSummaryData());
    }, [filters]);

    useEffect(() => {
      if (!bondSummaryData.data.length) {
        Promise.all([dispatch(BondSummaryActions.getDataForFilters())]).then(
          (resArr) => {
            const metricRes = resArr[0].payload as FilterData;
            handleSetEndingDate(moment(metricRes.endDate));
            dispatch(BondSummaryActions.getBondSummaryData());
          }
        );
      }
    }, []);

    const isSubmitCalculationDisabled = useMemo(() => {
      return false;
    }, [filters]);

    return (
      <Container>
        <FiltersContainer>
          <DatePickerStyled>
            <DatePicker
              label="Ending Date"
              minDate={MIN_ALLOWED_DATE}
              maxDate={maxEndingDate ? moment(maxEndingDate) : moment()}
              onChange={handleSetEndingDate}
              value={moment(filters.endingDate)}
            />
          </DatePickerStyled>
          <CalculateButton
            onClick={handleSubmitCalculation}
            disabled={isSubmitCalculationDisabled}
            isDisabled={isSubmitCalculationDisabled}
          >
            Calculate
          </CalculateButton>
        </FiltersContainer>
        <DescriptionContainer ref={ref}>
          <DateRangeStyled>
            {bondSummaryData.data.length > 0 &&
              `Periods Ending ${moment(bondSummaryData.endingDate).format(
                "MMMM D, YYYY"
              )}`}
            <span>Returns longer than one year are annualized</span>
          </DateRangeStyled>
          {!compare && (
            <CompareButton onClick={compareLines} disabled={selectedCount < 2}>
              COMPARE {isMobile ? "" : " INDEXES"}
            </CompareButton>
          )}
          {compare && (
            <>
              <CompareButton compare={compare} onClick={compareLines}>
                back to full list
              </CompareButton>
              <ArrowBackContainer onClick={compareLines}>
                <ArrowBack />
              </ArrowBackContainer>
            </>
          )}
        </DescriptionContainer>
      </Container>
    );
  }
);

export default BondReturnsSummaryFilters;
