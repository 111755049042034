import React from "react";
import { Amplify } from "aws-amplify";

import "reset-css";
import ReactDOM from "react-dom/client";

import App from "./application/App";
import reportWebVitals from "./reportWebVitals";

import awsExports from "integration/aws/aws-exports";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
