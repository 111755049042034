import React, { useState } from "react";
import { authSelector } from "common";
import { useAppSelector } from "application/store";
import { applicationConfig } from "application/config/applicationConfig";
import Setting from "../Setting";
import UserAvatar from "../UserAvatar";
import {
  Logo,
  Arrow,
  Content,
  UserName,
  Container,
  BarText,
  FlexBox,
  NavWrapper,
  MobileNavWrapper,
  HeaderMenuIcon,
  HeaderCloseIcon,
} from "./styles";
import logoSource from "application/assets/images/logo-blue.svg";
import arrowSource from "application/assets/images/icons/arrow-down.svg";
import BarItem from "./BarItem";
import { useLocation, useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";
import { useMediaQuery, useTheme } from "@mui/material";
import { Filter } from "../Setting/styles";

const PrimaryHeader = ({
  menuVisible,
  onClose,
}: {
  menuVisible: boolean;
  onClose: (isClose: boolean) => void;
}) => {
  const { given_name, family_name } = useAppSelector(authSelector).cognitoData;
  const [visible, setVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleVisible = () => setVisible(!visible);

  const stock =
    location.pathname === PathNames.valuationReports ||
    location.pathname === PathNames.expectStock ||
    location.pathname === PathNames.historicalStock ||
    location.pathname === PathNames.indicesComparison ||
    location.pathname === PathNames.indicesSummary ||
    location.pathname === PathNames.valuationSummary;


  const avatarName = given_name.charAt(0) + family_name.charAt(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const handleHowToLink = () => {
    window.open(applicationConfig.how_to_page, "_blank");
  };

  const handleWebinarLink = () => {
    window.open(applicationConfig.assetcamp_webinar, "_blank");
  };

  const closeMenu = () => {
    onClose(false);
  };

  const navigateToHome = () => {
    navigate(PathNames.home);
  };

  const stockMenuItems = [
    { name: "What Happened?" },
    {
      name: "Stock Market Returns Summary Table",
      path: PathNames.indicesSummary,
    },
    {
      name: "Stock Market 10-Year Performance Attribution",
      path: PathNames.historicalStock,
    },
    {
      name: "Stock Market Performance Comparison Tool",
      path: PathNames.indicesComparison,
    },
    { name: "Where Are We Now?" },
    {
      name: "Stock Valuations and Earnings Summary Tables",
      path: PathNames.valuationSummary,
    },
    {
      name: "Stock Valuations, Earnings, and Trends Charts",
      path: PathNames.valuationReports,
    },
    { name: "Where Are We Heading?" },
    { name: "Stock Market Expected Returns Modeling", path: PathNames.expectStock },
  ];

  const bondMenuItems = [
    { name: "What Happened?" },
    {
      name: "Bond Market Returns Summary Table",
      path: PathNames.bondIndicesSummary,
    },
    {
      name: "Bond Market Performance Comparison Tool",
      path: PathNames.bondsComparison,
    },
    { name: "Where Are We Now?" },
    {
      name: "Bond Yield, Duration, and Spread Summary Tables",
      path: PathNames.bondValuationSummary,
    },
    {
      name: "Bond Yield, Duration, and Spread Charts",
      path: PathNames.fixedIncomeCharts,
    },
    {
      name: "Yield Curve Spreads Summary Table",
      path: PathNames.curveSpreadSummary,
    },
    {
      name: "Stock Earnings Yield and Bond Yield Spread Comparison",
      path: PathNames.stockBondComparison,
    },
    { name: "Where Are We Heading?" },
    {
      name: "Bond Market Expected Returns Modeling",
      path: PathNames.expectedReturnsModeling
    },
  ];

  return (
    <Container>
      {menuVisible ? (
        <HeaderCloseIcon
          onClick={() => {
            onClose(false);
          }}
        />
      ) : (
        <HeaderMenuIcon
          onClick={() => {
            onClose(true);
          }}
        />
      )}
      <FlexBox>
        {!menuVisible && (
          <Logo src={logoSource} alt="logo" onClick={navigateToHome} />
        )}

        {!isTablet && (
          <NavWrapper>
            <BarItem
              onClose={onClose}
              title="STOCKS"
              isSelected={stock}
              menuItems={stockMenuItems}
            />
            <BarItem
              onClose={onClose}
              title="BONDS"
              isSelected={false}
              menuItems={bondMenuItems}
            />
            <BarText onClick={handleHowToLink} isSelected={false}>
              LESSONS & TUTORIALS
            </BarText>
            <BarText onClick={handleWebinarLink} isSelected={false}>
              MONTHLY WEBINAR
            </BarText>
          </NavWrapper>
        )}
      </FlexBox>
      {!menuVisible && (
        <Content onClick={toggleVisible}>
          <UserAvatar name={avatarName} />
          <UserName>
            {given_name} {family_name}
          </UserName>
          <Arrow src={arrowSource} alt="arrow" />
        </Content>
      )}
      {visible && <Setting onClose={toggleVisible} />}

      {menuVisible && (
        <MobileNavWrapper className="mobile-wrapper">
          <NavWrapper>
            <BarItem
              onClose={onClose}
              title="STOCKS"
              isSelected={stock}
              menuItems={stockMenuItems}
            />
            <BarItem
              onClose={onClose}
              title="BONDS"
              isSelected={false}
              menuItems={bondMenuItems}
            />
            <BarText onClick={handleHowToLink}>LESSONS & TUTORIALS</BarText>
            <BarText onClick={handleWebinarLink}>MONTHLY WEBINAR</BarText>
          </NavWrapper>
          <Filter onClick={closeMenu} />
        </MobileNavWrapper>
      )}
    </Container>
  );
};

export default PrimaryHeader;
