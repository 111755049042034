import React from "react";

import { ProcessStepProps } from "./types";

import { ProcessCircle, ProcessLine } from "./styles";

const ProcessStep = ({
  isActive,
  renderLine,
  processLength,
}: ProcessStepProps) => {
  return (
    <>
      <ProcessCircle isActive={isActive} />
      {renderLine && <ProcessLine length={processLength - 1} />}
    </>
  );
};

export default ProcessStep;
