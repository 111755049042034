import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  ButtonsContainer,
  ChartContainer,
  CloseButton,
  ModalContent,
  ModalOverlay,
} from "./styles";
import { ChartModalProps, FilterDataItem } from "./types";
import { useMediaQuery, useTheme } from "@mui/material";
import Chart from "features/chart";
import { stockBondYieldSummarySelector, setStockBondYieldFilterIdices, setStockBondYieldFilterMetric, resetBondIndiciesData } from "common/stockBondYieldsComparison/store/slice";
import StockBondYieldComparison from "common/stockBondYieldsComparison/store/actions";

const StockBondYieldChartModal = ({
  selectedMetric,
  selectedIndex,
  onClose,
}: ChartModalProps) => {
  const dispatch = useAppDispatch();

  const requestData = useAppSelector(requestSelector);
  const loading =
    requestData.getStockBondYieldCharts.loading;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const { indicesData, filters } =
    useAppSelector(stockBondYieldSummarySelector);

  const handleIndicesSelect = (item: FilterDataItem) => {
    dispatch(setStockBondYieldFilterIdices(item));
  };

  const handleMetricSelect = (item: FilterDataItem) => {
    dispatch(setStockBondYieldFilterMetric(item));
  };

  useEffect(() => {
    handleIndicesSelect(selectedIndex);
    handleMetricSelect(selectedMetric);
  }, []);

  useEffect(() => {
    if (filters.selectedIndices.code && filters.selectedMetric.code) {
      dispatch(StockBondYieldComparison.getCharts());
    }
  }, [filters]);

  const onCloseHandler = () => {
    dispatch(resetBondIndiciesData());
    onClose();
  };

  const modalRootElement = document.getElementById("modal");

  return createPortal(
    <ModalOverlay onClick={onCloseHandler}>
      <ModalContent onClick={(event) => event.stopPropagation()}>
        <ChartContainer>
          <Chart
            loading={loading}
            indicesData={indicesData}
            filters={filters}
            isTablet={isTablet}
            adjustedChartHeight={270}
            withNumberFormater
            isBloomberg
          />
        </ChartContainer>
        <ButtonsContainer>
          <CloseButton onClick={onCloseHandler}>
            <p>Close</p>
          </CloseButton>
        </ButtonsContainer>
      </ModalContent>
    </ModalOverlay>,
    modalRootElement!
  );
};

export default StockBondYieldChartModal;
