import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import { FilterDataItem, ValuationSummary } from "./model";
import ValuationSummaryActions from "./actions";

const initialState: ValuationSummary = {
  metrics: {},
  tableData: {},
  filters: {
    selectedMetric: { code: "", name: "" },
  },
};

export const valuationSummarySlice = createSlice({
  name: "valuation-summary",
  initialState,
  reducers: {
    setFilterMetric: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedMetric = action.payload;
    },
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    // getTableData
    builder.addCase(
      ValuationSummaryActions.getValuationSummaryTableData.fulfilled,
      (state, action) => {
        state.tableData = action.payload;
      }
    );
    builder.addCase(
      ValuationSummaryActions.getMetrics.fulfilled,
      (state, action) => {
        state.metrics = action.payload;
      }
    );
  },
});

export default valuationSummarySlice.reducer;
export const valuationSelector = () => store.getState().valuationSummary;
export const { setFilterMetric, reset } = valuationSummarySlice.actions;
