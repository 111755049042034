import React from "react";

import { Loader } from "./styles";

const SmallLoader = () => {
  return (
    <Loader>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  );
};

export default SmallLoader;
