import styled from "styled-components";

import { PrimaryInputProps } from "./types";
import { colors } from "application/styles/colors";
import {ReactComponent as InfoIconSvg} from "../../assets/images/icons/info-icon.svg";

interface StyledProps {
  error?: PrimaryInputProps["error"];
}

export const Wrapper = styled.div`
  position: relative;
  padding-bottom: 10px;

  @media only screen and (max-width: 1023px) {
    padding-bottom: 0;
  }
`;

export const Label = styled.label`
  display: block;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.font_grey};
  margin-bottom: 4px;

  @media only screen and (max-width: 674px) {
    position: relative;
  }
`;

export const InputContainer = styled.div<StyledProps>`
  height: 44px;
  position: relative;
  box-sizing: border-box;
  background-color: ${colors.stripe_grey_light};
  border: ${({ error }) => `1px solid ${error ? colors.red : colors.grey}`};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  text-indent: 12px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.black};
  background: transparent;
  &::placeholder {
    color: ${colors.grey_500};
  }
`;

export const EyeIcon = styled.img`
  top: 6px;
  right: 12px;
  cursor: pointer;
  position: absolute;
`;

export const Error = styled.span`
  position: absolute;
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 4px;
  color: ${colors.red};
  font-family: "Helvetica";

  @media only screen and (max-width: 1023px) {
    position: relative;
  }
`;

export const Tip = styled.span`
  position: absolute;
  display: block;
  margin-top: 4px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.font_grey};
`;

export const InfoIcon = styled(InfoIconSvg)`
  stroke: #595959;
  transform: translateY(4px);
  margin-left: 4px;
`;