import React, {useEffect, useRef, useState} from "react";

import ExpectedStockActions from "./store/actions";
import { FilterDataItem } from "./store/model";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import {
  homeSelector,
  setFilterDividend,
  setFilterEarnings,
  setFilterValueation,
} from "./store/slice";

import DropGroup from "application/components/DropGroup";
import { headCells } from "../../application/constants/expectedStock/expectedStock";

import {
  Title,
  Container,
  DropContainer,
  FiltersContainer,
  TitleWrapper,
  CompareButton,
  TableInfoWrapper,
  BottomLogo,
  UnderContentInfo,
  ArrowBackContainer,
  ArrowBack,
  BottomMfruLogo,
  LogoWrapper,
} from "./styles";
import { Loader } from "../../features/chart/styles";
import SmallLoader from "../../application/components/SmallLoader";
import StickyHeaderTable from "../../application/components/StickyHeaderTable";
import MainContainer from "../../application/components/MainContainer";
import MSCIInfo from "../../application/components/MSCIInfo";
import {
  Data,
  NoData,
} from "../../application/components/StickyHeaderTable/types";
import { find, propEq } from "ramda";
import logoBlueSource from "../../application/assets/images/logo-blue.svg";
import { applicationConfig } from "../../application/config/applicationConfig";
import ReportLastUpdateAction from "../home/actions/ReportLastUpdateAction";
import LastUpdateDate from "../../application/components/LastUpdateDate";
import NotifyPopUpTable from "../../application/components/NotifyPopUpTable";
import { useMediaQuery, useTheme } from "@mui/material";
import mfruLogoSource from "../../application/assets/images/mfru-logo.svg";

const ExpectStock = () => {
  const [selectedCount, setSelectedCount] = React.useState<number>(0);
  const [compare, setCompare] = React.useState<boolean>(false);
  const [isMSCIVisible, setIsMSCIVisible] = React.useState<boolean>(false);
  const [selectedCompare, setSelectedCompare] = React.useState<Data[]>([]);
  const [date, setDate] = useState<string>("");
  const buttonSectionRef = useRef<HTMLDivElement>(null);
  const [buttonSectionHeight, setButtonSectionHeight] = useState<number>(0);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const dispatch = useAppDispatch();
  const requestData = useAppSelector(requestSelector);
  const { dividend, earnings, valuation, filters, tableData } =
    useAppSelector(homeSelector);
  const loading = requestData.getExpectedStockTableData.loading;

  useEffect(() => {
    const dividendKeys = Object.keys(dividend);
    const valuationKeys = Object.keys(valuation);
    const earningKeys = Object.keys(earnings);
    const firstValuation = valuation[valuationKeys[0]][0];
    const firstDividend = dividend[dividendKeys[0]][0];
    const firstEarning = earnings[earningKeys[0]][0];
    handleDividendSelect(firstDividend);
    handleEarningSelect(firstEarning);
    handleValuationSelect(firstValuation);

    if (!date.length) {
      Promise.all([ReportLastUpdateAction.getLastUpdateDate()]).then((data) => {
        setDate(data[0]?.lastUpdatedDate || "");
      });
    }

    if (buttonSectionRef.current) {
      setButtonSectionHeight(buttonSectionRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (
      filters.selectedDividend.code &&
      filters.selectedEarnings.code &&
      filters.selectedValuation.code
    ) {
      dispatch(ExpectedStockActions.getExpectedStockTableData());
    }
  }, [filters]);

  useEffect(() => {
    let arr: Data[] = [];

    selectedCompare.map((item: Data) => {
      // @ts-ignore
      let dataItem: Data | NoData = find(propEq("indexName", item.indexName))(
        tableData.expectedStock
      );

      if (!dataItem) {
        dataItem = {
          ...item,
          noData: true,
        };
      }

      return (arr = [...arr, dataItem]);
    });

    setSelectedCompare(arr);
  }, [tableData]);

  const handleDividendSelect = (item: FilterDataItem) => {
    dispatch(setFilterDividend(item));
  };

  const handleEarningSelect = (item: FilterDataItem) => {
    dispatch(setFilterEarnings(item));
  };

  const handleValuationSelect = (item: FilterDataItem) => {
    dispatch(setFilterValueation(item));
  };

  const showButton = (show: number) => {
    setSelectedCount(show);
  };

  const compareLines = () => {
    setCompare(!compare);
  };

  const toggleMSCIVisible = () => {
    setIsMSCIVisible(!isMSCIVisible);
  };

  const handleSelectedCompare = (data: Data[]) => {
    setSelectedCompare(data);
  };

  return (
    <MainContainer>
      <Container>
        <TitleWrapper>
          <Title>Stock Market Expected Returns Modeling</Title>
          <LastUpdateDate date={date} />
        </TitleWrapper>
        <FiltersContainer>
          <DropContainer>
            <DropGroup
              data={dividend}
              label="Dividend Yield"
              disabled={loading}
              onSelect={handleDividendSelect}
              selected={filters.selectedDividend}
              placeholder="Dividend Yield name goes here"
            />
            <DropGroup
              data={earnings}
              label="Earnings Growth"
              disabled={loading}
              onSelect={handleEarningSelect}
              selected={filters.selectedEarnings}
              placeholder="Earnings Growth name goes here"
            />
            <DropGroup
              data={valuation}
              label="Valuation Adjustment"
              disabled={loading}
              onSelect={handleValuationSelect}
              selected={filters.selectedValuation}
              placeholder="Valuation Adjustment name goes here"
            />
          </DropContainer>
        </FiltersContainer>
        {loading && (
          <Loader>
            <SmallLoader />
          </Loader>
        )}
        {!loading && (
          <div>
            <TableInfoWrapper ref={buttonSectionRef} compare={compare}>
              {!compare && (
                <CompareButton onClick={compareLines} disabled={selectedCount < 2}>
                  COMPARE {isMobile ? "" : " INDEXES"}
                </CompareButton>
              )}
              {compare && (
                <>
                  <CompareButton compare={compare} onClick={compareLines}>
                    back to full list
                  </CompareButton>
                  <ArrowBackContainer onClick={compareLines}>
                    <ArrowBack />
                  </ArrowBackContainer>
                </>
              )}
            </TableInfoWrapper>
            <StickyHeaderTable
              historicalStock={tableData.expectedStock}
              dataUnitsOfMeasurement={tableData.dataUnitsOfMeasurement}
              showButton={showButton}
              compareLines={compare}
              headCells={headCells}
              onSelectedCompare={handleSelectedCompare}
              selectedCompare={selectedCompare}
              buttonSection={buttonSectionHeight}
            />
            <UnderContentInfo>
              <MSCIInfo
                isMSCIVisible={isMSCIVisible}
                underTable
                onChange={toggleMSCIVisible}
              />
              <LogoWrapper>
                <BottomMfruLogo
                  src={mfruLogoSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.main_page, "_blank");
                  }}
                />
                <BottomLogo
                  src={logoBlueSource}
                  alt="logo"
                  onClick={() => {
                    window.open(applicationConfig.assetcamp, "_blank");
                  }}
                />
              </LogoWrapper>
            </UnderContentInfo>
          </div>
        )}
      </Container>
      {isTablet && <NotifyPopUpTable />}
    </MainContainer>
  );
};

export default ExpectStock;
