import React from "react";
import { BillingError, CloseIcon, ErrorIcon, ErrorWrapper } from "./styles";
interface Props {
  onClick: () => void;
}

const BillingAddressError = ({onClick}: Props) => {
  return (
    <BillingError>
      <ErrorWrapper>
        <ErrorIcon />
        <p>Billing Address is incorrect. <br />Please ensure Country, State, City and ZIP code are provided correctly.</p>
      </ErrorWrapper>
      <CloseIcon onClick={onClick} />
    </BillingError>
  );
};

export default BillingAddressError;