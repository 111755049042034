import React, { useEffect } from "react";
import { Provider } from "react-redux";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { store } from "./store";
import { AppRouter } from "./routes";
import { removeScripts } from "./helpers/removeScripts";

import "./App.css";

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1280,
    },
  },
});

const App = () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  useEffect(() => {
    removeScripts(environment);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </ThemeProvider>
  );
};

export default App;
