import React, { useEffect, useRef } from "react";
import { Data, EnhancedTableProps, IndexTypes, List, NoData } from "./types";
import { findIndex, propEq, uniq, sortBy, compose, prop } from "ramda";
import {
  BorderSubElement,
  CellCheckoutBox,
  CustomTableCell,
  EmptyTableCell,
  HistoricalTable,
  StickyTableCell,
  StickyTableContainer,
  StickyTableSection,
  EmptyGroupCell,
} from "./styles";
import {
  Checkbox,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getComparator, stableSort } from "../../helpers/tableSorting";
import { colors } from "../../styles/colors";
import {
  SortArrow,
  SortArrowDown,
  SortArrowUp,
} from "../TableArrows/TableArrows";
import {
  countriesMock,
  developedMarketsMock,
  emergingMarketslMock,
  globalMock,
} from "./constants";
import OptionalTooltip from "../OptionalTooltip";

const StickyHeaderTable = ({
  showButton,
  compareLines,
  historicalStock,
  dataUnitsOfMeasurement,
  headCells,
  onSelectedCompare,
  selectedCompare = [],
  buttonSection,
  valuationSummary = false,
}: List) => {
  const [order, setOrder] = React.useState<number>(0);
  const [orderBy, setOrderBy] = React.useState<keyof Data>("indexName");
  const [selected, setSelected] = React.useState<Data[]>([]);
  const [rows, setRows] = React.useState<NoData[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const theadRef = useRef<any>(null);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1024));

  const groupItems = () => {
    if (historicalStock && !compareLines) {
      const globalStock = historicalStock.filter(
        (e: any) => e.indexType === IndexTypes.global
      );
      const developerMarketsStock = historicalStock.filter(
        (e: any) => e.indexType === IndexTypes.developedMarkets
      );
      const emergingMarketsStock = historicalStock.filter(
        (e: any) => e.indexType === IndexTypes.emergingMarkets
      );
      const countriesStock = historicalStock.filter(
        (e: any) => e.indexType === IndexTypes.countries
      );
      const sortedTypeArray = globalMock.concat(
        globalStock,
        developedMarketsMock,
        developerMarketsStock,
        emergingMarketslMock,
        emergingMarketsStock,
        countriesMock,
        countriesStock
      );
      if (order === 0) {
        setRows(sortedTypeArray);
      } else {
        setRows(historicalStock);
      }
    }
  };

  const handleScroll = () => {
    if (theadRef.current && wrapperRef.current) {
      const wrapperPosition = wrapperRef.current.getBoundingClientRect();
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      if (wrapperPosition.y <= buttonSection) {
        theadRef.current.style.position = "relative";

        if (isIOS) {
          theadRef.current.style.transform = `translateY(${
            (wrapperPosition.y + 1 - buttonSection) * -1
          }px)`;
        } else {
          theadRef.current.style.top = `${
            (wrapperPosition.y + 1 - buttonSection) * -1
          }px`;
        }

        theadRef.current.style.zIndex = 99;
      } else {
        theadRef.current.style.position = "unset";
        theadRef.current.style.top = buttonSection;
        theadRef.current.style.webkitTransform = `translateY(0)`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (compareLines) {
      setRows(selectedCompare ? selectedCompare : selected);
    } else if (historicalStock) {
      groupItems();
    }
  }, [compareLines, historicalStock]);

  useEffect(() => {
    groupItems();
  }, [order]);

  useEffect(() => {
    if (compareLines) {
      setRows(selectedCompare ? selectedCompare : selected);
      if (selectedCompare?.length) {
        setSelected(selectedCompare);
      }
    }
    handleScroll();
  }, [selectedCompare]);

  useEffect(() => {
    handleScroll();
  }, [order, orderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    setOrder(property === orderBy ? (order === 2 ? 0 : order + 1) : 1);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && !selected.length) {
      const filterOnSelectAll = rows.filter((e) => !e.type);
      showButton(rows.length);
      setSelected(filterOnSelectAll);
      if (onSelectedCompare) {
        onSelectedCompare(filterOnSelectAll);
      }
      return;
    } else {
      showButton(0);
      setSelected([]);
      if (onSelectedCompare) {
        onSelectedCompare([]);
      }
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: Data) => {
    if (compareLines) return;

    let newArr = uniq(selectedCompare);

    const selectedIndex = findIndex(propEq("indexName", row.indexName))(newArr);

    let newSelected: Data[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(newArr, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(newArr.slice(1));
    } else if (selectedIndex === newArr.length - 1) {
      newSelected = newSelected.concat(newArr.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        newArr.slice(0, selectedIndex),
        newArr.slice(selectedIndex + 1)
      );
    }

    const sort = compose(sortBy(prop("indexId")));
    const sortedNewSelected = sort(newSelected);
    const global = sortedNewSelected.filter(
      (e: any) => e.indexType === IndexTypes.global
    );
    const developedMarket = sortedNewSelected.filter(
      (e: any) => e.indexType === IndexTypes.developedMarkets
    );
    const emergingMarkets = sortedNewSelected.filter(
      (e: any) => e.indexType === IndexTypes.emergingMarkets
    );
    const countries = sortedNewSelected.filter(
      (e: any) => e.indexType === IndexTypes.countries
    );

    const finalSelected = global
      .concat(developedMarket)
      .concat(emergingMarkets)
      .concat(countries);

    showButton(newSelected.length);
    setSelected(finalSelected);

    if (onSelectedCompare) {
      onSelectedCompare(finalSelected);
    }
  };

  const arrowDropDown = (headCellId: string) => {
    switch (headCellId === orderBy ? order : 0) {
      case 1:
        return <SortArrowUp />;
      case 2:
        return <SortArrowDown />;
      default:
        return <SortArrow />;
    }
  };

  const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        if (order !== 0 && !compareLines) {
          const filterOnSort = rows.filter((e) => !e.type);
          setRows(filterOnSort);
        }
        if (event.target.hasOwnProperty("checked")) {
          return;
        }
        onRequestSort(event, property);
      };

    handleScroll();

    const getBorder = (id: string) => {
      const isBorder =
        id === "decadeAvgDividendYield" ||
        id === "nominalEarningGrowth" ||
        id === "valuationAdjustment" ||
        id === "currencyExchangeImpact" ||
        id === "unexplained" ||
        id === "annualizedNominalReturn";

      return isBorder;
    };

    return (
      <TableHead ref={theadRef}>
        <TableRow>
          {headCells.map((headCell) => (
            <StickyTableCell
              className="sticky-table-cell"
              key={headCell.id}
              align="left"
              padding="normal"
              diffBackground={headCell.diffBackground}
              minWidth={headCell.id === "indexName"}
              firstBorderLeft={compareLines}
              isSorting={order !== 0 && orderBy === headCell.id}
              valuationSummary={valuationSummary}
            >
              <BorderSubElement
                isBorder={getBorder(headCell.id)}
                id={headCell.id}
              />
              {headCell.id === "indexName" && (
                <OptionalTooltip text={headCell.tooltip}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={() => arrowDropDown(headCell.id)}
                  >
                    <CellCheckoutBox>
                      {!compareLines && (
                        <Checkbox
                          color="primary"
                          indeterminate={
                            numSelected > 0 && numSelected < rowCount
                          }
                          checked={rowCount > 0 && numSelected === rowCount}
                          onChange={onSelectAllClick}
                          inputProps={{
                            "aria-label": "select all desserts",
                          }}
                        />
                      )}
                      {headCell.label}
                    </CellCheckoutBox>
                  </TableSortLabel>
                </OptionalTooltip>
              )}
              {headCell.id === "action" && <span>{headCell.label}</span>}
              {headCell.id !== "indexName" && headCell.id !== "action" && (
                <OptionalTooltip text={headCell.tooltip}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={() => arrowDropDown(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </OptionalTooltip>
              )}
            </StickyTableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const isSelected = (row: Data) =>
    findIndex(propEq("indexName", row.indexName))(
      selectedCompare?.length ? selectedCompare : selected
    ) !== -1;

  return (
    <StickyTableSection>
      <StickyTableContainer ref={wrapperRef}>
        <HistoricalTable aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={
              selectedCompare?.length ? selectedCompare.length : selected.length
            }
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(
              rows,
              getComparator(order, orderBy),
              orderBy,
              order
            ).map((row: any, index) => {
              const isItemSelected = compareLines ? false : isSelected(row);
              const labelId = `enhanced-table-checkbox-${index}`;
              let isFrontierBuilt = false;

              const getColor = (diffBackground: boolean) => {
                let cellBackgroundColor: string;

                if (row.noData) {
                  cellBackgroundColor = colors.noDataRow;
                } else {
                  cellBackgroundColor = isItemSelected
                    ? isItemSelected
                      ? colors.selectedRow
                      : colors.white
                    : diffBackground
                    ? colors.cell
                    : colors.white;
                }

                return cellBackgroundColor;
              };

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.indexName}
                  selected={isItemSelected}
                >
                  {headCells.map((headCell) => {
                    if (headCell.id === "indexName") {
                      if (row.type === IndexTypes.global)
                        return (
                          <EmptyGroupCell key={headCell.id}>
                            Global
                          </EmptyGroupCell>
                        );
                      if (row.type === IndexTypes.developedMarkets)
                        return (
                          <EmptyGroupCell key={headCell.id}>
                            Developed Markets
                          </EmptyGroupCell>
                        );
                      if (row.type === IndexTypes.emergingMarkets)
                        return (
                          <EmptyGroupCell key={headCell.id}>
                            Emerging Markets
                          </EmptyGroupCell>
                        );
                      if (row.type === IndexTypes.countries)
                        return (
                          <EmptyGroupCell key={headCell.id}>
                            Countries
                          </EmptyGroupCell>
                        );
                      return (
                        <OptionalTooltip
                          placement="right"
                          text={row.description}
                        >
                          <CustomTableCell
                            key={headCell.id}
                            align="left"
                            bgColor={() => getColor(headCell.diffBackground)}
                          >
                            <CellCheckoutBox
                              onClick={(event) => {
                                if (!row.type) handleClick(event, row);
                              }}
                            >
                              {!compareLines && (
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              )}
                              {row.indexName}
                            </CellCheckoutBox>
                          </CustomTableCell>
                        </OptionalTooltip>
                      );
                    }
                    if (row.type) return <EmptyGroupCell key={headCell.id} />;
                    if (
                      row.indexName === "Frontier Markets" &&
                      row.annualizedNominalReturn === null &&
                      row.beginningPriceToEarningsRatio === null &&
                      row.decadeAvgDividendYield === null &&
                      row.endingPriceToEarningsRatio === null &&
                      row.nominalEarningGrowth === null &&
                      row.valuationAdjustment === null &&
                      !isFrontierBuilt
                    ) {
                      isFrontierBuilt = true;

                      return (
                        <EmptyTableCell
                          colSpan={headCells.length - 1}
                          align="center"
                        >
                          {isTablet
                            ? "No data"
                            : "No return estimate available: insufficient future earnings data"}
                        </EmptyTableCell>
                      );
                    }
                    if (
                      row.indexName === "Frontier Markets" &&
                      row.asOfOneMonthAgo === null &&
                      row.asOfThreeMonthAgo === null &&
                      row.asOfTwoMonthAgo === null &&
                      row.longTermAverage === null &&
                      row.standardDeviation === null &&
                      !isFrontierBuilt
                    ) {
                      isFrontierBuilt = true;

                      return (
                        <EmptyTableCell
                          colSpan={headCells.length - 1}
                          align="center"
                        >
                          {isTablet
                            ? "No data"
                            : "This metric is not available due to a lack of data."}
                        </EmptyTableCell>
                      );
                    } else if (
                      row.valuationAdjustment === null &&
                      row.indexName !== "Frontier Markets"
                    ) {
                      if (
                        headCell.id === "nominalEarningGrowth" ||
                        headCell.id === "valuationAdjustment" ||
                        headCell.id === "currencyExchangeImpact"
                      ) {
                        return null;
                      } else if (headCell.id === "unexplained") {
                        return (
                          <EmptyTableCell colSpan={4} align="center">
                            Cannot calculate due to negative earnings
                          </EmptyTableCell>
                        );
                      } else {
                        return (
                          <CustomTableCell
                            key={headCell.id}
                            align="right"
                            bgColor={() => getColor(headCell.diffBackground)}
                          >
                            {row[headCell.id] !== null
                              ? `${row[headCell.id].toFixed(2)} ${
                                  dataUnitsOfMeasurement[headCell.id] ===
                                  "PERCENTAGE"
                                    ? "%"
                                    : ""
                                }`
                              : ""}
                          </CustomTableCell>
                        );
                      }
                    } else if (
                      headCell.id === "action" &&
                      !(
                        row.asOfOneMonthAgo === null &&
                        row.asOfThreeMonthAgo === null &&
                        row.asOfTwoMonthAgo === null &&
                        row.longTermAverage === null &&
                        row.standardDeviation === null
                      ) &&
                      !(
                        row.annualizedNominalReturn === null &&
                        row.beginningPriceToEarningsRatio === null &&
                        row.decadeAvgDividendYield === null &&
                        row.endingPriceToEarningsRatio === null &&
                        row.nominalEarningGrowth === null &&
                        row.valuationAdjustment === null
                      )
                    ) {
                      return (
                        <CustomTableCell
                          key={headCell.id}
                          align="center"
                          bgColor={() => getColor(headCell.diffBackground)}
                        >
                          {row.action}
                        </CustomTableCell>
                      );
                    } else if (
                      !row.noData &&
                      (row.indexName !== "Frontier Markets" ||
                        (row.annualizedNominalReturn !== null &&
                          !valuationSummary) ||
                        (row.asOfOneMonthAgo !== null && valuationSummary))
                    ) {
                      return (
                        <CustomTableCell
                          key={headCell.id}
                          align="right"
                          bgColor={() => getColor(headCell.diffBackground)}
                        >
                          {/* @ts-ignore */}
                          {headCell.id === "longTermAverageStartingYear"
                            ? row[headCell.id]
                            : row[headCell.id] !== null
                            ? `${row[headCell.id]?.toFixed(2)} ${
                                dataUnitsOfMeasurement[headCell.id] ===
                                "PERCENTAGE"
                                  ? "%"
                                  : ""
                              }`
                            : ""}
                        </CustomTableCell>
                      );
                    }
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </HistoricalTable>
      </StickyTableContainer>
    </StickyTableSection>
  );
};

export default StickyHeaderTable;
