import styled from "styled-components";
import { colors } from "application/styles/colors";

type Props = {
  isActive: boolean;
};
export const MobileAuth = styled.div`
  display: flex;
`;

export const Text = styled.span<Props>`
  cursor: pointer;
  position: relative;
  font-size: 20px;
  line-height: 88px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 300)};
  opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
  color: ${colors.white};
  font-family: "Helvetica";

  &:first-child {
    margin-right: 24px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    border-left: ${({ isActive }) =>
      isActive ? "8px solid transparent" : "none"};
    border-right: ${({ isActive }) =>
      isActive ? "8px solid transparent" : "none"};

    border-top: ${({ isActive }) => (isActive ? "8px solid #ffffff" : "none")};
  }
  @media only screen and (max-width: 674px) {
    line-height: 64px;
  }
`;
