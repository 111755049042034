import { FilterData } from "common/bondSpreadSummary/store/model";
import Api from "../Api";

class ApiYieldCurve extends Api {
  static getYieldCurveTable({ date }: { date?: string | null }) {
    return ApiYieldCurve.instance.axiosInst.get<{
      data: string;
    }>("bond/yield-spread-summary/table", { params: { date } });
  }
  static getFilters() {
    return ApiYieldCurve.instance.axiosInst.get<FilterData>(
      "/bond/yield-spread-summary/filters"
    );
  }
  static getChart({
    indexCodeA,
    indexCodeB,
  }: {
    indexCodeA?: string | null;
    indexCodeB?: string | null;
  }) {
    return ApiYieldCurve.instance.axiosInst.get<{
      data: string;
    }>("/bond/yield-spread-summary/chart", {
      params: { indexCodeA, indexCodeB },
    });
  }
}

export default ApiYieldCurve;
