import styled, { css } from "styled-components/macro";

import { colors } from "application/styles/colors";

type Props = {
  isSignUp: boolean;
}
export const Container = styled.div<Props>`
  min-width: 506px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    158.2deg,
    ${colors.navy_blue_default} 0%,
    ${colors.navy_blue_hover} 100%
  );

  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-direction: ${({ isSignUp }) => isSignUp ? "column" : "row"};
    align-items: center;
    justify-content: space-between;
    min-width: unset;
    height: ${({ isSignUp }) => isSignUp ? "124px" : "88px"};
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
  }
`;

export const LogoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1023px) {
    padding: 0 16px;
    min-height: 88px;
    width: 642px;
    margin: 0 auto;
  }

  @media only screen and (max-width: 674px) {
    width: calc(100% - 32px);
    height: 64px;
    min-height: 64px;
  }
`;

export const LogoText = styled.img`
  width: 170px;
  height: 58px;
  margin: 40px 0 0 40px;
  cursor: pointer;

  @media only screen and (max-width: 1023px) {
    width: 112px;
    height: 38px;
    margin: unset;
  }
`;

export const ArrowSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  
  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const ArrowBox = styled.div`
  height: 290px;
  margin-bottom: 80px;
`;

export const CopyrightContainer = styled.div`
  width: 100%;
  bottom: 40px;
  left: 46px;
  position: absolute;

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const CopyrightBlock = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

const copyrightTextCss = css`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
`;

export const CopyrightText = styled.p`
  ${copyrightTextCss};
  color: ${colors.white};

  @media only screen and (max-width: 1023px) {
    color: ${colors.blue_default};
  }
`;

export const CopyrightCircle = styled(CopyrightText)`
  margin: 0 10px;
`;

export const CopyrightAnd = styled(CopyrightText)`
  margin: 0 5px;
`;

export const CopyrightAction = styled.a`
  ${copyrightTextCss};
  text-decoration: none;
  color: ${colors.brown_default};
`;

export const Title = styled.h1`
  font-size: 36px;
  font-weight: 300;
  line-height: 56px;
  font-style: normal;
  color: ${colors.white};
  font-family: "Helvetica";
  padding-left: 40px;
  max-width: 418px;
  margin-bottom: 112px;
`;

export const ProcessWrapper = styled.div`
  flex: 1;
  display: flex;
  padding-right: 40px;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 1023px) {
    width: 642px;
    margin: 0 auto;
    padding: 0;
  }

  @media only screen and (max-width: 647px) {
    padding: 0 16px;
    width: calc(100% - 32px);
  }
`;

export const ProcessList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 1023px) {
    align-items: flex-start;
  }
`;

export const ProcessContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    width: 100%;
    margin: 0 auto;
  }

  @media only screen and (max-width: 647px) {
    width: 100%;
  }
`;

export const ProcessTitle = styled.h2`
  margin-bottom: 40px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.white};

  @media only screen and (max-width: 1023px) {
    display: none;
  }
`;

export const HeaderTitle = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  text-align: right;
  color: ${colors.white};
  position: absolute;
  right: 16px;
  top: 44px;
  transform: translateY(-50%);
  width: 200px;
`;