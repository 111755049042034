import CryptoJS from "crypto-js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "application/store";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import ApiBondExpectedReturnsModeling from "integration/api/ApiBondExpectedReturnsModeling";

class ExpectedReturnsModelingActions {
  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };

  static getExpectedReturnsModelingTableData = createAsyncThunk(
    `expected-returns-modeling/${ResponseHandlerName.GetExpectedReturnsModelingTableData}`,
    async (_, { rejectWithValue, getState }) => {
      const { selectedFilterValues } = (getState() as RootState)
        .expectedReturnsModeling;

      try {
        const res = await ApiBondExpectedReturnsModeling.getTableData({
          corporateDefaultRate: selectedFilterValues.corporateDefaultRate.code,
          creditSpreadChange: selectedFilterValues.creditSpreadChange.code,
          defaultRecoveryRate: selectedFilterValues.defaultRecoveryRate.code,
          interestRateChange: selectedFilterValues.interestRateChange.code,
          timePeriod: selectedFilterValues.timePeriod.code,
        });
        const decryptedData = ExpectedReturnsModelingActions.decryptData(
          res.data.data
        );

        return decryptedData;
      } catch (error) {
        console.log("getExpectedReturnsModelingTableData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getExpectedReturnsModelingFilters = createAsyncThunk(
    `expected-returns-modeling/${ResponseHandlerName.GetExpectedReturnsModelingFilters}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiBondExpectedReturnsModeling.getFilters();
        return res.data;
      } catch (error) {
        console.log("getExpectedStockFilters Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default ExpectedReturnsModelingActions;
