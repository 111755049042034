import React from "react";

import { MSCIBloombergPopUpProps } from "./types";

import { Text, Container, Filter, Header, Title, SubTitle } from "./styles";
import closeIconSource from "application/assets/images/icons/close-icon.svg";

const MSCIBloombergInfoPopUp = ({ onClose }: MSCIBloombergPopUpProps) => {
  return (
    <Filter onClick={onClose}>
      <Container>
        <Header>
          <Title>
            Sourced or calculated from MSCI and Bloomberg Index Services Limited
            data
          </Title>
          <img src={closeIconSource} alt="close" onClick={onClose} />
        </Header>
        <Text>
          <SubTitle>MSCI Statement</SubTitle>
          <br />
          This report contains information (the “Information”) sourced from MSCI
          Inc., its affiliates or information providers (the “MSCI Parties”) and
          may have been used to calculate scores, ratings or other indicators.
          <br />
          <br />
          The Information is for internal use only, and may not be
          reproduced/redisseminated in any form, or used as a basis for or a
          component of any financial instruments or products or indices.
          <br />
          <br />
          The MSCI Parties do not warrant or guarantee the originality, accuracy
          and/or completeness of any data or Information herein and expressly
          disclaim all express or implied warranties, including of
          merchantability and fitness for a particular purpose.
          <br />
          <br />
          The Information is not intended to constitute investment advice or a
          recommendation to make (or refrain from making) any investment
          decision and may not be relied on as such, nor should it be taken as
          an indication or guarantee of any future performance, analysis,
          forecast, or prediction.
          <br />
          <br />
          None of the MSCI Parties shall have any liability for any errors or
          omissions in connection with any data or Information herein, or any
          liability for any direct, indirect, special, punitive, consequential
          or any other damages (including lost profits) even if notified of the
          possibility of such damages.
          <br />
          <br />
          <SubTitle>Bloomberg Statement</SubTitle>
          <br />
          BLOOMBERG® is a trademark and service mark of Bloomberg Finance L.P.
          and its affiliates (collectively “Bloomberg”).
          <br />
          <br />
          Bloomberg or Bloomberg’s licensors own all proprietary rights in the
          Bloomberg Indices. Neither Bloomberg nor Bloomberg’s licensors
          approves or endorses this material, or guarantees the accuracy or
          completeness of any information herein, or makes any warranty, express
          or implied, as to the results to be obtained therefrom and, to the
          maximum extent allowed by law, neither shall have any liability or
          responsibility for injury or damages arising in connection therewith.
          <br />
          <br />
          (ii) Licensee shall not use or distribute Data or Derived Data in any
          manner that, in Licensor’s good faith judgment, adversely affects
          Bloomberg’s (or any of its Data Providers’) ability to license the
          Data or would cause the use and distributions thereof to be a
          functional source of, or a substitute for, data otherwise commercially
          available from Bloomberg or any of its Data Providers.
        </Text>
      </Container>
    </Filter>
  );
};

export default MSCIBloombergInfoPopUp;
