import * as yup from "yup";

import {
  emailValidation,
  passwordValidation,
} from "application/helpers/validations";
import { CommonErrors } from "common/errorService";

export const RegistrationSchema = yup
  .object({
    email: emailValidation,
    password: passwordValidation,
    lastName: yup.string().required(CommonErrors.requiredField),
    firstName: yup.string().required(CommonErrors.requiredField),
  })
  .required();

export const RegistrationBillingSchema = yup
  .object({
    addressLine1: yup.string().required(CommonErrors.requiredField),
    city: yup.string().required(CommonErrors.requiredField),
    country: yup.string().required(CommonErrors.requiredField),
    state: yup.string().required(CommonErrors.requiredField),
    postalCode: yup.string().required(CommonErrors.requiredField),
  })
  .required();
