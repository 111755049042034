import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { store } from "application/store";
import {
  FilterDataItem,
  BondYieldCurve,
  IndexPair,
  BondIndicesData,
} from "./model";
import BondYieldCurveActions from "./actions";

const initialIndicesData: BondIndicesData = {
  average: 0,
  data: [],
  movingAverageData: [],
  unitOfMeasurement: "ABSOLUTE",
  standardDeviationLevels: [],
};

const initialState: BondYieldCurve = {
  tableData: {},
  indicesData: initialIndicesData,
  filters: { startDate: "", endDate: "" },
  selectedMetric: {} as FilterDataItem,
  indexPair: { indexCodeA: null, indexCodeB: null },
};

export const bondYieldCurveSlice = createSlice({
  name: "yield-curve",
  initialState,
  reducers: {
    setFilterMetric: (state, action: PayloadAction<FilterDataItem>) => {
      state.selectedMetric = action.payload;
    },
    setSelectedIndexPair: (state, action: PayloadAction<IndexPair>) => {
      state.indexPair = action.payload;
    },
    resetYieldCurveIndiciesData: (state) => {
      state.indicesData = initialIndicesData;
    },
    reset: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    // getTableData
    builder.addCase(
      BondYieldCurveActions.getYieldCurveTableData.fulfilled,
      (state, action) => {
        state.tableData = action.payload;
      }
    );
    builder.addCase(
      BondYieldCurveActions.getFilters.fulfilled,
      (state, action) => {
        state.filters = action.payload;
      }
    );
    builder.addCase(
      BondYieldCurveActions.getCharts.fulfilled,
      (state, action) => {
        state.indicesData = action.payload;
      }
    );
  },
});

export default bondYieldCurveSlice.reducer;
export const yieldCurveSelector = () => store.getState().yieldCurveSelector;
export const { reset, setFilterMetric, setSelectedIndexPair, resetYieldCurveIndiciesData } =
  bondYieldCurveSlice.actions;
