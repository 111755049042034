import styled from "styled-components/macro";
import { colors } from "application/styles/colors";
import { ReactComponent as ArrowBackIcon } from "application/assets/images/icons/arrow-back.svg";

interface CalculateButtonProps {
  isDisabled: boolean;
}

interface DateRangeProps {
  isError?: boolean;
}

interface CompareProps {
  compare?: boolean;
}

const mediaStep_1 = 1160;
const mediaStep_2 = 1023;
const mediaStep_3 = 940;
const mediaStep_4 = 760;
const mediaStep_5 = 500;

export const Container = styled.div`
  position: sticky;
  top: -75px;
  z-index: 10;
  background: white;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  margin: 20px 0;
  height: 160px;

  @media only screen and (max-width: ${mediaStep_2}px) {
    width: 100%;
    padding: 0 0 16px 0;
    border-top: none;
    border-left: none;
    border-right: none;
    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 16px);
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background: #fff;
    }
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    flex-direction: column;
  }

  @media only screen and (max-width: ${mediaStep_5}px) {
    top: -139px;
    height: fit-content;
  }
`;

export const FiltersContainer = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 36px;

  @media only screen and (max-width: ${mediaStep_1}px) {
    gap: 31.3px;
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    gap: 12px;
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    gap: 8px;
  }

  @media only screen and (max-width: ${mediaStep_4}px) {
    grid-template-rows: 2;
  }

  @media only screen and (max-width: ${mediaStep_5}px) {
    grid-template-rows: 4;
  }
`;

export const DropGroupStyled = styled.div`
  box-sizing: border-box;
  .drop-group-container {
    width: 100%;
  }

  .drop-group-selected {
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      color: ${colors.black};
    }
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    .drop-group-selected {
      height: 44px;
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .drop-group-label {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 4px 4px;
    }
  }

  @media only screen and (max-width: ${mediaStep_3}px) {
    .drop-group-selected {
      p {
        font-size: 14px;
      }
    }

    .drop-group-label {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media only screen and (max-width: ${mediaStep_4}px) {
    width: 100%;
    grid-column: span 2;
    .drop-group-wrapper {
      width: 100%;
      grid-column: span 2;
    }
  }

  @media only screen and (max-width: ${mediaStep_5}px) {
    width: 100%;
    grid-column: span 4;
    .drop-group-wrapper {
      width: 100%;
      grid-column: span 4;
    }
  }
`;

export const DatePickerStyled = styled.div`
  @media (max-width: ${mediaStep_4}px) {
    grid-column: span 2;
  }

  @media (max-width: ${mediaStep_5}px) {
    grid-column: span 4;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  justify-content: space-between;
  align-items: center;
`;

export const DateRangeStyled = styled.div<DateRangeProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${colors.black};
  background-color: ${({ isError }) =>
    isError ? colors.noDataRow : "transparent"};
  font-family: Times;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;

  span {
    font-family: "Helvetica";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: ${colors.font_grey};

    @media only screen and (max-width: ${mediaStep_4}px) {
      font-size: 10px;
      line-height: 14px;
    }

    @media only screen and (max-width: ${mediaStep_5}px) {
      line-height: 10px;
    }
  }

  @media only screen and (max-width: ${mediaStep_2}px) {
    font-size: 18px;
    line-height: 36px;
  }

  @media only screen and (max-width: ${mediaStep_4}px) {
    font-size: 12px;
    line-height: 30px;
  }

  @media only screen and (max-width: ${mediaStep_5}px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const CalculateButton = styled.button<CalculateButtonProps>`
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  grid-column: 4;
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  width: 160px;
  height: 44px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border: unset;
  background: ${({ isDisabled }) =>
    isDisabled ? colors.grey_500 : colors.ochre};
  color: ${colors.white};
  text-align: center;
  font-family: "Helvetica";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
`;

export const CompareButton = styled.button<CompareProps>`
  width: 160px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: ${colors.ochre};
  color: ${colors.white};
  border: none;
  padding: 14px 16px;
  cursor: pointer;
  text-transform: uppercase;

  &[disabled] {
    background: ${colors.grey_500};
  }

  @media (max-width: 647px) {
    display: ${({ compare }) => (compare ? "none" : "unset")};
  }
`;

export const ArrowBackContainer = styled.div`
  display: none;
  background: ${colors.ochre};

  @media (max-width: 647px) {
    display: block;
  }
`;

export const ArrowBack = styled(ArrowBackIcon)`
  padding: 10px;
  stroke: ${colors.font_grey};
`;
