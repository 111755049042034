import { FilterData } from "common/valuationSummary/store/model";
import Api from "../Api";

class ApiValuationSummary extends Api {
  static getValuationSummary({ metric }: { metric?: string }) {
    return ApiValuationSummary.instance.axiosInst.get<{}>(
      "/stock/valuation-and-earnings",
      { params: { metric } }
    );
  }
  static getMetrics({ tableView }: { tableView?: string }) {
    return ApiValuationSummary.instance.axiosInst.get<FilterData>("/metrics", {
      params: { tableView },
    });
  }
}

export default ApiValuationSummary;
