import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { FormProps } from "./types";
import AuthActions from "../store/actions";
import ApiUser from "integration/api/ApiUser";
import { setNotify } from "common/notify/slice";
import { UserStatusEnum } from "integration/api/ApiUser/types";
import { useAppDispatch, useAppSelector } from "application/store";
import { authSelector, setAuthenticated } from "common/auth/store/slice";
import { NotifyMassages } from "application/components/NotifyPopUp/constants";
import PrimaryButton from "application/components/PrimaryButton";
import { PaymentError } from "./styles";
import { AuthBottomWrapper } from "common/auth/styles";
import { PathNames } from "../../../application/routes";
import MobileFooter from "../../../application/components/MobileFooter";
import MobileCopyrightBlock from "../../../application/components/MobileCopyright";
import { useMediaQuery, useTheme } from "@mui/material";
import { MobileConfirmButton } from "../signUp/styles";

const CHECK_STATUS_DELAY = 2000;

const Form = ({ type, userId }: FormProps) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const dispatch = useAppDispatch();
  const intervalId = useRef<NodeJS.Timeout>();
  const {
    signUpUserInfo: {
      registrationStep: { email, password },
    },
  } = useAppSelector(authSelector);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const handleSubmit = async (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setError("");
    setLoading(true);

    //On sign up we use trial period with payment after it ends
    //So we need just set up payment method without payment
    const result =
      type === "sign-up"
        ? await stripe.confirmSetup({
            elements,
            redirect: "if_required",
          })
        : await stripe.confirmPayment({
            elements,
            redirect: "if_required",
          });

    if (result.error) {
      setLoading(false);
      setError(result.error.message || "");
    } else {
      const id = setInterval(checkUserStatus, CHECK_STATUS_DELAY);
      intervalId.current = id;
    }
  };

  const checkUserStatus = async () => {
    try {
      const {
        data: { userStatus },
      } = await ApiUser.getUserInfo(userId);
      if (userStatus === UserStatusEnum.ACTIVE) {
        removeInterval();
        await handleSuccess();
      }
    } catch (error) {
      console.log("checkUserStatus Error:", error);
    }
  };

  const handleSuccess = async () => {
    switch (type) {
      case "sign-up":
        await dispatch(AuthActions.singIn({ email, password })).then(() => {
          window.sessionStorage.clear();
          navigate(PathNames.home);
        });
        break;
      case "update-subscription":
        dispatch(setAuthenticated(true));
        dispatch(
          setNotify({
            visible: true,
            type: "success",
            text: NotifyMassages.signInSuccess,
          })
        );
        window.sessionStorage.clear();
        navigate(PathNames.home);
        break;
      default:
        break;
    }
  };

  const removeInterval = () => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  };

  useEffect(() => {
    return () => {
      removeInterval();
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        {error && <PaymentError>{error}</PaymentError>}
        {!isMobile && (
          <AuthBottomWrapper>
            <PrimaryButton
              title="Back"
              btn_type="light"
              disabled={loading}
              onClick={() => navigate(-1)}
            />
            <PrimaryButton type="submit" title="Subscribe" loading={loading} />
          </AuthBottomWrapper>
        )}
        {isTablet && (
          <MobileFooter>
            {isMobile && (
              <MobileConfirmButton>
                <AuthBottomWrapper>
                  <PrimaryButton
                    title="Back"
                    btn_type="light"
                    disabled={loading}
                    onClick={() => navigate(-1)}
                  />
                  <PrimaryButton
                    type="submit"
                    title="Subscribe"
                    loading={loading}
                  />
                </AuthBottomWrapper>
              </MobileConfirmButton>
            )}
            <MobileCopyrightBlock />
          </MobileFooter>
        )}
      </form>
    </>
  );
};

export default Form;
