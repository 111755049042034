import React, { useEffect } from "react";

import { notifySelector, setNotify } from "common/notify/slice";
import { useAppDispatch, useAppSelector } from "application/store";

import errorSource from "application/assets/images/icons/error.svg";
import { Text, Container, InfoIcon, CloseIcon, InfoIconAccept } from "./styles";

const NotifyPopUp = () => {
  const dispatch = useAppDispatch();
  const { visible, text, type } = useAppSelector(notifySelector).data;

  const handleClose = () => {
    dispatch(
      setNotify({
        visible: false,
        text: "",
        type: "success",
      })
    );
  };

  const hideNotify = () => {
    dispatch(setNotify({ visible: false, text: "", type: "success" }));
  };

  useEffect(() => {
    if (visible) {
      setTimeout(hideNotify, 3000);
    }
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <Container type={type}>
      {type === "success" ? (
        <InfoIconAccept />
      ) : (
        <InfoIcon src={errorSource} alt="info icon" />
      )}

      <Text type={type}>{text}</Text>
      <CloseIcon type={type} onClick={handleClose} />
    </Container>
  );
};

export default NotifyPopUp;
