import React from "react";
import { BillingError, ErrorIcon, ErrorWrapper } from "./styles";

const PaymentError = () => {
  return (
    <BillingError>
      <ErrorWrapper>
        <ErrorIcon />
        <p>An internal error occurred, please try again</p>
      </ErrorWrapper>
    </BillingError>
  );
};

export default PaymentError;