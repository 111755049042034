import styled from "styled-components";
import { TextField } from "@mui/material";

import { colors } from "application/styles/colors";

export const Input = styled(TextField)`
  width: 100%;
  background-color: ${colors.stripe_grey_light};
  input {
    padding: 12px;
    font-family: "Helvetica";
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
  fieldset {
    border: none;
  }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 4px;
  /* Font style */
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  font-family: "Helvetica";
  color: ${colors.font_grey};
`;
