import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import { Elements } from '@stripe/react-stripe-js';
import {loadStripe, StripeElementsOptions} from '@stripe/stripe-js';
import { AddressElement } from '@stripe/react-stripe-js';

import { postcodeValidator } from 'postcode-validator';

import AuthActions from "../../store/actions";
import { PathNames } from "application/routes";
import { authSelector, setBillingAddressError } from "common/auth/store/slice";
import { useAppDispatch, useAppSelector } from "application/store";
import { requestSelector } from "common/responseHandlerService/slice";
import { UpdateUserBillingAddressParams } from "integration/api/ApiAuth/types";

import SideBar from "features/side-bar";
import PrimaryButton from "application/components/PrimaryButton";

import {
  AuthWrapper,
  AuthContainer,
  AuthBottomWrapper,
  MobileConfirmButton,
} from "../../styles";
import { SignUpTitle, SignUpContent } from "../styles";
import {useMediaQuery, useTheme} from "@mui/material";
import MobileFooter from "../../../../application/components/MobileFooter";
import MobileCopyrightBlock from "../../../../application/components/MobileCopyright";
import BillingAddressError from "../../../../application/components/BillingAddressError";
import { colors } from "../../../../application/styles/colors";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY || "");

const BillingData = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { signUpUserInfo, billingAddressError } = useAppSelector(authSelector);
  const { updateUserBillingAddress } = useAppSelector(requestSelector);

  const [formData, setFormData] = useState<UpdateUserBillingAddressParams>({
    addressLine1: "",
    addressLine2: "",
    country: "US",
    city: "",
    state: "",
    postalCode: ""
  });

  useEffect(() => {
    const accountInfo = window.sessionStorage.getItem("accountInfo");

    if (!accountInfo) {
      navigate(PathNames.registration);
    }

    setTimeout(() => {
      window.scrollTo({top: 0})
    }, 300);
  }, []);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const onSubmit = async () => {
    // validation only US case for Tax
    if (
      (formData.country &&
      formData.city &&
      formData.state) ||
      (formData.country &&
      formData.postalCode &&
      postcodeValidator(formData.postalCode, formData.country))
    ) {
      dispatch(
        AuthActions.updateUserBillingAddress(formData)
      ).then((res) => {
        if (res.type.endsWith("/fulfilled")) {
          handleBillingAddressError();
          navigate(PathNames.registrationSubscriptionPlan);
        }
      });
    } else {
      dispatch(setBillingAddressError(true));
    }
  };

  useEffect(() => {
    dispatch(AuthActions.getCountries());
  }, []);

  const handleBillingAddressError = () => {
    dispatch(setBillingAddressError(false));
  };

  const options: StripeElementsOptions = {
    appearance: {
      variables: {
        borderRadius: "0px",
        fontFamily: "Helvetica",
        colorDanger: colors.red,
        colorText: colors.font_grey,
        colorBackground: colors.white,
        colorPrimary: colors.blue_hover,
      },
      rules: {
        ".Label": {
          fontFamily: "Helvetica",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "12px",
          lineHeight: "16px",
          color: colors.font_grey,
        },
        ".Input": {
          backgroundColor: colors.stripe_grey_light,
          padding: "12px",
        },
        ".Input--empty": {
          backgroundColor: colors.stripe_grey_light,
          padding: "12px",
        },
        ".Input:focus": {
          boxShadow: "none",
          outline: "none",
          borderColor: "none",
        },
        ".Input::placeholder": {
          fontFamily: 'Helvetica',
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "20px",
          color: colors.grey_500,
        }
      }
    },
  };

  return (
    <AuthWrapper>
      <SideBar type="sign-up" activeStep={2} />
      <AuthContainer isSignUp>
        <SignUpContent>
          <SignUpTitle>Billing Address</SignUpTitle>
          <Elements stripe={stripe} options={options}>
            <AddressElement
              className="AddressForm"
              onChange={(event) => {
                const data = event.value.address;

                const newData: UpdateUserBillingAddressParams = {
                  addressLine1: data.line1,
                  addressLine2: data.line2 || "",
                  country: data.country,
                  city: data.city,
                  state: data.state,
                  postalCode: data.postal_code
                }
                setFormData(newData);
              }}
              options={{
                mode: 'billing',
                defaultValues: {
                  name: "",
                  address: {
                    line1: signUpUserInfo.addressStep.addressLine1,
                    line2: signUpUserInfo.addressStep.addressLine2,
                    city: signUpUserInfo.addressStep.city,
                    state: signUpUserInfo.addressStep.state,
                    postal_code: signUpUserInfo.addressStep.postalCode,
                    country: signUpUserInfo.addressStep.country,
                  },
                },
              }}
            />
          </Elements>

          {!isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                btn_type="light"
                title="Back"
                onClick={() => {
                  handleBillingAddressError();
                  navigate(-1);
                }}
              />
              <PrimaryButton
                title="Continue"
                onClick={onSubmit}
                loading={updateUserBillingAddress.loading}
              />
            </AuthBottomWrapper>
          )}
        </SignUpContent>
        {isTablet && (
          <MobileFooter>
            {isMobile && (
              <MobileConfirmButton>
                <AuthBottomWrapper>
                  <PrimaryButton
                    btn_type="light"
                    title="Back"
                    onClick={() => {
                      handleBillingAddressError();
                      navigate(-1);
                    }}
                  />
                  <PrimaryButton
                    title="Continue"
                    onClick={onSubmit}
                    loading={updateUserBillingAddress.loading}
                  />
                </AuthBottomWrapper>
              </MobileConfirmButton>
            )}
            <MobileCopyrightBlock />
          </MobileFooter>
        )}
      </AuthContainer>
      {billingAddressError && (
        <BillingAddressError onClick={handleBillingAddressError} />
      )}
    </AuthWrapper>
  );
};

export default BillingData;
