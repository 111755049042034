import Api from "../Api";
import { SubscriptionInfo } from "common/valuationReports/store/model";
import { GetCustomerPortalLinkResponse, GetUserInfoResponse } from "./types";

class ApiUser extends Api {
  static getUserInfo(userId: string) {
    return ApiUser.instance.axiosInst.get<GetUserInfoResponse>(
      `/users/${userId}/info`
    );
  }

  static getCustomerPortalLink(userId: string) {
    return ApiUser.instance.axiosInst.get<GetCustomerPortalLinkResponse>(
      "/portal",
      {
        params: { userId },
      }
    );
  }

  static getSubscriptionInfo(userId: string, type = "LAST") {
    return ApiUser.instance.axiosInst.post<SubscriptionInfo>(
      "subscriptions/search",
      {
        userId,
        type,
      }
    );
  }
}

export default ApiUser;
