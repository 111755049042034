import styled from "styled-components/macro";

import { colors } from "application/styles/colors";

interface StyledProps {
  progress?: number;
}

export const ProcessLineContainer = styled.div`
  margin: 9px 0 9px 12px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 1023px) {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0;
  }
`;

export const ProcessActive = styled.div<StyledProps>`
  top: 0;
  width: 1px;
  position: absolute;
  transition: all ease-in 1s;
  height: ${({ progress }) => progress}%;
  background-color: ${colors.brown_hover};

  @media only screen and (max-width: 1023px) {
    width: ${({ progress }) => progress}%;
    height: 1px;
    top: 2px;
  }
`;

export const ProcessActiveCircle = styled.div`
  width: 18px;
  height: 18px;
  bottom: -9px;
  left: -8.5px;
  position: absolute;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(192, 107, 33, 0.4);

  @media only screen and (max-width: 1023px) {
    left: unset;
    right: -10.5px;
  }
`;

export const ProcessActiveCircleInside = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1px solid #051638;
  background-color: ${colors.brown_hover};
`;
