import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import HomeActions from "./actions";
import { store } from "application/store";
import AuthActions from "common/auth/store/actions";
import { FilterDataItem, ValuationReportState } from "./model";

const initialState: ValuationReportState = {
  metrics: {},
  indices: {},
  indicesData: {
    average: 0,
    data: [],
    movingAverageData: [],
    unitOfMeasurement: "ABSOLUTE",
    standardDeviationLevels: [],
  },
  filters: {
    selectedMetric: { code: "", name: "" },
    selectedIndices: { code: "", name: "" },
  },
  subscriptionInfo: {
    price: null,
    currency: "",
    interval: "month",
    nextPayment: "",
  },
};

export const bondValuationReportSlice = createSlice({
  name: "bond-stock-valuation",
  initialState,
  reducers: {
    setFilterMetric: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedMetric = action.payload;
    },
    setFilterIndices: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedIndices = action.payload;
    },
    resetState: (state) => (state = initialState),
  },
  extraReducers: (builder) => {
    // getMetrics
    builder.addCase(HomeActions.getMetrics.fulfilled, (state, action) => {
      state.metrics = action.payload;
    });

    // getIndices
    builder.addCase(HomeActions.getIndices.fulfilled, (state, action) => {
      state.indices = action.payload;
    });

    // getIndicesData
    builder.addCase(HomeActions.getIndicesData.fulfilled, (state, action) => {
      state.indicesData = action.payload;
    });

    // getSubscriptionInfo
    builder.addCase(
      HomeActions.getSubscriptionInfo.fulfilled,
      (state, action) => {
        state.subscriptionInfo = action.payload;
      }
    );

    // signOut
    builder.addCase(AuthActions.signOut.pending, () => initialState);
  },
});

export default bondValuationReportSlice.reducer;
export const homeSelector = () => store.getState().bondValuationReport;
export const { setFilterIndices, setFilterMetric, resetState } =
  bondValuationReportSlice.actions;
