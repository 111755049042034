import React from "react";
import {
  FooterWrapper,
  Container,
  LinksSection,
  FooterLink,
  FooterGroup,
} from "./styles";
import { Typography } from "@mui/material";
import { applicationConfig } from "../../config/applicationConfig";
import ReportLastUpdateLine from "../ReportLastUpdateLine";

const Footer = ({ date }: { date?: string }) => {
  return (
    <FooterGroup>
      {date && <ReportLastUpdateLine date={date} />}
      <FooterWrapper className="global-footer">
        <Container>
          <LinksSection>
            <FooterLink
              href={applicationConfig.assetcamp}
              variant="body2"
              underline="none"
              target="_blank"
            >
              Asset Camp
            </FooterLink>
            <FooterLink
              href={applicationConfig.assetcamp_about}
              variant="body2"
              underline="none"
              target="_blank"
            >
              About
            </FooterLink>
            <FooterLink
              href={applicationConfig.privacy_policy}
              variant="body2"
              underline="none"
              target="_blank"
            >
              Disclosures
            </FooterLink>
            <FooterLink
              href={applicationConfig.cookies}
              variant="body2"
              underline="none"
              target="_blank"
            >
              Privacy and cookies
            </FooterLink>
          </LinksSection>
          <Typography variant="body2">
            Copyright © {new Date().getFullYear()}
          </Typography>
        </Container>
      </FooterWrapper>
    </FooterGroup>
  );
};

export default Footer;
