import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ExpectedReturnsModelingActions from "./actions";
import { store } from "application/store";
import { ExpectedReturnsModelingPage, SelectedFilter } from "./model";

const initialState: ExpectedReturnsModelingPage = {
  tableData: undefined,
  filters: {
    corporateDefaultRate: { code: "", name: "", filterValues: [] },
    creditSpreadChange: { code: "", name: "", filterValues: [] },
    defaultRecoveryRate: { code: "", name: "", filterValues: [] },
    interestRateChange: { code: "", name: "", filterValues: [] },
    timePeriod: { code: "", name: "", filterValues: [] },
  },
  selectedFilterValues: {
    corporateDefaultRate: { code: "", name: "" },
    creditSpreadChange: { code: "", name: "" },
    defaultRecoveryRate: { code: "", name: "" },
    interestRateChange: { code: "", name: "" },
    timePeriod: { code: "", name: "" },
  },
};

export const expectedReturnsModelingSlice = createSlice({
  name: "expected-returns-modeling",
  initialState,
  reducers: {
    setCorporateDefaultRateFilter: (
      state,
      action: PayloadAction<SelectedFilter>
    ) => {
      state.selectedFilterValues.corporateDefaultRate = action.payload;
    },
    setCreditSpreadChangeFilter: (
      state,
      action: PayloadAction<SelectedFilter>
    ) => {
      state.selectedFilterValues.creditSpreadChange = action.payload;
    },
    setDefaultRecoveryRateFilter: (
      state,
      action: PayloadAction<SelectedFilter>
    ) => {
      state.selectedFilterValues.defaultRecoveryRate = action.payload;
    },
    setInterestRateChangeFilter: (
      state,
      action: PayloadAction<SelectedFilter>
    ) => {
      state.selectedFilterValues.interestRateChange = action.payload;
    },
    setTimePeriodFilter: (state, action: PayloadAction<SelectedFilter>) => {
      state.selectedFilterValues.timePeriod = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getFilters and set initial values of filters
    builder.addCase(
      ExpectedReturnsModelingActions.getExpectedReturnsModelingFilters
        .fulfilled,
      (state, action) => {
        state.filters = action.payload;
        state.selectedFilterValues.corporateDefaultRate =
          action.payload.corporateDefaultRate.filterValues.find(
            (f) => f.isDefault
          ) ?? action.payload.corporateDefaultRate.filterValues[0];
        state.selectedFilterValues.creditSpreadChange =
          action.payload.creditSpreadChange.filterValues.find(
            (f) => f.isDefault
          ) ?? action.payload.creditSpreadChange.filterValues[0];
        state.selectedFilterValues.defaultRecoveryRate =
          action.payload.defaultRecoveryRate.filterValues.find(
            (f) => f.isDefault
          ) ?? action.payload.defaultRecoveryRate.filterValues[0];
        state.selectedFilterValues.interestRateChange =
          action.payload.interestRateChange.filterValues.find(
            (f) => f.isDefault
          ) ?? action.payload.interestRateChange.filterValues[0];
        state.selectedFilterValues.timePeriod =
          action.payload.timePeriod.filterValues.find((f) => f.isDefault) ??
          action.payload.timePeriod.filterValues[0];
      }
    );
    //getTableData
    builder.addCase(
      ExpectedReturnsModelingActions.getExpectedReturnsModelingTableData
        .fulfilled,
      (state, action) => {
        state.tableData = action.payload;
      }
    );
  },
});

export default expectedReturnsModelingSlice.reducer;
export const homeSelector = () => store.getState().expectedReturnsModeling;
export const {
  setCorporateDefaultRateFilter,
  setCreditSpreadChangeFilter,
  setDefaultRecoveryRateFilter,
  setInterestRateChangeFilter,
  setTimePeriodFilter,
} = expectedReturnsModelingSlice.actions;
