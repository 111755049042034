import styled from "styled-components";
import { TableCell, TableContainer, Table } from "@mui/material";
import { colors } from "application/styles/colors";

interface StyledProps {
  diffBackground?: boolean;
  minWidth?: boolean;
  firstBorderLeft?: boolean;
  selected?: boolean;
  isCompare?: boolean;
  bgColor?: () => string;
  isSorting?: boolean;
}

interface Props {
  onTouchStart?: (e: TouchEvent) => void;
  onTouchMove?: (e: TouchEvent) => void;
  onTouchEnd?: (e: TouchEvent) => void;
}

interface BorderProps {
  isBorder: boolean;
  id: string;
}

export const StickyTableSection = styled.div`
  @media only screen and (max-width: 1160px) {
    overflow-x: auto !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (max-width: 1023px) {
    width: calc(100% + 16px);
  }
`;

export const StickyTableContainer = styled(TableContainer)`
  overflow-x: unset !important;
`;

export const PerformanceSummaryTable = styled(Table)<Props>`
  border: 1px solid ${colors.grey_300};
  position: sticky;
  z-index: 0;
  thead {
    tr {
      position: sticky;
      z-index: 4;
    }
  }
  tbody {
    tr {
      position: sticky;
      z-index: -1;
    }
  }

  tr {
    &:hover {
      background-color: unset;
    }
  }

  td:first-child {
    position: sticky;
    left: 0;
    z-index: -1;
    border-left: 1px solid rgba(224, 224, 224, 1);
  }

  th:first-child {
    position: sticky;
    left: 0;
    z-index: 999;
  }

  @media only screen and (max-width: 1160px) {
    td {
      &:first-child {
        padding-left: 8px !important;
        filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
        border-left: 1px solid rgba(224, 224, 224, 1);
        z-index: 8;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    td {
      width: 100px;
      border-right: unset;
      z-index: 7;
    }

    th {
      width: 100px;

      &:first-child {
        padding-left: 8px !important;
        filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.25));
      }
    }
  }
`;

export const StickyTableCell = styled(TableCell)<StyledProps>`
  position: relative;
  left: ${({ minWidth }) => (minWidth ? 0 : "unset")};
  background-color: ${({ diffBackground, isSorting }) =>
    diffBackground
      ? isSorting
        ? colors.annualCellSorting
        : colors.blue_100
      : isSorting
      ? colors.cellSorting
      : colors.grey_100} !important;
  z-index: ${({ minWidth }) => (minWidth ? 9 : 8)};
  border-left: 1px solid ${colors.grey_300};
  min-width: ${({ minWidth }) => (minWidth ? "130px" : "auto")};
  padding: 24px 14px;
  font-size: 12px !important;
  line-height: 16px !important;
  font-weight: bold !important;

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${({ minWidth }) => (!minWidth ? "80px" : "auto")};
  }

  @media only screen and (max-width: 1023px) {
    min-width: 100px;
    padding: ${({ minWidth }) =>
      minWidth ? "0 16px 0 8px" : "16px"} !important;

    span {
      width: ${({ minWidth }) => (!minWidth ? "100px" : "auto")};
    }
  }
`;

export const CustomTableCell = styled(TableCell)<StyledProps>`
  font-size: 18px !important;
  line-height: 20px !important;
  color: rgba(0, 0, 0, 0.87);
  background: ${({ bgColor }) => bgColor};
  font-weight: ${({ diffBackground }) =>
    diffBackground ? "700 !important" : 400};
  padding: 0 16px !important;
  height: 56px;

  @media only screen and (max-width: 1023px) {
    font-size: 12px !important;
    line-height: 14px !important;
    height: 44px;
  }
`;

export const EmptyTableCell = styled(TableCell)<StyledProps>`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px !important;
  color: ${colors.brown_hover} !important;
  background: ${colors.noDataRow};
`;

export const BorderSubElement = styled.div<BorderProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: ${({ isBorder }) => (isBorder ? "2px solid #BDBDBD" : "none")};
  border-bottom: ${({ isBorder }) => (isBorder ? "2px solid #BDBDBD" : "none")};
  border-left: ${({ id }) =>
    id === "decadeAvgDividendYield" ? "2px solid #BDBDBD" : "none"};
  border-right: ${({ id }) =>
    id === "annualizedNominalReturn" ? "2px solid #BDBDBD" : "none"};
  box-sizing: border-box;
`;

export const Arrow = styled.img`
  margin-left: 8px;
  width: 12px;
`;

export const CellCheckoutBox = styled.div`
  display: flex;
  align-items: center;
  font-family: "Helvetica";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  @media only screen and (max-width: 1023px) {
    font-size: 12px !important;
    line-height: 14px !important;
  }
`;

export const EmptyGroupCell = styled(TableCell)<StyledProps>`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  color: ${colors.black}; !important;
  background: #ebebeb;
`;
