import React from "react";

import { BloombergPopUpProps } from "./types";

import { Text, Container, Filter, Header, Title } from "./styles";
import closeIconSource from "application/assets/images/icons/close-icon.svg";

const BloombergInfoPopUp = ({ onClose }: BloombergPopUpProps) => {
  return (
    <Filter onClick={onClose}>
      <Container>
        <Header>
          <Title>Sourced or calculated from Bloomberg Index Services Limited data</Title>
          <img src={closeIconSource} alt="close" onClick={onClose} />
        </Header>
        <Text>
          BLOOMBERG® is a trademark and service mark of Bloomberg Finance L.P. and its affiliates (collectively
          “Bloomberg”).
          <br/>
          <br/>
          Bloomberg or Bloomberg’s licensors own all proprietary rights in the Bloomberg Indices.
          <br/>
          <br/>
          Neither Bloomberg nor Bloomberg’s licensors approves or endorses this material, or guarantees the
          accuracy or completeness of any information herein, or makes any warranty, express or implied, as to the
          results to be obtained therefrom and, to the maximum extent allowed by law, neither shall have any liability
          or responsibility for injury or damages arising in connection therewith.” and (ii) Licensee shall not use or
          distribute Data or Derived Data in any manner that, in Licensor’s good faith judgment, adversely affects
          Bloomberg’s (or any of its Data Providers’) ability to license the Data or would cause the use and
          distributions thereof to be a functional source of, or a substitute for, data otherwise commercially available
          from Bloomberg or any of its Data Providers.
        </Text>
      </Container>
    </Filter>
  );
};

export default BloombergInfoPopUp;
