import React from "react";
import { Container } from "./styles";

interface Props {
  name: string;
}

const UserAvatar = ({ name }: Props) => {
  return (
    <Container>
      <h4>{name}</h4>
    </Container>
  );
};

export default UserAvatar;
