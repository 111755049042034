import styled from "styled-components";

import { colors } from "application/styles/colors";

interface StyledProps {
  error?: boolean;
  checked: boolean;
}

export const CheckBoxContainer = styled.div<StyledProps>`
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  min-width: 16px;
  height: 16px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ checked }) =>
    checked ? colors.blue_default : "transparent"};
  border: ${({ error, checked }) =>
    error
      ? `2px solid ${colors.red}`
      : checked
      ? "none"
      : `1px solid ${colors.stroke_grey}`};
`;
