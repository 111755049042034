import moment, { Moment } from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  DescriptionContainer,
  CalculateButton,
  Container,
  DateRangeStyled,
  DropGroupStyled,
  FiltersContainer,
  DatePickerStyled,
  CompareButton,
  ArrowBackContainer,
  ArrowBack,
} from "./styles";
import DatePicker from "../DatePicker";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  ComparisonMetric,
  FilterData,
} from "common/indicesPerformanceSummary/store/models";
import {
  indicesPerformanceSummarySelector,
  setEndingDate,
  setFilterMetric,
} from "common/indicesPerformanceSummary/store/slice";
import IndicesComparisonActions from "common/indicesPerformanceSummary/store/actions";
import DropGroup from "../DropGroup";
import { requestSelector } from "common/responseHandlerService/slice";
import { useMediaQuery, useTheme } from "@mui/material";

const MIN_ALLOWED_DATE = moment("2022-06-01");

interface Props {
  compare: boolean;
  compareLines: () => void;
  selectedCount: number;
}

const ReturnsSummaryFilters = React.forwardRef(
  ({ compare, compareLines, selectedCount }: Props, ref: any) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(1024));
    const dispatch = useAppDispatch();
    const requestData = useAppSelector(requestSelector);
    const { metrics, indicesSummaryData, filters, maxEndingDate } =
      useAppSelector(indicesPerformanceSummarySelector);

    const loading =
      requestData.getIndicesPerformanceSummary.loading ||
      requestData.getMetrics.loading;

    const handleMetricSelect = (item: ComparisonMetric) => {
      dispatch(setFilterMetric(item));
    };

    const handleSetEndingDate = (date: Moment) => {
      const formatedDate = date.format("yyyy-MM");
      dispatch(setEndingDate(formatedDate));
    };

    const handleSubmitCalculation = useCallback(() => {
      if (filters.selectedMetric.code === "") {
        return;
      }
      dispatch(IndicesComparisonActions.getIndicesPerformanceSummaryData());
    }, [filters]);

    useEffect(() => {
      if (!indicesSummaryData.data.length) {
        Promise.all([
          dispatch(IndicesComparisonActions.getDataForFilters()),
        ]).then((resArr) => {
          const metricRes = resArr[0].payload as FilterData;
          handleMetricSelect(metricRes.metrics[0]);
          handleSetEndingDate(moment(metricRes.endDate));
          dispatch(IndicesComparisonActions.getIndicesPerformanceSummaryData());
        });
      }
    }, []);

    const isSubmitCalculationDisabled = useMemo(() => {
      if (filters.selectedMetric.code === "") {
        return true;
      }
      return false;
    }, [filters]);

    return (
      <Container>
        <FiltersContainer>
          <DropGroupStyled>
            <DropGroup
              data={{ Comparison: metrics }}
              label="Metric to compare"
              disabled={loading}
              onSelect={handleMetricSelect}
              selected={filters.selectedMetric}
              placeholder="Metric name goes here"
            />
          </DropGroupStyled>
          <DatePickerStyled>
            <DatePicker
              label="Ending Date"
              minDate={MIN_ALLOWED_DATE}
              maxDate={maxEndingDate ? moment(maxEndingDate) : moment()}
              onChange={handleSetEndingDate}
              value={moment(filters.endingDate)}
            />
          </DatePickerStyled>
          <CalculateButton
            onClick={handleSubmitCalculation}
            disabled={isSubmitCalculationDisabled}
            isDisabled={isSubmitCalculationDisabled}
          >
            Calculate
          </CalculateButton>
        </FiltersContainer>
        <DescriptionContainer ref={ref}>
          <DateRangeStyled>
            {indicesSummaryData.data.length > 0 &&
              `${
                indicesSummaryData.metric.name
              } for the Periods Ending ${moment(
                indicesSummaryData.endingDate
              ).format("MMMM D, YYYY")}`}
            <span>Returns longer than one year are annualized</span>
          </DateRangeStyled>
          {!compare && (
            <CompareButton onClick={compareLines} disabled={selectedCount < 2}>
              COMPARE {isMobile ? "" : " INDEXES"}
            </CompareButton>
          )}
          {compare && (
            <>
              <CompareButton compare={compare} onClick={compareLines}>
                back to full list
              </CompareButton>
              <ArrowBackContainer onClick={compareLines}>
                <ArrowBack />
              </ArrowBackContainer>
            </>
          )}
        </DescriptionContainer>
      </Container>
    );
  }
);

export default ReturnsSummaryFilters;
