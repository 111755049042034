import React from "react";

import { PlanSwitcherProps } from "./types";

import { Switch, Container } from "./styles";

const PlanSwitcher = ({
  data,
  activePlanPriceId,
  onSelect,
}: PlanSwitcherProps) => {
  if (data.length === 0) {
    return <></>;
  }

  const obj = {
    yearProduct: data.find((product) => product.period === "year"),
    monthProduct: data.find((product) => product.period === "month"),
    dayProduct: data.find((product) => product.period === "day")
  };

  const resultObj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

  return (
    <Container>
      {Object.keys(resultObj).map((item: string) => {
        return (
          <Switch
            key={resultObj[item]!.priceId}
            isActive={resultObj[item]!.priceId === activePlanPriceId}
            onClick={() => onSelect(resultObj[item]!.priceId)}
          >
            <p>{resultObj[item]!.period}</p>
        </Switch>
        );
      })}
    </Container>
  );
};

export default PlanSwitcher;
