import styled, { css } from "styled-components/macro";
import { ReactComponent as ArrowBackIcon } from "application/assets/images/icons/arrow-back.svg";
import { colors } from "application/styles/colors";

interface CompareProps {
  compare: boolean;
}

const mediaStep_1 = 1160;
const mediaStep_2 = 1023;
const mediaStep_3 = 940;
const mediaStep_4 = 647;

export const Container = styled.div`
  position: sticky; 
  top: 0; 
  z-index: 10;
  display: flex;
  padding: 16px;
  border-top: 1px solid ${colors.grey_300};
  border-left: 1px solid ${colors.grey_300};
  border-right: 1px solid ${colors.grey_300};
  background: #fff;
  @media only screen and (max-width: ${mediaStep_2}px) {
    padding: 0 0 16px 0;
    border-top: none;
    border-left: none;
    border-right: none;
  }
  @media only screen and (max-width: ${mediaStep_3}px) {
    flex-direction: column;
    padding: unset;
    top: -139px;
    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 16px);
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      background: #fff;
    }
  }
`;

export const DateBlock = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: ${mediaStep_3}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const ActionBlock = styled.div<CompareProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media only screen and (max-width: ${mediaStep_3}px) {
    min-height: 44px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: ${mediaStep_4}px) {
    flex-direction: ${({compare}) => (compare ? "row-reverse" : "row")};
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 64px;
  background-color: ${colors.grey_300};
  @media only screen and (max-width: ${mediaStep_3}px) {
    width: 100%;
    height: 1px;
    margin: 16px 0;
  }
`;

export const PickerContainer = styled.div`
  width: 281px;
  @media only screen and (max-width: ${mediaStep_1}px) {
    width: 220px;
  }
  @media only screen and (max-width: ${mediaStep_3}px) {
    width: 100%;
  }
`;

const textStyle = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Helvetica";
  @media only screen and (max-width: ${mediaStep_1}px) {
    font-size: 14px;
  }
`;

export const UpdateDate = styled.p`
  ${textStyle};
  flex: 1;
  margin-left: 24px;
  @media only screen and (max-width: ${mediaStep_3}px) {
    flex: none;
    margin-left: 0;
    margin-top: 16px;
    br {
      display: none;
    }
  }
`;

export const SelectedText = styled.p`
  ${textStyle};
  margin-left: 31px;
  @media only screen and (max-width: ${mediaStep_3}px) {
    margin-left: 0;
  }
`;

export const ButtonContainer = styled.div<CompareProps>`
  width: 149px;
  
  button[disabled] {
    background: ${colors.grey_500};
  }

  @media only screen and (max-width: ${mediaStep_4}px) {
    display: ${({compare}) => (compare ? "none" : "unset")}
  }
`;

export const ArrowBackContainer = styled.div<CompareProps>`
  display: none;
  background: ${colors.ochre};

  @media only screen and (max-width: ${mediaStep_4}px) {
    display: ${({compare}) => (compare ? "block" : "none")}
  }
`;

export const ArrowBack = styled(ArrowBackIcon)`
  padding: 10px;
  stroke: ${colors.font_grey};
`;