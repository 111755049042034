import styled from "styled-components";

import { colors } from "application/styles/colors";

export const Filter = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(5, 22, 56, 0.7);
`;

export const Container = styled.div`
  width: 486px;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${colors.white};

  @media only screen and (max-width: 525px) {
    margin: 0 16px;
  }
  
  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Name = styled.h3`
  margin-bottom: 8px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.font_grey};
`;

export const Text = styled.p`
  margin-bottom: 24px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.font_grey};
`;
