import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthActions from "../store/actions";
import { ResetSchema } from "./validations";
import { ResetPasswordDataT } from "./types";
import { authSelector } from "../store/slice";
import { PathNames } from "application/routes";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  requestSelector,
  resetErrors,
} from "common/responseHandlerService/slice";

import SideBar from "features/side-bar";
import PrimaryInput from "application/components/PrimaryInput";
import PrimaryButton from "application/components/PrimaryButton";

import {
  ResendText,
  ResetTitle,
  NoCodeContainer,
  ResetDescription,
} from "./styles";
import {
  AuthWrapper,
  AuthContent,
  AuthContainer,
  AuthInputWrapper,
  AuthBottomWrapper,
  AuthActionText,
} from "../styles";
import { useMediaQuery, useTheme } from "@mui/material";
import MobileCopyrightBlock from "../../../application/components/MobileCopyright";
import MobileFooter from "../../../application/components/MobileFooter";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    resetPassword: { loading, error },
  } = useAppSelector(requestSelector);
  const { resetEmailValue } = useAppSelector(authSelector);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordDataT>({
    resolver: yupResolver(ResetSchema),
  });

  const onSubmit = (data: ResetPasswordDataT) => {
    dispatch(AuthActions.resetPassword(data)).then((res) => {
      if (res.type.endsWith("/fulfilled")) {
        navigate(PathNames.login);
      }
    });
  };

  useEffect(() => {
    if (error) {
      setError("code", { message: error });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  return (
    <AuthWrapper>
      <SideBar type="sign-in" recovery />
      <AuthContainer>
        <AuthContent>
          <ResetTitle>Password recovery</ResetTitle>
          <ResetDescription>
            We have sent a password reset code by email to {resetEmailValue}.
            Enter it below to reset your password.
          </ResetDescription>

          <AuthInputWrapper>
            <PrimaryInput
              id="code"
              label="Code"
              type="password"
              register={register}
              placeholder="••••••"
              error={errors.code?.message}
            />
          </AuthInputWrapper>

          <AuthInputWrapper>
            <PrimaryInput
              id="password"
              type="password"
              register={register}
              label="New Password"
              placeholder="New Password"
              error={errors.password?.message}
            />
          </AuthInputWrapper>

          <PrimaryInput
            type="password"
            register={register}
            id="password_repeat"
            label="Enter New Password Again"
            placeholder="Enter New Password Again"
            error={errors.password_repeat?.message}
          />
          {!isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                title="Back"
                btn_type="light"
                onClick={() => navigate(PathNames.login)}
              />
              <PrimaryButton
                title="Change password"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              />
            </AuthBottomWrapper>
          )}
          {isMobile && (
            <NoCodeContainer>
              <ResendText>
                Still no code in your inbox? Check spam folder or
              </ResendText>
              <AuthActionText onClick={() => navigate(PathNames.passwordRecovery)}>
                Resend code
              </AuthActionText>
            </NoCodeContainer>
          )}
        </AuthContent>

        {!isMobile && (
          <NoCodeContainer>
            <ResendText>
              Still no code in your inbox? Check spam folder or
            </ResendText>
            <AuthActionText onClick={() => navigate(PathNames.passwordRecovery)}>
              Resend code
            </AuthActionText>
          </NoCodeContainer>
        )}
      </AuthContainer>
      {isTablet && (
        <MobileFooter>
          {isMobile && (
            <AuthBottomWrapper>
              <PrimaryButton
                title="Back"
                btn_type="light"
                onClick={() => navigate(PathNames.login)}
              />
              <PrimaryButton
                title="Change password"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              />
            </AuthBottomWrapper>
          )}
          <MobileCopyrightBlock />
        </MobileFooter>
      )}
    </AuthWrapper>
  );
};

export default ResetPassword;
