import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import AuthActions from "./actions";
import {AuthState, Tax} from "./model";
import { store } from "application/store";
import { UserStatusEnum } from "integration/api/ApiUser/types";
import getLocalData from "../getLocalData";

const { billingAddress, accountInfo, subscription, userId, priceId, products } = getLocalData();

const initialState: AuthState = {
  isOnLoad: true,
  resetEmailValue: "",
  isAuthenticated: false,
  signUp: {
    userId: userId,
    priceId: priceId,
  },
  stripe: {
    clientSecret: subscription ? subscription.clientSecret : "",
    subscriptionId: subscription ? subscription.subscriptionId : "",
  },
  billingAddressError: false,
  products: products,
  countries: {},
  cognitoData: {
    token: "",
    email: "",
    given_name: "",
    family_name: "",
    "custom:mfru_user_id": "",
  },
  isVisibleSubscriptionDeclinedPopUp: false,

  signUpUserInfo: {
    registrationStep: {
      email: accountInfo ? accountInfo.email : "",
      password: accountInfo ? accountInfo.password : "",
      lastName: accountInfo ? accountInfo.lastName : "",
      firstName: accountInfo ? accountInfo.firstName : "",
      isAgreeToMarketingOffers: accountInfo ? accountInfo.isAgreeToMarketingOffers : false,
    },
    addressStep: {
      city: billingAddress ? billingAddress.city : "",
      state: billingAddress ? billingAddress.state : "",
      country: billingAddress ? billingAddress.country : "",
      postalCode: billingAddress ? billingAddress.postalCode : "",
      addressLine1: billingAddress ? billingAddress.addressLine1 : "",
      addressLine2: billingAddress ? billingAddress.addressLine2 : "",
    },
  },
  customerPortalLink: "",
  subscription: {
    coupon: subscription ? (subscription.coupon ? subscription.coupon : null) : null,
    subscriptionId: subscription ? subscription.subscriptionId : null,
    oldPrice: subscription ? subscription.oldPrice : null,
    newPrice: subscription ? subscription.newPrice : null,
    couponStatus: subscription ? subscription.couponStatus : null,
    discount: subscription ? subscription.discount : null,
    taxes: subscription ? subscription.taxes : [],
    total: subscription ? subscription.total : null,
    noPaymentNeeded: subscription ? subscription.noPaymentNeeded : false
  },
  isPaymentError: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsVisibleSubscriptionDeclinedPopUp: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isVisibleSubscriptionDeclinedPopUp = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setProduct: (state, action: PayloadAction<string>) => {
      state.signUp.priceId = action.payload;
    },
    setDiscount: (state, action: PayloadAction<string>) => {
      state.subscription.coupon = action.payload;
    },
    setDiscountDetails: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.subscription.couponStatus = action.payload.couponStatus;
        state.subscription.discount = action.payload.discount;
        state.subscription.newPrice = action.payload.newPrice;
        state.subscription.oldPrice = action.payload.oldPrice;
        state.subscription.total = action.payload.total;
        state.subscription.subscriptionId = action.payload.subscriptionId;
        state.subscription.noPaymentNeeded = action.payload.noPaymentNeeded;
      }
    },
    setTaxes: (state, action: PayloadAction<Tax[]>) => {
      state.subscription.taxes = action.payload;
    },
    setBillingAddressError: (state, action: PayloadAction<boolean>) => {
      state.billingAddressError = action.payload;
    },
    setPaymentError: (state, action: PayloadAction<boolean>) => {
      state.isPaymentError = action.payload;
    },
    setOnLoad: (state, action: PayloadAction<boolean>) => {
      state.isOnLoad = true;
    },
  },
  extraReducers: (builder) => {
    // onLoad
    builder.addCase(AuthActions.onLoad.fulfilled, (state, { payload }) => {
      state.isOnLoad = false;
      state.cognitoData = payload.cognitoData;
      if (payload.userStatus === UserStatusEnum.ACTIVE) {
        state.isAuthenticated = true;
      }
    });
    builder.addCase(AuthActions.onLoad.rejected, (state, payload) => {
      state.isOnLoad = false;
    });

    // singIn
    builder.addCase(AuthActions.singIn.fulfilled, (state, action) => {
      state.cognitoData = action.payload.cognitoData;
      if (action.payload.userStatus !== UserStatusEnum.ACTIVE) {
        state.isVisibleSubscriptionDeclinedPopUp = true;
      } else {
        state.isAuthenticated = true;
      }
    });

    // signOut
    builder.addCase(AuthActions.signOut.pending, () => ({
      ...initialState,
      isOnLoad: false,
    }));

    // forgotPassword
    builder.addCase(AuthActions.forgotPassword.fulfilled, (state, action) => {
      state.resetEmailValue = action.payload;
    });

    // getCustomerPortalLink
    builder.addCase(
      AuthActions.getCustomerPortalLink.fulfilled,
      (state, action) => {
        state.customerPortalLink = action.payload;
      }
    );

    // Sign Up Start
    // createUser
    builder.addCase(AuthActions.createUser.fulfilled, (state, action) => {
      state.signUp.userId = action.payload.response.userId;
      state.signUpUserInfo.registrationStep = action.payload.formData;
    });

    // updateUserBillingAddress
    builder.addCase(
      AuthActions.updateUserBillingAddress.fulfilled,
      (state, action) => {
        state.signUpUserInfo.addressStep = action.payload.formData;
      }
    );

    // getCountries
    builder.addCase(AuthActions.getCountries.fulfilled, (state, action) => {
      state.countries = action.payload.data;
    });

    // getProducts
    builder.addCase(AuthActions.getProducts.fulfilled, (state, action) => {
      state.products = action.payload.data;
      const yearProduct = action.payload.data.find((product) => product.period === "year");

      if (yearProduct) {
        state.signUp.priceId = priceId ? priceId : yearProduct?.priceId;
      }

      // For update subscription
      if (!state.signUp.userId && state.cognitoData["custom:mfru_user_id"]) {
        state.signUp.userId = state.cognitoData["custom:mfru_user_id"];
      }
    });

    // signUpSubscriptionPlan
    builder.addCase(
      AuthActions.createSubscription.fulfilled,
      (state, action) => {
        state.stripe = action.payload.data;
      }
    );
    // Sign Up End
  },
});

export default authSlice.reducer;
export const authSelector = () => store.getState().auth;
export const {
  setProduct,
  setDiscount,
  setDiscountDetails,
  setTaxes,
  setAuthenticated,
  setIsVisibleSubscriptionDeclinedPopUp,
  setBillingAddressError,
  setPaymentError,
  setOnLoad
} = authSlice.actions;
