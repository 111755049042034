import styled from "styled-components";
import {colors} from "../../styles/colors";
import {ReactComponent as InfoIconSvg} from "../../assets/images/icons/info-icon.svg";

type Props = {
  underTable?: boolean;
}
export const MSCIIWrapper = styled.div<Props>`
  margin-top: ${({ underTable }) => underTable ? "24px" : 0};

  @media (max-width: 1023px) {
    margin-top: 18px;
  }
`;

export const MSCIInfoContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  @media (max-width: 800px) {
    margin-bottom: 12px;
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-style: normal;
  font-family: "Helvetica";
  color: ${colors.font_grey};

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const InfoIcon = styled(InfoIconSvg)`
  margin-left: 4px;
  stroke: ${colors.font_grey};
`;

export const MSCIDescription = styled.p<Props>`
  margin-top: 4px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.font_grey};
  @media (max-width: 800px) {
    display:  ${({ underTable }) => underTable ? "block" : "none"};
  }
`;