import { FilterData } from "common/valuationSummary/store/model";
import Api from "../Api";

class ApiBondValuationSummary extends Api {
  static getValuationSummary({ metric }: { metric?: string }) {
    return ApiBondValuationSummary.instance.axiosInst.get<{ data: string }>(
      "/bond/valuation-and-earnings",
      { params: { metric } }
    );
  }
  static getMetrics({ tableView }: { tableView?: string }) {
    return ApiBondValuationSummary.instance.axiosInst.get<FilterData>("/bond/metrics", {
      params: { tableView },
    });
  }

  static getIndicesData(index: string, metric: string) {
    return ApiBondValuationSummary.instance.axiosInst.get<{ data: string }>(
      "bond/indices/data",
      { params: { index, metric } }
    );
  }
}

export default ApiBondValuationSummary;
