import CryptoJS from "crypto-js";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "application/store";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import { ValuationReportState } from "common/valuationReports/store/model";
import ApiBondValuationSummary from "integration/api/ApiBondValuationSummary";

class BondValuationSummaryActions {
  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };
  static getValuationSummaryTableData = createAsyncThunk(
    `bond-valuation-summary/${ResponseHandlerName.GetExpectedBondTableData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).bondValuationSummary;

      const metric = filters.selectedMetric.code;
      try {
        const { data } = await ApiBondValuationSummary.getValuationSummary({
          metric,
        });
        const decryptedData = BondValuationSummaryActions.decryptData(
          data.data
        );
        return decryptedData;
      } catch (error) {
        console.log("getValuationSummaryTableData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static getBondMetrics = createAsyncThunk(
    `bond-valuation-summary/${ResponseHandlerName.GetBondMetricsValuation}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiBondValuationSummary.getMetrics({
          tableView: "VALUATIONS_AND_EARNINGS_SUMMARY_TABLE",
        });
        return res.data;
      } catch (error) {
        console.log("getMetrics Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default BondValuationSummaryActions;
