import React from "react";

import { ProcessItemProps } from "./types";
import { Container, IconAccept } from "./styles";

const ProcessItem = ({ isActive, process_step }: ProcessItemProps) => {
  return (
    <Container>
      {isActive && <IconAccept width={24} height={24} />}
      <p>{process_step.title}</p>
      <img src={process_step.icon} alt={process_step.title} />
    </Container>
  );
};

export default ProcessItem;
