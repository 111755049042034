import React from "react";

export interface Data {
  indexName: string;
  indexDescription: string;
  mtd: number;
  threeMtd: number;
  ytd: number;
  oneYr: number;
  threeYr: number;
  fiveYr: number;
  tenYr: number;
  twentyYr: number;
}

export interface NoData extends Data {
  noData?: boolean;
  type?: "COUNTRY" | "DEVELOPED_MARKET" | "EMERGING_MARKET" | "GLOBAL";
  indexType?: "COUNTRY" | "DEVELOPED_MARKET" | "EMERGING_MARKET" | "GLOBAL";
}

export interface List {
  data: Data[];
  showButton: any;
  compareLines: boolean;
  headCells: HeadCell[];
  onSelectedCompare?: any;
  selectedCompare?: Data[] | NoData[];
  buttonSection: number;
}

export interface HeadCell {
  id: keyof Data;
  label: string;
  diffBackground: boolean;
}

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orderBy: string;
  rowCount: number;
}

export interface DesktopProps {
  headCells: HeadCell[];
  compareLines: boolean;
  selectedCompare?: Data[] | NoData[];
  selected: Data[];
  order: number;
  orderBy: keyof Data;
  rows: NoData[];
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  handleClick: (event: React.MouseEvent<unknown>, row: Data) => void;
  buttonSection: number;
}

export enum IndexTypes {
  countries = "COUNTRY",
  developedMarkets = "DEVELOPED_MARKET",
  emergingMarkets = "EMERGING_MARKET",
  global = "GLOBAL",
}
