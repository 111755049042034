import { createAsyncThunk } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

import { RootState } from "application/store";
import { ResponseHandlerName } from "common/responseHandlerService/model";
import ApiYieldCurve from "integration/api/ApiBondYieldCurve";

class BondYieldCurveActions {
  static decryptData = (data: string) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const decryptedData = CryptoJS.AES.decrypt(data, key).toString(
      CryptoJS.enc.Utf8
    );
    return JSON.parse(decryptedData);
  };
  static getYieldCurveTableData = createAsyncThunk(
    `yield-curve/${ResponseHandlerName.GetYieldCurveTableData}`,
    async (_, { rejectWithValue, getState }) => {
      const { filters } = (getState() as RootState).yieldCurveSelector;

      const date = filters.endDate?.slice(0, -3);
      try {
        const res = await ApiYieldCurve.getYieldCurveTable({ date });
        const resData = BondYieldCurveActions.decryptData(res.data.data);
        return resData;
      } catch (error) {
        console.log("getValuationSummaryTableData Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static getFilters = createAsyncThunk(
    `yield-curve/${ResponseHandlerName.GetYieldCurveFilters}`,
    async (_, { rejectWithValue }) => {
      try {
        const res = await ApiYieldCurve.getFilters();
        return res.data;
      } catch (error) {
        console.log("getFilters Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static getCharts = createAsyncThunk(
    `yield-curve/${ResponseHandlerName.GetYieldCurveChart}`,
    async (_, { rejectWithValue, getState }) => {
      const { indexPair } = (getState() as RootState).yieldCurveSelector;
      try {
        const {data} = await ApiYieldCurve.getChart({indexCodeA: indexPair.indexCodeA, indexCodeB: indexPair.indexCodeB});
        const decryptedData = BondYieldCurveActions.decryptData(data.data);
        return decryptedData;
      } catch (error) {
        console.log("getFilters Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
}

export default BondYieldCurveActions;
