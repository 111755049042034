import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import AuthActions from "../store/actions";
import { ForgotSchema } from "./validations";
import { ForgotPasswordDataT } from "./types";
import { PathNames } from "application/routes";
import { useAppDispatch, useAppSelector } from "application/store";
import {
  requestSelector,
  resetErrors,
} from "common/responseHandlerService/slice";

import SideBar from "features/side-bar";
import PrimaryInput from "application/components/PrimaryInput";
import PrimaryButton from "application/components/PrimaryButton";

import {
  AuthTitle,
  AuthWrapper,
  AuthContent,
  AuthContainer,
  AuthBottomWrapper,
} from "../styles";
import {useMediaQuery, useTheme} from "@mui/material";
import MobileCopyrightBlock from "../../../application/components/MobileCopyright";
import MobileFooter from "../../../application/components/MobileFooter";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    forgotPassword: { loading, error },
  } = useAppSelector(requestSelector);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down(1023));
  const isMobile = useMediaQuery(theme.breakpoints.down(674));

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordDataT>({
    resolver: yupResolver(ForgotSchema),
  });

  const onSubmit = (data: ForgotPasswordDataT) => {
    dispatch(AuthActions.forgotPassword(data)).then((res) => {
      if (res.type.endsWith("/fulfilled")) {
        navigate(PathNames.passwordRecoveryReset);
      }
    });
  };

  useEffect(() => {
    if (error) {
      setError("email", { message: error });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(resetErrors());
    };
  }, []);

  return (
    <AuthWrapper>
      <SideBar type="sign-in" recovery />
      <AuthContainer>
        <AuthContent>
          <AuthTitle>Password recovery</AuthTitle>

          <PrimaryInput
            id="email"
            register={register}
            label="Email Address"
            placeholder="Email Address"
            error={errors.email?.message}
          />
          {!isMobile && (
            <>
              <AuthBottomWrapper>
                <PrimaryButton
                  title="Back"
                  btn_type="light"
                  onClick={() => navigate(PathNames.login)}
                />
                <PrimaryButton
                  title="Send reset code"
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                />
              </AuthBottomWrapper>
            </>
          )}
        </AuthContent>
      </AuthContainer>

      {isTablet && (
        <MobileFooter>
          {isMobile && (
            <>
              <AuthBottomWrapper>
                <PrimaryButton
                  title="Back"
                  btn_type="light"
                  onClick={() => navigate(PathNames.login)}
                />
                <PrimaryButton
                  title="Send reset code"
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                />
              </AuthBottomWrapper>
            </>
          )}
          <MobileCopyrightBlock />
        </MobileFooter>
      )}
    </AuthWrapper>
  );
};

export default ForgotPassword;
