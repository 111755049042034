import Api from "../Api";

class ApiStockBondYieldComparison extends Api {
  static getBondYieldSummaryMetrics() {
    return ApiStockBondYieldComparison.instance.axiosInst.get<{
      data: string;
    }>("/finance-instruments/indices-metrics");
  }

  static getBondYieldSummaryTableData({
    stockMetricCode,
  }: {
    stockMetricCode: string;
  }) {
    return ApiStockBondYieldComparison.instance.axiosInst.get<{
      data: string;
    }>("/finance-instruments/comparison", {
      params: {
        stockMetricCode,
      },
    });
  }

  static getBondCharts(stockMetricCode: string, indicesPairId: string) {
    return ApiStockBondYieldComparison.instance.axiosInst.get<{ data: string }>(
      "/finance-instruments/index-spread-data",
      {
        params: {
          stockMetricCode,
          indicesPairId,
        },
      }
    );
  }
}

export default ApiStockBondYieldComparison;
