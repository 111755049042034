import styled from "styled-components";

import { colors } from "application/styles/colors";

const textFontSize = 8;
const valueFontSize = 9.5;

export const Container = styled.div`
  height: 100%;
  box-sizing: border-box;
  border-radius: 1px;
  padding: 12px 0 0 7px;
  background: ${colors.white};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
`;

export const Date = styled.p`
  margin-bottom: 10px;
  /* Font style */
  font-size: 10px;
  font-weight: 700;
  color: ${colors.font_grey};
  font-family: "Helvetica";
`;

export const Text = styled.p`
  /* Font style */
  font-weight: 400;
  font-family: "Helvetica";
  color: ${colors.font_grey};
  font-size: ${textFontSize}px;
`;

export const Percent = styled.p`
  margin-left: 4px;
  /* Font style */
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: "Helvetica";
  color: ${colors.blue_default};
  font-size: ${valueFontSize}px;
`;

export const Avg = styled.p`
  margin-left: 4px;
  /* Font style */
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: "Helvetica";
  color: ${colors.brown_hover};
  font-size: ${valueFontSize}px;
`;

export const SD = styled.p`
  margin-left: 4px;
  /* Font style */
  font-weight: 700;
  letter-spacing: 0.5px;
  font-family: "Helvetica";
  color: ${colors.font_grey};
  font-size: ${valueFontSize}px;
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
