import React from "react";
import { MSCIIWrapper, Text, InfoIcon, MSCIDescription, MSCIInfoContainer } from "./styles";
import MSCIInfoPopUp from "../../../features/chart/components/MSCIInfoPopUp";

type Props = {
  isMSCIVisible: boolean;
  onChange: any;
  underTable?: boolean
}

const MSCIInfo = ({isMSCIVisible, onChange, underTable}: Props) => {
  return (
    <MSCIIWrapper underTable={underTable}>
      <MSCIInfoContainer onClick={onChange}>
        <Text>Calculated from MSCI data</Text>
        <InfoIcon />
      </MSCIInfoContainer>
      <MSCIDescription underTable={underTable}>
        This information is for internal use only
        {!underTable && <br />} and may not be reproduced or shared in any form
      </MSCIDescription>
      {isMSCIVisible && <MSCIInfoPopUp onClose={onChange} />}
    </MSCIIWrapper>
  );
};

export default MSCIInfo;
