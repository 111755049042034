import { createSlice } from "@reduxjs/toolkit";

import { RootState, store } from "application/store";
import { ResponseHandlerState, ResponseHandlerName } from "./model";

const initialState = Object.fromEntries(
  Object.values(ResponseHandlerName).map((key) => [
    key,
    {
      loading: false,
      error: "",
    },
  ])
) as ResponseHandlerState;

export const requestSlice = createSlice({
  name: "responseHandler",
  initialState,
  reducers: {
    resetErrors: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith("/pending"),
      (state, action) => {
        const requestName = action.type
          .split("/")[1]
          .trim() as ResponseHandlerName;
        state[requestName].loading = true;
      }
    );
    builder.addMatcher(
      (action) => action.type.endsWith("/rejected"),
      (state, action) => {
        const requestName = action.type
          .split("/")[1]
          .trim() as ResponseHandlerName;
        state[requestName].error = action.payload.message || action.payload;
        state[requestName].loading = false;
      }
    );
    builder.addMatcher(
      (action) => action.type.endsWith("/fulfilled"),
      (state, action) => {
        const requestName = action.type
          .split("/")[1]
          .trim() as ResponseHandlerName;
        state[requestName] = initialState[requestName];
      }
    );
  },
});

export const { resetErrors } = requestSlice.actions;
export default requestSlice.reducer;
export const requestSelector = () => store.getState().request;

export const historicalPageLoading = (state: RootState) => {
  return (
    state.request.getHistoricalStock.loading ||
    state.request.getHistoricalStockDateCalculation.loading ||
    state.request.getHistoricalStockLastUpdatedDate.loading
  );
};
