import React from "react";
import { ReportLastUpdate, ReportLastUpdateContainer, Text } from "./styles";
import moment from "moment";
import { ReactComponent as CalendarIconSource } from "application/assets/images/icons/calendar-icon.svg";

interface Props {
  date: string;
}

const ReportLastUpdateLine = ({ date }: Props) => {
  return (
    <ReportLastUpdateContainer>
      <ReportLastUpdate>
        <CalendarIconSource />
        <Text>Last Update:</Text>
        <Text>{moment(date).format("MMMM, Do YYYY")}</Text>
      </ReportLastUpdate>
    </ReportLastUpdateContainer>
  );
};

export default ReportLastUpdateLine;
