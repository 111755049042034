import styled from "styled-components";

import { colors } from "application/styles/colors";
import {LargeTitle} from "../../styles/text";

export const ErrorLeftSide = styled.div`
  width: 406px;
  padding-top: 10px;

  @media only screen and (max-width: 1024px) {
    width: 490px;
    text-align: center;
    padding-top: 60px;
  }

  @media only screen and (max-width: 525px) {
    width: calc(100% - 32px);
    padding-top: 20px;
  }
`;

export const ErrorWrapper = styled.div`
  width: 1140px;
  height: calc(100vh - 207px);
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 1160px) {
    width: 990px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: calc(100vh - 149px);
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media only screen and (max-width: 647px) {
    height: calc(100vh - 205px);
  }

  @media (orientation: landscape) and (max-width: 1024px) {
    height: 100%;
  }
`;

export const ErrorImg = styled.img`
  @media (max-width: 647px) {
    width: 450px;
  }

  @media only screen and (max-width: 525px) {
    width: 300px;
  }
`;

export const Title = styled(LargeTitle)`
  font-family: 'Time';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: ${colors.black};
  text-align: left;

  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
  
  @media only screen and (max-width: 525px) {
    font-size: 32px;
  }
`;

export const ErrorText = styled.p`
  margin-top: 16px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: ${colors.oxford_blue};

  @media only screen and (max-width: 525px) {
    font-size: 18px;
    line-height: 28px;
  }
`;

export const ButtonWrapper = styled.div`
  width: 280px;
  margin-top: 48px;

  @media only screen and (max-width: 1024px) {
    margin: 48px auto 0;
  }

  @media only screen and (max-width: 525px) {
    margin-top: 32px;
  }
`;

