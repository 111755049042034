import styled from "styled-components";

import { colors } from "application/styles/colors";

export const TermsContainer = styled.div`
  padding: 32px;
  max-height: 436px;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid ${colors.grey_400};
  margin-bottom: 24px;

  @media only screen and (max-width: 674px) {
    border: none;
    padding: 0;
    max-height: unset;
    overflow: unset;
  }
`;

export const TermsTitle = styled.h6`
  margin-bottom: 16px;
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: ${colors.black}; ;
`;

export const TermsText = styled.p`
  /* Font style */
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  color: ${colors.black};

  a {
    color: ${colors.blue_700};
    text-decoration: underline;
  }
`;

export const BoldText = styled.p`
  font-weight: bold;
`;

export const CompleteTermsTextLink = styled.p`
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  color: ${colors.black};

  a {
    color: ${colors.blue_700};
    text-decoration: underline;
  }
`;
