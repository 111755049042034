import styled from "styled-components";
import {colors} from "../../styles/colors";

export const MobileConfirmWrapper = styled.div`
  width: calc(100% - 32px);
  padding: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  background: ${colors.white};
  z-index: 99;

  @media only screen and (max-width: 674px) {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.25);
  }
`;