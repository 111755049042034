import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import ExpectedStockActions from "./actions";
import { store } from "application/store";
import { FilterDataItem, ExpectedStockPage } from "./model";

const initialState: ExpectedStockPage = {
  dividend: {
    Dividend: [
      { code: "CURRENT", name: "Current Dividend Yield" },
      { code: "AVERAGE", name: "Average Dividend Yield" },
      { code: "UPPER", name: "Upper Dividend Yield Range" },
      { code: "LOWER", name: "Lower Dividend Yield Range" },
    ],
  },
  earnings: {Earnings: [
    { code: "TEN_YEAR_HISTORICAL", name: "10-Year Historical Earnings Growth" },
    { code: "FORWARD", name: "Consensus Expected Earnings Growth" },
    { code: "FIVE_YEARS_AVERAGE", name: "Average 5-Year Earnings Growth" },
    { code: "UPPER", name: "Upper Historical Earnings Growth Range" },
    { code: "LOWER", name: "Lower Historical Earnings Growth Range" },
  ],},
  valuation: {Valuation: [
    { code: "STAYS_THE_SAME", name: "P/E Ratio Stays the Same" },
    { code: "SHIFTS_TO_LONG_TERM", name: "P/E Ratio Shifts to Long-term Average" },
    { code: "SHIFTS_TO_MEDIAN", name: "P/E Ratio Shifts to Median P/E Ratio" },
    { code: "SHIFTS_TO_FORWARD", name: "P/E Shifts to Avg Forward P/E Ratio" },
    { code: "SHIFTS_TO_UPPER_RANGE", name: "P/E Ratio Shifts to Upper Historical Range" },
    { code: "SHIFTS_TO_LOWER_RANGE", name: "P/E Ratio Shifts to Lower Historical Range" },
  ]},
  tableData: {},
  filters: {
    selectedDividend: { code: "", name: "" },
    selectedEarnings: { code: "", name: "" },
    selectedValuation: { code: "", name: "" },
  },
};

export const expectStockSlice = createSlice({
  name: "expect-stock",
  initialState,
  reducers: {
    setFilterDividend: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedDividend = action.payload;
    },
    setFilterEarnings: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedEarnings = action.payload;
    },
    setFilterValueation: (state, action: PayloadAction<FilterDataItem>) => {
      state.filters.selectedValuation = action.payload;
    },
  },
  extraReducers: (builder) => {
    // getTableData
    builder.addCase(ExpectedStockActions.getExpectedStockTableData.fulfilled, (state, action) => {
      state.tableData = action.payload;
    });
  },
});

export default expectStockSlice.reducer;
export const homeSelector = () => store.getState().expectStock;
export const { setFilterDividend, setFilterEarnings, setFilterValueation } =
  expectStockSlice.actions;
