import styled from "styled-components";
import { colors } from "../../styles/colors";
import { ReactComponent as ErrorIconSvg } from "../../assets/images/icons/error.svg";
import { ReactComponent as CloseIconSvg } from "../../assets/images/icons/close-icon-test.svg";

export const ErrorIcon = styled(ErrorIconSvg)`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  stroke: ${colors.red_900};
`;

export const CloseIcon = styled(CloseIconSvg)`
  width: 24px;
  height: 24px;
  margin-left: 24px;
  stroke: ${colors.red_900};
  cursor: pointer;
`;
export const BillingError = styled.div`
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  right: 24px; 
  top: 24px; 
  background: ${colors.red_100};
  padding: 16px;
  color: ${colors.red_900};
  display: flex;
  align-items: center;
  z-index: 999;

  @media only screen and (max-width: 674px) {
   left: 24px;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;