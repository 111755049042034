export const formatDate = (date: string) => {
  const newDate = new Date(date);
  const year = newDate.getUTCFullYear();

  const getMonth = (1 + newDate.getUTCMonth()).toString();
  const month = getMonth.length > 1 ? getMonth : "0" + getMonth;

  const getDay = newDate.getUTCDate().toString();
  const day = getDay.length > 1 ? getDay : "0" + getDay;

  return month + "/" + day + "/" + year;
};
  
