import React, { useMemo } from "react";
import {
  IndicesComparisonTableStyled,
  IndicesComparisonTableContainer,
  TableHeadStyled,
  TableRowStyled,
} from "./styles";
import { TableBody, TableRow, TableCell } from "@mui/material";
import { useAppSelector } from "application/store";
import { indicesComparisonSelector } from "common/bondsComparison/store/slice";
import moment from "moment";

const IndicesComparisonTable = () => {
  const { indicesComparisonData } = useAppSelector(indicesComparisonSelector);

  const headerCells = useMemo(() => {
    const isDataAnnualised =
      indicesComparisonData.data.length > 0 &&
      indicesComparisonData.data[0].annualized;

    const firstColumn =
      indicesComparisonData.data.length > 0 ? "Indexes" : "Index to compare";

    const secondColumn =
      indicesComparisonData.data.length === 0 ? "Total Return" : `${isDataAnnualised ? "Annualized Total Return" : "Total Return"}`;
    return [firstColumn, secondColumn];
  }, [indicesComparisonData]);

  const sortedArray = [...indicesComparisonData.data];

  return (
    <IndicesComparisonTableContainer>
      <IndicesComparisonTableStyled>
        <TableHeadStyled>
          <TableRow>
            {headerCells.map((head) => (
              <TableCell key={head}>{head}</TableCell>
            ))}
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          {sortedArray.length === 0 ? (
            <TableRowStyled isEvenRow={true}>
              <TableCell>Select from 2 up to 5 indexes</TableCell>
              <TableCell></TableCell>
            </TableRowStyled>
          ) : (
            sortedArray
              .sort((a, b) => (a?.indexName > b?.indexName ? 1 : -1))
              .map((item, idx) => (
                <TableRowStyled
                  key={item.indexName}
                  isEvenRow={idx % 2 === 0}
                  isWrong={
                    indicesComparisonData.fromDate < item.inceptionDate ||
                    indicesComparisonData.toDate < item.inceptionDate
                  }
                >
                  <TableCell>{item.indexName}</TableCell>
                  <TableCell>{` ${
                    indicesComparisonData.fromDate < item.inceptionDate ||
                    indicesComparisonData.toDate < item.inceptionDate
                      ? `Date out of range. First available date is ${moment(
                          item.inceptionDate
                        ).format("MMMM D, YYYY")}`
                      : `${item.annualizedValue.toFixed(2)}%`
                  }`}</TableCell>
                </TableRowStyled>
              ))
          )}
        </TableBody>
      </IndicesComparisonTableStyled>
    </IndicesComparisonTableContainer>
  );
};

export default IndicesComparisonTable;
