import React from "react";
import {
  MobileCopyright,
  CopyrightText,
  CopyrightCircle,
  CopyrightAnd,
  CopyrightBlock,
  CopyrightAction,
} from "./styles";
import { applicationConfig } from "../../config/applicationConfig";

const MobileCopyrightBlock = () => {
  const { privacy_policy, cookies } = applicationConfig;

  return (
    <MobileCopyright>
      <CopyrightText>Copyright © {new Date().getFullYear()}</CopyrightText>
      <CopyrightCircle>•</CopyrightCircle>
      <CopyrightBlock>
        <CopyrightAction target="_blank" href={privacy_policy}>
          Privacy Policy
        </CopyrightAction>
        <CopyrightAnd>and</CopyrightAnd>
        <CopyrightAction target="_blank" href={cookies}>
          Cookie Policy
        </CopyrightAction>
      </CopyrightBlock>
    </MobileCopyright>
  );
}

export default MobileCopyrightBlock;
